<template>
  <div class="page-web page-home">
		<Head />
   <div style="max-width: 1200px;  margin: 0 auto; ">

    <el-card style="width: 1100px; margin: 10px auto;background-color: #0b0e11; border-style:none; color:#fff;">
  <div slot="header" class="clearfix">
   <div style="width:1100px;  border-radius: 10px；  " >
      <div style="margin:20px;font-size: 22px;">{{$t('inviteusers1')}}:  {{address}}   <button class="copy" @click="copys()">{{$t('inviteusers8')}}</button></div>
      <div style="margin:20px;font-size: 22px;">{{$t('inviteusers2')}}:  {{number}}  </div>
      <div style="margin:20px;font-size: 22px;">{{$t('inviteusers10')}}:  {{sum}}  </div>
 </div>
  </div>
  <div class="my_table">
     <el-table
     row-key="id"
     :tree-props="{children: 'userInviteeVoList'}"
     :data="tableData"
     :empty-text="$t('inviteusers7')"
    height="700"
    style="width: 1200px ;">
    <el-table-column  :label="$t('transaction0')" width="120" align="left">
<template slot-scope="scope">
{{ (scope.$index+1) }}
</template>
</el-table-column>
    <el-table-column
      prop="id"
      :label="$t('inviteusers3')"
      width="350">
    </el-table-column>
    <el-table-column
      prop="userName"
      :label="$t('inviteusers4')"
      width="280">
    </el-table-column>
    <el-table-column
      prop="usdtBalance"
      :label="$t('inviteusers11')"
      width="100">
    </el-table-column>
    <el-table-column
      prop="time"
      :label="$t('inviteusers5')">
    </el-table-column>
  </el-table>
    </div>
  </el-card>
 
    </div>
<Foot />
 </div>
</template>

<script>
	import Head from '@/components/Head.vue'
	import Foot from '@/components/Foot.vue'
  import {getInviteuser} from '@/api/virtual/inviteusers.js'
  export default {
    components: {
			Head,
			Foot
		},
    data() {
      return {
        tableData: [
        ],
        address: "",
        number: "",
        sum:0,
      }
    },
    created() {
            this.getList();
            },
    methods: {
        getList() {
        const id=  localStorage.getItem('userId');
        getInviteuser(id).then(response => {
        this.address=response.data.address;
        this.number=response.data.number;
        this.sum=response.data.sum;
        this.tableData = response.data.userInviteeVoList;
      });
      },
      copys(){
        let text=this.address;
       if(window.clipboardData)
       {
        window.clipboardData.setData("text",text);
       }else{
        (function(){
          document.oncopy=function(e){
            e.clipboardData.setData("text",text);
             e.preventDefault();
             document.oncopy=null;
          }
        })(text);
       }
       document.execCommand('copy');
        // const save =function(e){
        //   e.clipboardData.setData("text/plain",text);
        //   e.preventDefault();
        // }
        // document.addEventListener('copy',save);
        // document.execCommand('copy');
        this.$message({
          message:this.$t('inviteusers6'),
          type:'success'
        })
  
        
      }
  }
  }
</script>
<style scoped>
.my_table >>> .el-table__row>td{
  /* 去除表格线 */
  border: none;
}
.my_table >>> .el-table th.is-leaf {
  /* 去除上边框 */
    border: none;
}

.my_table >>> .el-table::before{
  /* 去除下边框 */
  height: 0;
}

.my_table ::v-deep .el-table__body tr:hover > td {
     background-color: rgb(0, 0, 0) !important;
 }


.el-table__body tr:hover>td{
 
background-color: rgb(0, 0, 0) !important;
 
}
 
/* 点击之后颜色改为粉红色  如果没有效果 需要给el-table  加上  highlight-current-row  这个属性 */
/* .el-table__body tr.current-row>td{
 
  color: rgb(0, 0, 0) !important;
  background: rgb(12, 63, 192) !important;
 
} */

</style>