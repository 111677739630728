<template>
  <div style="margin: 0 auto; width: 100%; display: flex; justify-content: center; align-items: center;margin-top: 20px;">
    <div v-if="activity === 0" style="text-align: center;">
      <h1 style="font-size:50px">{{$t('pool1')}}</h1>
    </div>
    <div v-else-if="activity === 1" style="width: 100%; ">
      <el-row >
        <el-col :span="23" v-for="o in prizes" :key="o" style="margin: 5px;">
          <el-card :body-style="{ padding: '5px'}">
            <div style="padding: 14px;">
              <p style="font-size: 22px;">{{o.name }}</p>
              <p>{{$t('pool2')}}: {{o.number }}</p>
              <p >{{$t('pool3')}}:{{o.notes}}</p>
            </div>
          </el-card>
        </el-col>
      </el-row>
      <el-button v-if="isParticipate" type="primary" @click="addEntry" style="width: 100%; margin-top: 20px;">{{$t('pool4')}}</el-button>
      <h1 v-else  style="width: 100%; text-align: center; margin-top: 20px; font-size:40px"> {{$t('pool5')}}</h1>
      <h1 style="width: 100%; margin-top: 20px; font-size:20px">{{$t('pool6')}}</h1>
    <div style="margin-top: 20px;">
        <div >
          <el-tag style="margin-right: 10px; margin-top: 10px;" v-for="item in userList" :key="item" type="warning">{{item.userEmail}}</el-tag>
        </div>
    </div>
    </div>
    <div v-else-if="activity === 2" style="width: 100%;">
        <h1 style="width:100%;  text-align: center; font-size:30px">{{$t('pool7')}}</h1>
         <el-row >
        <el-col :span="23" v-for="o in prizelist" :key="o" style="margin: 5px;">
          <el-card :body-style="{ padding: '5px' }">
            <div style="padding: 14px;">
              <p style="font-size: 22px;">{{ o.name }}</p>
              <p>{{$t('pool2')}}: {{ o.number }}</p>
              <p >{{$t('pool3')}}:{{o.notes}}</p>
            </div>
            <div style="text-align: center;">
            <h1>{{$t('pool8')}}</h1>
            <div v-for="(item, index) in o.prizeList" :key="index">
              <p>{{ item }}</p>
            </div>
          </div>
          </el-card>
        </el-col>
      </el-row>
      
    </div>
  </div>
</template>

<script>
import { prizeConfig,prizeUser,prizeList ,prizeUserListAll,addParticipate,prizeUserList} from '@/api/virtual/virtual.js'
export default {
  data() {
    return {
        activity: 0, // 0活动为开始，1进行中，2以开奖
        newEntry: '', // 输入框的值
        prizes: [], // 奖池数组
        prizelist: [],  //开奖后的数组
        isParticipate : false, //是否参与
        userList: [], // 用户列表
    };
  },
   created() {
    this.prizeConfig();
  },
  mounted() {
  this.startAutoScroll(); // 组件挂载后开始自动滚动
  },
  methods: {
    prizeConfig() //获取奖池状态
    {
        prizeConfig().then(response => {
          if(response.code==500)
        {
          this.$message.error('error');
        }
        else if(response.code==200)
        {
          this.activity=response.data;
          if(this.activity==1)  //进行中
          {
            this.prizeList();  //获取奖池
            this.prizeUser();  //获取用户是否参与
            this.prizeUserListAll();  //获取所有用户
          }
          else if(this.activity==2) //开奖了
          {
            this.prizeUserList(); //获取获奖信息
          }
        }
        })
    },
    prizeUser()   //获取用户是否参与奖池
    {
        const userId=  localStorage.getItem('userId');
        prizeUser(userId).then(response => {
          if(response.code==500)
        {
          this.$message.error('error');
        }
        else if(response.code==200)
        {
            this.isParticipate=response.data;
        }
        })
    },
    prizeList()   //获取奖品列表
    {
        prizeList().then(response => {
          if(response.code==500)
        {
          this.$message.error('error');
        }
        else if(response.code==200)
        {
            this.prizes=response.data;
        }
        })
    },
    prizeUserListAll()    //获取所有参与用户
    {
        prizeUserListAll().then(response => {
          if(response.code==500)
        {
          this.$message.error('error');
        }
        else if(response.code==200)
        {
            this.userList=response.data;
        }
        })
    },
    prizeUserList()   //获取获奖信息
    {
        prizeUserList().then(response => {
          if(response.code==500)
        {
          this.$message.error('error');
        }
        else if(response.code==200)
        {
           this.prizelist=response.data; 
        }
        })
    },
    addEntry() {
        //参加抽奖
      const userId=  localStorage.getItem('userId');
      console.log("userId:"+userId);
      addParticipate(userId).then(response => {
        if(response.code==200)
        {
            this.$message({
              message: response.msg,
              type: 'success'
            });
        }
        else
        {
            this.$message.error(response.msg);
        }
      })
      this.prizeConfig();
    }
  }
};
</script>
<style scoped>

</style>