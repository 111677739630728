<template>
  <div>
    <div class="header">
      <div class="container-fluid flex flex-ac">
        <div class="logo">
          <router-link to="/">
            <img src="../assets/img/baloog.png" alt="" />
            <b>BNBNCOIN</b>
          </router-link>
        </div>
        <div class="flex flex_bd head-body hidden-xs">
          <el-menu
            :default-active="$route.path"
            @select="handleSelect"
            class="el-menu-nav"
            mode="horizontal"
            :router="true"
          >
            <el-menu-item index="/">{{ $t('meun1') }}</el-menu-item>
            <el-menu-item index="/exchange/BTCUSDT">{{ $t('meun2') }}</el-menu-item>
              <el-menu-item index="/wallet">
              {{ $t('meun3') }}
              </el-menu-item>
            <el-menu-item index="/mining">{{ $t('meun4') }}</el-menu-item>
              <el-menu-item index="/inviteusers">{{ $t('meun5') }}</el-menu-item>
               <el-menu-item index="/whitepaper">{{ $t('meun6') }}</el-menu-item>
            <el-menu-item index="/announcement">{{ $t('meun7') }}</el-menu-item>
          </el-menu>
        </div>
        <div class="head-right flex flex-ac hidden-sm">
          <div v-if="isLogin == true">
            <el-menu class="el-menu-nav" mode="horizontal" :router="true" :default-active="$route.path"
            @select="handleSelect">
               <el-menu-item @click="open" v-if="isLogin">{{ $t('meun13') }}</el-menu-item>
              
               <el-menu-item index="/wallet" v-if="isLogin">{{ $t('meun10') }}</el-menu-item>
                <el-menu-item index="/order" v-if="isLogin">{{$t('meun11')  }}</el-menu-item>
                  
              <el-submenu index="3" popper-class="legal-submenu">
                <template slot="title">
                  <div class="user-avatar">
                    <img :src="avatar" alt="" />
                  </div>
                </template>
                <el-menu-item index="/personal"
                  ><img src="../assets/photo/in11.svg" alt="" />{{ $t('meun13_1') }}
                </el-menu-item>
                <li class="el-menu-item" @click="logOutClick">
                  <img src="../assets/photo/in20.svg" alt="" />{{ $t('meun13_5') }}
                </li>
              </el-submenu>
            </el-menu>
          </div>
           <div v-if="isLogin == false">
           <el-menu class="el-menu-nav" mode="horizontal" :router="true">
               <el-menu-item index="/login" >{{ $t('meun8') }}</el-menu-item>
                <el-menu-item index="/register" >{{ $t('meun9') }}</el-menu-item>
           </el-menu>
           </div>
          <div class="head-download">
            <el-popover placement="bottom" width="300"  trigger="click">
              <el-button slot="reference" class="download-btn" >{{ $t('meun12') }}</el-button>
              <div class="ios" v-for="(dictdata, index) in this.dataList" :key="index">
                <p >{{ $t('meun12') }}{{dictdata.dictValue}}  ： {{dictdata.dictLabel}} </p>
              </div>
            </el-popover>
          </div>
          <el-menu class="el-menu-nav" mode="horizontal" :router="true">
            <el-menu-item @click="langClick">
              <div class="menu-local">
                {{ diaList[current].name }}
              </div>
            </el-menu-item>
          </el-menu>
        </div>
        <div class="menu-nav flex flex-ac hidden-sm">
          <!-- <div class="menu-lang">
            <el-button v-if="theme" @click="themeClick('black')"><span class="iconfont icon-sun"></span> </el-button>
            <el-button v-else @click="themeClick('white')"><span class="iconfont icon-moon"></span> </el-button>
          </div> -->
        </div>
        <div class="menu-icon" @click="drawer = true">
          <span class="iconfont icon-menu"></span>
        </div>
      </div>
    </div>
    
    <el-drawer :visible.sync="drawer" :direction="direction" size="60%" custom-class="custom-drawer">
      <div class="head-body head-drawer-body">
        <div class="menu-nav flex flex-ac">
          <el-menu
            v-if="isLogin == true"
            class="el-menu-nav el-menu-user"
            mode="horizontal"
            :router="true"
            menu-trigger="click"
          >
            <el-submenu index="1">
              <template slot="title">
                <div class="user-avatar">
                  <img src="../assets/photo/user.png" alt="" />
                </div>
              </template>
              <el-menu-item index="/personal"
                ><img src="../assets/photo/in11.svg" alt="" />{{ $t('meun13_1') }}
              </el-menu-item>
              <li class="el-menu-item" @click="logOutClick">
                <img src="../assets/photo/in20.svg" alt="" />{{ $t('meun13_5') }}
              </li>
            </el-submenu>
          </el-menu>
          <el-menu v-else class="el-menu-nav el-menu-btns" mode="horizontal" :router="true">
            <el-menu-item index="/login">{{ $t('meun8') }}</el-menu-item>
            <el-menu-item index="/register">{{ $t('meun9') }}</el-menu-item>
          </el-menu>
          <!-- <div class="unit-span" @click="currentClick">{{ currentInfo.fullName }}</div> -->
          <!-- <div class="menu-lang">
            <el-button v-if="theme" @click="themeClick('black')"><span class="iconfont icon-sun"></span> </el-button>
            <el-button v-else @click="themeClick('white')"><span class="iconfont icon-moon"></span> </el-button>
          </div> -->
        </div>
        <el-menu :default-active="activeIndex" class="el-menu-nav" :router="true">
          <el-menu-item index="/">{{ $t('meun1') }}</el-menu-item>
          <el-menu-item index="/exchange/BTCUSDT">{{ $t('meun2') }} </el-menu-item>
            <el-menu-item index="/wallet">
            {{ $t('meun3') }}
            </el-menu-item>
            <el-menu-item index="/mining">{{ $t('meun4') }}</el-menu-item>
            <el-menu-item index="/inviteusers">{{ $t('meun5') }}</el-menu-item>
            <el-menu-item index="/whitepaper">{{ $t('meun6') }}</el-menu-item>
            <el-menu-item index="/announcement">{{ $t('meun7') }}</el-menu-item>

        </el-menu>
        <div class="el-line"></div>
        <el-menu class="el-menu-nav" :router="true">
          <el-menu-item @click="open" v-if="isLogin">{{ $t('meun13') }}</el-menu-item>
           <el-menu-item index="/wallet" v-if="isLogin">{{ $t('meun10') }}</el-menu-item>

          <el-menu-item index="/order" v-if="isLogin">{{$t('meun11')  }}</el-menu-item>
          
          <el-menu-item @click="langClick">
            {{ diaList[current].name }}
          </el-menu-item>
          <el-menu-item @click="CustomerClick">{{ $t('meun12') }}</el-menu-item>
        </el-menu>
      </div>
    </el-drawer>
    <div class="language">
      <el-dialog :title="$t('meun12_1')" :visible.sync="customerClick">
          <el-table :data="dataList">
              <el-table-column property="dictValue" :label="$t('meun12_2')" width="100"></el-table-column>
              <el-table-column property="dictLabel" :label="$t('meun12_3')" width="300"></el-table-column>
          </el-table>
      </el-dialog>
      <el-dialog :visible.sync="dialogVisible" width="840px" top="18vh" :close-on-click-modal="false">
        <el-tabs v-model="activeName" @tab-click="handleClick">
          <el-tab-pane :label="$t('meun14_1')" name="first">
            <div class="dialog_name">{{ $t('meun14_3') }}</div>
            <div class="dialog_list">
              <el-row>
                <el-col :lg="6" :xs="8" v-for="(item, index) in diaList" :key="index">
                  <div class="dia_col" :class="{ active: index == current }" @click="toggle(item, index)">
                    {{ item.name }}
                  </div>
                </el-col>
              </el-row>
            </div>
          </el-tab-pane>
        </el-tabs>
      </el-dialog>
      <!-- <el-alert
    title="App即将上线！"
    type="warning">
      </el-alert> -->
    </div>
    
  </div>
</template>

<script>
import {listData} from '@/api/virtual/dict/data'
import { loginout } from '@/api/virtual/register'
import {getredenvelope } from '@/api/virtual/virtual'
export default {
  name: 'Header',
  inject: ['reload'],
  data() {
    return {
      dialogFormVisible: false,
      customerClick:false,
      kf:"virtual_customer_service",
      redenvelope:{
          userId:"",
          redenvelopeId:"",
      },
      activeIndex: '1',
      navinput: '',
      drawer: false,
      direction: 'ltr',
      theme: true,
      dialogVisible: false,
      activeName: 'first',
      dataList:[],
      diaList: [
        {
          name: 'English',
          value: 'en_US',
        },
        {
          name: 'بالعربية',
          value:'ar_AR',
        },
        {
          name: '日本語',
          value: 'ja_JP',
        },
        {
          name: '한국인',
          value: 'ko_KR',
        },
        {
          name: 'Deutsch',
          value: 'de_DE',
        },
        {
          name: 'Français',
          value: 'fr_FR',
        },
        {
          name: 'Italiano',
          value: 'it_IT',
        },
        {
          name: 'español',
          value: 'es_ES',
        },
        {
          name: 'Português',
          value:'pt_PT',
        },{
          name: '简体中文',
          value: 'zh_CN',
        },
        {
          name: '繁體中文',
          value: 'zh_HK',
        }
      ],
      currentList: [],
      current: 1,
      current2: 0,
      avatar: require('../assets/photo/user.png'),
      symbol: '',
    }
  },
  created() {
    this.symbol = localStorage.getItem('setSymbol');
    this.gitlistData();
    this.$store.dispatch('setTheme', true)
    window.document.documentElement.setAttribute('data-theme', 'black')
  },
  computed: {
    //是否登陆
    isLogin: function() {
      return this.$store.getters.isLogin
    },
    currentInfo: function() {
      return this.$store.getters.current
    },
  },
  mounted() {
    let theme = localStorage.getItem('theme')
    if (theme == 'black') {
      this.theme = false
    } else {
      this.theme = true
    }
    this.theme = false
    let isZh = localStorage.getItem('lang')
    if (isZh == 'en_US') {
      this.current = 0
    }
    if (isZh == 'ar_AR') {
      this.current = 1
    }
    if (isZh == 'ja_JP') {
      this.current = 2
    }
    if (isZh == 'ko_KR') {
      this.current = 3
    }
    if (isZh == 'de_DE') {
      this.current = 4
    }
    if (isZh == 'fr_FR') {
      this.current = 5
    }
    if (isZh == 'it_IT') {
      this.current = 6
    }
    if (isZh == 'es_ES') {
      this.current = 7
    }
    if (isZh == 'pt_PT') {
      this.current = 8
    }
    if( isZh =='zh_CN')
    {
      this.current = 9
    }
    if( isZh =='zh_HK')
    {
      this.current = 10
    }
    //this.getCurrent()
  },
  methods: {
    open() {
        this.$prompt(this.$t('meun13_3'), this.$t('meun13_2'), {
          confirmButtonText: this.$t('meun13_6'),
          cancelButtonText: this.$t('meun13_4'),
        }).then(({ value }) => {
          this.redenvelope.userId=  localStorage.getItem('userId');
          this.redenvelope.redenvelopeId= value;
          this.getredenvelope();
        }).catch(() => {
          this.$message({
            type: 'info',
            message: this.$t('meun13_7')
          });       
        });
      },
     getredenvelope()
     {
        getredenvelope(this.redenvelope).then(res=>{
              if(res.code==200)
              {
                this.$message({
                type: 'success',
                message: res.msg
                });
              }
              else{
                this.$message.error(res.msg);
              }
          }) 
     } ,
    CustomerClick(){
      this.gitlistData();
      this.customerClick=true;
    },
    handleSelect(key, keyPath) {
      console.log(key, keyPath)
    },
    logOutClick() {
      loginout().then(res=>{
        if(res.code==200)
        {
          localStorage.removeItem('token')
          localStorage.removeItem('invite')
          localStorage.removeItem('userId')
          localStorage.removeItem('userName')
          this.$store.commit('SET_ISLOGIN', false)
          this.$store.commit('SET_MEMBER', null)
          this.isLogin = false
          this.$message({
            message: this.$t('win'),
            type: 'success',
          })
          this.$router.push({
            path: '/',
          })
        }
      })
          
    },
    themeClick(theme) {
      console.log("theme", this.theme);
      this.theme = !this.theme
      this.$store.dispatch('setTheme', theme)
      window.document.documentElement.setAttribute('data-theme', 'black')
    },
    handleClick(tab, event) {
      console.log(tab, event)
    },
    toggle(item, index) {
      console.log(item,index);
      this.current = index
      this.dialogVisible = false
      this.$i18n.locale = item.value
      this.$emit('newLocal', item.value)
      localStorage.setItem('lang', item.value)
      this.reload()
    },
    toggleCurrent(item, index) {
      this.dialogVisible = false
      this.current2 = index
      // this.currentList.splice(index, 1)
      // this.currentList.unshift(item)
      // this.$emit('setCurrent', item)
      this.$store.commit('SET_CURRENT', item)
    },
    langClick() {
      this.activeName = 'first'
      this.dialogVisible = true
    },
    currentClick() {
      this.dialogVisible = true
      this.activeName = 'second'
    },
    gitlistData()
    {
      listData(this.kf).then(response => {
          this.dataList = response.data;
      });
    }
  },
  watch: {},
}
</script>
<style>
 @media (max-width: 1300px) {
  .hidden-sm {
    display: none !important;
  }
 
}

@media (max-width: 1200px) {
  .hidden-xs {
    display: none !important;
  }

  .el-menu-item,
  .el-submenu__title {
    height: 42px !important;
    line-height: 42px !important;
  }

  .el-drawer__header {
    margin-bottom: 0 !important;
  }

  .menu-nav {
    width: 100% !important;
    padding: 0 20px !important;
  }

  .el-menu-user .el-submenu__title {
    padding: 0 5px;
  }
  .head-body .el-menu--horizontal > .el-submenu .el-submenu__icon-arrow {
    margin-left: 8px;
    margin-top: 0;
  }

  .el-drawer__body {
    overflow-y: auto;
  }

  .el-menu-btns.el-menu-nav > .el-menu-item {
    padding: 0 15px !important;
   
  }

  .el-menu-btns.el-menu-nav > .el-menu-item:first-child {
    padding-left: 0 !important;
    
  }
}

@media (max-width: 768px) {
  .el-menu-btns.el-menu-nav > .el-menu-item {

    padding: 0 !important;
    max-width: 50px;
    margin-right: 7px !important;
  }

  .menu-nav .unit-span {
    margin: 0;
    padding: 0 10px;
  }
  .menu-nav {
    padding: 0 0 0 20px !important;
  }
.test-dialog .el-dialog{
  position: absolute;
  left: 20px;
  top: 30px;
}
}

.custom-drawer .el-drawer__close-btn {
  padding: 0;
  
}
.logo {
  color:#f0b90b !important;
  margin-right: 60px;
}
.logo a {
  display: flex;
  justify-content: center;
  align-items: center;
}
.logo img {
  width: 22px;
}
.logo b {
  color: #f0b90b !important;
  padding-left: 6px;
  font-size: 20px;
  letter-spacing: 1px;
  font-weight: 500 !important;
}

/* 
a:link {color:#fff;}      未访问链接
a:visited {color:#fbff00;}  已访问链接
a:hover {color:#e1ff00e3;}  鼠标移动到链接上
a:active {color:#d4ff00;} 鼠标点击时 */
</style>