
<template>
	<div class="page-web page-home">
		<Head />
      <div style="max-width: 90%; height: 900px; margin: 30px auto;">
       <el-row>
        <el-col :span="19">
         <el-container>
        <el-header style="border-style: solid;border-width:1px;border-color:#161a1e;height: 80px; padding-top:20px;">
        <el-row >
            <el-col :span="2" style="">
                <div style=" display: flex">
                <!-- <img :src="currencys[index].loog" alt="" /> -->
                <span style="font-size:18px !important; margin-top:auto; margin-bottom:auto;">  &nbsp; {{symbol}}</span>
                </div>
                <!-- <p style="margin-right:20px;  "></p> -->
            </el-col >
            <el-col :span="3">
              <p style="color: #6F6E6E;">{{$t('mth1')}}</p>
              <p style="font-size:15px;">{{ticker.lastPrice}}</p>   
            </el-col>
            <el-col :span="4" style="text-align: center;">
              <p style="color: #6F6E6E;">{{$t('mth2')}}</p>
               <span class="text-green" v-if="ticker.priceChangePercent > 0">+{{ticker.priceChangePercent}}%</span>
				   <span class="text-green" v-if="ticker.priceChangePercent == 0">{{ticker.priceChangePercent}}%</span>
					<span class="text-red" v-if="ticker.priceChangePercent < 0">{{ ticker.priceChangePercent}}%</span>
              <!-- <p style="font-size:15px;">{{tickers[index].priceChangePercent}}</p>    -->
            </el-col>
            <el-col :span="4">
                 <p style="color: #6F6E6E;">{{$t('mTxt')}}</p>
              <p style="font-size:15px;">{{ticker.highPrice}}</p>   
            </el-col>
            <el-col :span="3">
                 <p style="color: #6F6E6E;">{{$t('mTxt2')}}</p>
              <p style="font-size:15px;">{{ticker.lowPrice}}</p>  
                </el-col>
            <el-col :span="4">
                <p style="color: #6F6E6E;">{{$t('mTxt3')}}</p>
              <p style="font-size:15px;">{{ticker.volume}}</p>  
            </el-col>
            <el-col :span="4">
                 <span style="color: #6F6E6E;">{{$t('indexth')}}</span>
              <p style="font-size:15px;">{{ticker.quoteVolume}}</p>  
            </el-col>
        </el-row>
        </el-header>
    <el-container>
    <el-aside width="25.1%" style="border-style: solid; border-width:1px; border-color:#161a1e; overflow-x: hidden; "  > 
        <div style="width:100%; border-style: solid; border-width:1px;border-color:#161a1e; height: 350px; padding: 3px">
               <el-row :gutter="24" >
                  <el-col :span="7"><div style="font-size:15px;color: #f0b90b; text-align: center;">{{$t('indexbtn1')}}</div></el-col>
                  <el-col :span="7"><div style="font-size:15px;color: #f0b90b; text-align: center;">{{$t('indexbtn2')}}</div></el-col>
                  <el-col :span="9"><div style="font-size:15px;color: #f0b90b; text-align: center;">{{$t('indexbtn3')}}</div></el-col>
               </el-row>
                 <el-table :empty-text="$t('Internet')"  height="340" :data="aggtradesh" style="width:100%; color:#161a1e; font-size: 13px;" :row-style="{height: '0'}"
                    :cell-style="{padding: '0'}" class="my_table" :show-header = "false" >
						<el-table-column width="100"  prop="p" :label="$t('czTxt2')" style="color : red">
                     <template slot-scope="scope">
                     <span class="text-red">{{ scope.row.p}}</span>
                     </template>
						</el-table-column>
						<el-table-column width="80" prop="q" :label="$t('mth1')">
						</el-table-column>
						<el-table-column  prop="pq" :label="$t('mth2')">
						</el-table-column>			
                </el-table>
            </div>
            <br/>
            <div style="width:100%; border-style: solid; border-width:1px;border-color:#161a1e; height: 350px; padding: 3px">
               <br/>
                 <el-table :empty-text="$t('Internet')"  height="320" :data="aggtradesh" style="width:100%; color:#161a1e; font-size: 13px;" :row-style="{height: '0'}"
                    :cell-style="{padding: '0'}" class="my_table" :show-header = "false">
						<el-table-column width="100"  prop="p" :label="$t('czTxt2')">
                     <template slot-scope="scope">
                     <span class="text-green">{{ scope.row.p}}</span>
                     </template>
						</el-table-column>
						<el-table-column width="80" prop="q" :label="$t('mth1')">
						</el-table-column>
						<el-table-column  prop="pq" :label="$t('mth2')">
						</el-table-column>			
                </el-table>
            </div>
    </el-aside>
    <el-main style="border-style: solid;  height: 800px; border-width:1px;border-color:#161a1e;">
      <klines :symbol="symbol"/>
   </el-main>
    </el-container>
    </el-container>   
        </el-col>
         <el-col :span="5">
       <div style="border-style: solid; border-width:1px;border-color:#161a1e; height: 780px;">
        <!-- <p style="font-size:12px;color: #f0b90b;">最新价格</p> -->
            <div style="width:100%; border-style: solid; border-width:1px;border-color:#161a1e; height: 390px; ">
               <el-row :gutter="24" style="">
                  <el-col :span="7"><div style="font-size:15px;color: #f0b90b; text-align: center;">{{$t('czTxt2')}}</div></el-col>
                  <el-col :span="7"><div style="font-size:15px;color: #f0b90b; text-align: center;">{{$t('mth1')}}</div></el-col>
                  <el-col :span="10"><div style="font-size:15px;color: #f0b90b; text-align: center;">{{$t('mth2')}}</div></el-col>
               </el-row>
                 <el-table :empty-text="$t('Internet')" :data="tickers" style="width:100%; color:#161a1e; font-size: 13px;" :row-style="{height: '0'}"
                    :cell-style="{padding: '0'}"  class="my_table" :show-header = "false" @row-click='clickData'>
						<el-table-column width="90"  prop="symbol" :label="$t('czTxt2')">
						</el-table-column>
						<el-table-column width="120" prop="lastPrice" :label="$t('mth1')">
						</el-table-column>
						<el-table-column  prop="priceChangePercent" :label="$t('mth2')">
							<template slot-scope="scope">
								<span class="text-green" v-if="scope.row.priceChangePercent > 0">+{{scope.row.priceChangePercent}}%</span>
								<span class="text-green" v-if="scope.row.priceChangePercent == 0">{{scope.row.priceChangePercent}}%</span>
								<span class="text-red" v-if="scope.row.priceChangePercent < 0">{{ scope.row.priceChangePercent}}%</span>
							</template>
						</el-table-column>			
                </el-table>
            </div>
            <div style="width:100%; border-style: solid; border-width:1px;border-color:#161a1e; height: 450px;">
                <el-row :gutter="20" style="">
                  <el-col :span="8"><div style="font-size:15px;color: #f0b90b; text-align: center;">{{$t('indexbtn1')}}</div></el-col>
                  <el-col :span="8"><div style="font-size:15px;color: #f0b90b; text-align: center;">{{$t('indexbtn2')}}</div></el-col>
                  <el-col :span="7"><div style="font-size:15px;color: #f0b90b; text-align: center;">{{$t('indexbtn4')}}</div></el-col>
               </el-row>
                <el-table :empty-text="$t('Internet')" :data="tradeslist" height="420"  style="width:100%; color:#161a1e; font-size: 13px;" :row-style="{height: '0'}"
                    :cell-style="{padding: '0'}" class="my_table" :show-header = "false">
						<el-table-column width="115"  prop="price" label="价格">
						</el-table-column>
						<el-table-column width="115" prop="qty" label="数量">
						</el-table-column>
						<el-table-column  prop="time" label="时间"> 
						</el-table-column>			
                </el-table>
            </div>
        </div> 
        </el-col>
    </el-row>   
	</div>
	</div>
</template>

<script>
   import {gettickers,gettickerslist,gettradesList,getaggtrades} from '@/api/api/bianapi'
	import Head from '@/components/Head.vue'
   import klines from '@/components/klines.vue'
	export default {
		name: 'Home',
		components: {
			Head,
         klines
		},
		data() {
			return {
            symbol:'BTCUSDT',
      		detail: {},
            index: 0,
            ticker: {},
            tickers: [],
            tradeslist: [],
            aggtradesh:[],
            aggtradesl:[],
            aggtrades:[],
            currencys: [
              {
                 symbol :'BTCUSDT',
                 loog:'@/assets/photo/icon8.png',
              },
              {
                 symbol :'ETHUSDT',
                 loog:'',
              },
              {
                 symbol :'BNBUSDT',
                 loog:'',
              },
              {
                 symbol :'XRPUSDT',
                 loog:'',
              },
              {
                 symbol :'BCHUSDT',
                 loog:'',
              },
              {
                 symbol :'SOLUSDT',
                 loog:'',
              },
              {
                 symbol :'ETCUSDT',
                 loog:'',
              },
              {
                 symbol :'ADAUSDT',
                 loog:'',
              },
              {
                 symbol :'DOTUSDT',
                 loog:'',
              },
              {
                 symbol :'AVAXUSDT',
                 loog:'',
              },
              {
                 symbol :'TRXUSDT',
                 loog:'',
              },
              {
                 symbol :'LTCUSDT',
                 loog:'',
              },
              {
                 symbol :'ICPUSDT',
                 loog:'',
              }
            ],
    	  };
		},
		created() {
            if(this.$route.params.id.toUpperCase()!=undefined)
            {
                  this.symbol=this.$route.params.id.toUpperCase();
            }
            else{
               this.symbol='BTCUSDT';
            } 
			   this.gitticker();
            this.gittradesList();
            this.getaggtrades();
       },
		mounted() {
            
        },	
        methods: {
         clickData(row) {   
           this.$router.push('/exchange/'+row.symbol)
           this.$router.go(0);
         } ,
            gitticker(){
                this.isLogin = this.$store.state.isLogin
                const symbols="[\"BTCUSDT\",\"ETHUSDT\",\"BNBUSDT\",\"XRPUSDT\",\"BCHUSDT\",\"SOLUSDT\",\"ETCUSDT\",\"ADAUSDT\",\"DOTUSDT\",\"AVAXUSDT\",\"TRXUSDT\",\"LTCUSDT\",\"ICPUSDT\"]"
                     gettickerslist(symbols).then(response=>{
                        for(var i=0;i< response.length;i++){
                           this.tickers.push({symbol:response[i].symbol,lastPrice:parseFloat(response[i].lastPrice).toFixed(6),priceChangePercent: response[i].priceChangePercent,highPrice:parseFloat(response[i].highPrice).toFixed(2),lowPrice:parseFloat(response[i].lowPrice).toFixed(2),volume:parseFloat(response[i].volume).toFixed(2),quoteVolume:parseFloat(response[i].quoteVolume).toFixed(2)}); 
                        }
                     });
                     gettickers(this.symbol).then(response=>{
                        this.ticker={symbol:response.symbol,lastPrice:parseFloat(response.lastPrice).toFixed(6),priceChangePercent: response.priceChangePercent,highPrice:parseFloat(response.highPrice).toFixed(2),lowPrice:parseFloat(response.lowPrice).toFixed(2),volume:parseFloat(response.volume).toFixed(2),quoteVolume:parseFloat(response.quoteVolume).toFixed(2)}
                     });    
            },
            gittradesList(){
                gettradesList(this.symbol).then(response=>{
                  this.tradeslist=response.splice(450,500);
                  for(var i=0; i<this.tradeslist.length; i++){
                     var date = new Date(this.tradeslist[i].time);
                     this.tradeslist[i].time= date.getHours() + ':'+date.getMinutes() + ':'+date.getSeconds();
                     this.tradeslist[i].qty= parseFloat(this.tradeslist[i].qty).toFixed(6);
                     this.tradeslist[i].price= parseFloat(this.tradeslist[i].price).toFixed(4);
                  }
                  this.tradeslist=this.tradeslist.reverse();
                })
            },
            getaggtrades(){
               getaggtrades(this.symbol).then(response=>{
                  this.aggtrades=response.splice(200,500);
                  for(var i=0; i<this.aggtrades.length; i++){
                     if(this.aggtrades[i].m){
                        this.aggtradesh.push({p :parseFloat(this.aggtrades[i].p).toFixed(4), q:parseFloat(this.aggtrades[i].q).toFixed(4), pq: parseFloat(this.aggtrades[i].p*this.aggtrades[i].q).toFixed(4)})
                     }
                     else{
                        this.aggtradesl.push({p :parseFloat(this.aggtrades[i].p).toFixed(4), q:parseFloat(this.aggtrades[i].q).toFixed(4), pq: parseFloat(this.aggtrades[i].p*this.aggtrades[i].q).toFixed(4)})
                     }
                     // this.tradeslist[i].qty= parseFloat(this.tradeslist[i].qty).toFixed(6);
                     // this.tradeslist[i].price= parseFloat(this.tradeslist[i].price).toFixed(4);
                  }
               })
            }
        } 
}	
</script>
<style scoped>

.my_table >>> .el-table__row>td{
  /* 去除表格线 */
  border: none;
}
.my_table >>> .el-table th.is-leaf {
  /* 去除上边框 */
    border: none;
}

.my_table >>> .el-table::before{
  /* 去除下边框 */
  height: 0;
}

.my_table ::v-deep .el-table__body tr:hover > td {
     background-color: rgb(0, 0, 0) !important;
 }

.el-table__body tr:hover>td{
background-color: rgb(0, 0, 0) !important;
 
}
 .my_table >>>.el-table__body-wrapper::-webkit-scrollbar{
     /*width: 0;宽度为0隐藏*/
    width: 2px;
  }
.my_table >>> .el-table__body-wrapper::-webkit-scrollbar-thumb{
    border-radius: 2px;
    height: 60px;
    background: #eee;
  }
.my_table >>> .el-table__body-wrapper::-webkit-scrollbar-track{
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    border-radius: 2px;
    background: rgba(0,0,0,0.4);
  }
</style>

