export default {

    //에러 메시지
    error1: '시스템 오류가 발생했습니다. 고객 서비스에 문의하세요.',
    error2: '로그인이 만료되었습니다. 다시 로그인하세요! ',
    
    
    meun1: '집',
    meun2: '시장 동향',
    meun3: 'BNBN 구독',
    meun4: '채굴기 구입',
    meun5: '빠른 승격',
    meun6: '백서',
    meun7: '공지사항',
    meun8: '로그인',
    meun9: '등록',
    meun10: '지갑',
    meun10_1: '현물 계정',
    meun10_2: 'U 기반 계약 계정',
    meun10_3: '코인 마진 계약 계좌',
    meun10_4: '2차 기반 계약',
    meun10_5: '금융 계좌',
    meun10_6: '지갑 내역',
    meun11: '주문',
    meun11_1: '구독 주문',
    meun11_2: '채굴 주문',
    meun12: '고객 서비스',
    meun12_1: '고객 서비스 정보',
    meun12_2: '고객 서비스 번호',
    meun12_3: '고객 서비스 이메일',
    meun13: '빨간 봉투',
    meun13_2:'빨간 봉투 받기',
    meun13_3:'빨간 봉투 수집 코드를 입력해주세요',
    meun13_4:'취소',
    meun13_6:'받기',
    meun13_7:'취소 성공',
    meun13_1: '계정 관리',
    meun13_5: '로그아웃',
    meun14_1: '언어',
    meun14_3: '언어 선택',
    
    scan: '다운로드할 iOS 및 Android 스캔 코드',
    footmeun1: '정보',
    footmeun1_1: '회사 소개',
    footmeun1_2: '우리와 함께하세요',
    footmeun1_3: '플랫폼 공지',
    footmeun1_4: '백서',
    footmeun2: '도움말 센터',
    footmeun2_1: '초보자 가이드',
    footmeun2_2: 'FAQ',
    footmeun2_3: '거래 가이드',
    footmeun2_4: '통화 정보',
    footmeun3: '이용약관',
    footmeun3_1: '면책조항',
    footmeun3_2: '개인정보 보호정책',
    footmeun3_3: '사용자 계약',
    footmeun3_4: '요금 설명',
    footmeun4: '문의하기',
    footmeun4_1: '고객 서비스 이메일',
    footmeun4_2: '사업 협력',
    footmeun4_3: '화폐 상장 신청',
    footmeun4_4: '불만 신고',
    foottext: 'BNBN 글로벌 구독 플랫폼',
    countdown:'활동이 종료되었습니다',
    indexTxt1: 'BNBN 글로벌 최초 구독',
    indexTxt2: 'BNBN 글로벌 최초 구독',
    indexTxt3: '온라인 카운트다운',
    indexLabel1: '시장 동향',
    indexVal1: '암호화폐 실시간 가격',
    indexLabel2: 'BNBN 구독',
    indexVal2: '디지털 자산과 직접 교환',
    indexLabel3: '채굴기 구매',
    indexVal3: 'BNBN 암호화폐 채굴',
    indexLabel4: '빠른 승격',
    indexVal4: '사용자 혜택 구독',
    
    indexh5: '재무적 수준의 보안',
    indexh6: '매우 빠른 입출금',
    indexh7: '글로벌 서비스',
    indexh8: '엄격하게 선택된 프로젝트',
    indexp1: 'BITISAN은 초기 비트코인 참가자 및 괴짜 그룹에 의해 설립되었습니다. 팀의 핵심 구성원은 Google, Microsoft, Alibaba 및 Tencent와 같은 유명 기업 출신입니다. 그들은 인터넷 운영에 대한 풍부한 경험과 깊은 R&D 역량을 갖추고 있습니다. 제품. ',
    indexp2: 'BITISAN은 기본 블록체인 서비스 제공업체로 자리매김하고 있으며 글로벌 사용자에게 고품질 암호화폐 자산 거래 플랫폼을 제공하기 위해 최선을 다하고 있습니다. "악을 행하지 말라"는 기본 원칙을 준수하며 고객에게 정직하게 서비스를 제공할 것을 고집합니다. 공정성과 열정, 그리고 열린 자세로 사용자의 근본적인 이익에 도움이 되는 모든 파트너/프로젝트를 환영합니다. ',
    indexp3: '종합적인 금융 위험 관리 시스템과 도난 방지 시스템, 핫월렛과 콜드월렛, 다중 서명 시스템으로 자금 보안을 보장합니다',
    indexp4: '최대한 3분 안에 입출금이 완료되며, 24시간 수동 온라인 검토를 통해 고객이 최고의 투자 기회를 놓치지 않도록 보호합니다',
    indexp5: '글로벌 비즈니스 서비스 네트워크 범위로 글로벌 암호화폐 자산에 투자하고 글로벌 사용자와 거래할 수 있도록 지원합니다',
    indexp6: '고품질 암호화 프로젝트를 엄격하게 선택하고 위험도가 매우 높은 프로젝트의 80%를 필터링합니다',
    market: '시장 정보',
    //새로운 통화 구독 지갑
    wallet1: 'Recharge Quantity',
    wallet2: 'The current USDT payment blockchain protocol is TRC20',
    wallet3: 'The payment amount received must be consistent with the payment amount displayed below, otherwise the system cannot confirm whether the recharge was successful.',
    wallet4: 'Order Number',
    wallet5: 'Payment amount',
    wallet6: '！! If you encounter any payment issues, please contact customer service promptly with your order number',
    wallet7: 'Payment timeout, please initiate payment again!',
    usdt:'USDT',
    platform:'BNBN',
    usdt1: '사용 가능한 잔액',
    usdt2: '잔액 잠금',
    platform1: '철회',
    platform2: '충전',
    platform3: '구독',
    transaction: '거래 세부정보',
    transaction0: '일련번호',
    transaction1: '거래 번호',
    transaction2: '거래 수량',
    transaction3: '거래 시간',
    transaction4: 'USDT 충전',
    transaction5: '충전 주소',
    transaction6: 'USDT 출금',
    transaction7: '출금에는 일정 금액의 처리 수수료가 필요합니다.',
    transaction8: '출금 주소',
    transaction9: '출금 금액',
    transaction10: '취소',
    transaction11: '확인',
    transaction12: '구독',
    transaction13: '구독 수량 값은 구독한 USDT 금액을 나타냅니다.',
    transaction14: '구독 수량',
    transaction15: '구독 수량은 0보다 커야 합니다',
    transaction16: '출금 금액은 10보다 커야 합니다',
    transaction17: '통화 유형',
    transaction18: '아직 거래 세부정보가 없습니다.',
    transaction19: '충전 주소를 선택하세요',
    transaction20: '10분 후에 버튼을 다시 클릭해 주세요',
    transaction21: 'Exchange quantity',
	transaction22: 'exchange',
	transaction23: ' The number of exchanges means how many BNBNs need to be exchanged into USDT. Only the available balance can be exchanged. The locking balance does not support the exchange. (1BNBN=0.625USDT)',
    transaction24: 'The amount of exchange must be greater than 0',
    //락업 채굴
    mining1: '구독',
    mining2: '구매 가격',
    mining3: '일일 수익',
    mining4: '잠금일',
    mining5: '구매 수준',
    mining6: '총 구매 수입',
    //초대하다
    Invitationusers1:'초대 링크',
    Invitationusers2:'초대받은 사람 수',
    Invitationusers3:'초대 번호',
    Invitationusers4:'사용자 초대',
    Invitationusers5:'초대 시간',
    Invitationusers6: '복사 성공',
    Invitationusers7:'아직 초대된 사용자가 없습니다',
    Invitationusers8:'복사',
    inviteusers10:'Number of subscribers',
    inviteusers11:'Is it active',
    // 주문하다
    order1: '구독 주문',
    order2: '재정관리명령',
    order3: '구독번호',
    order4: '구독 수량',
    order5: '구독 시간',
    order7: '재무 관리 번호',
    order8: '잠긴 수량',
    order9: '보상 수량',
    order10: '시작 시간',
    order11: '종료 시간',

    //개인센터
    personal1: '개인정보',
    personal2: '실명인증 상태',
    personal3: '인증됨',
    personal4: '인증 중',
    personal5: '인증 없이 인증하려면 클릭하세요',
    personal6: '인증이 실패하면 인증하려면 클릭하세요',
    personal7: '사용자 이름',
    personal8: '이메일',
    personal9: '휴대폰번호',
    personal10: 'VIP 레벨',
    personal11: '일반 회원',
    personal12: '초대 코드',
    personal13:'인증정보',
    personal14:'본인인증 관련 정보를 업로드해주세요',
    personal15:'비밀번호 변경',
    personal16:'원래 비밀번호',
    personal17:'비밀번호 변경',
    personal18:'비밀번호 확인',
    personal19:'비밀번호를 입력해주세요',
    personal20:'비밀번호 길이는 6자 미만일 수 없습니다.',
    personal21:'비밀번호를 다시 입력해주세요',
    personal22:'두 번 입력한 비밀번호가 일치하지 않습니다',
    authentication1:'인증 정보 추가',
    authentication2:'업로드 확인',
    authentication3:'jpg/png 파일만 업로드할 수 있으며 최대 2M',
    authentication4:'파일 업로드',
    authentication5:'사용자가 추가되었으며 파일이 성공적으로 업로드되었습니다',
    authentication6:'사용자 추가 및 파일 업로드 실패',
    authentication7:'업로드된 아바타 사진은 JPG 형식만 가능합니다!',
    authentication8:'업로드된 아바타 이미지의 크기는 2MB를 초과할 수 없습니다!',
    authentication9: '파일 5개 초과',
    // 로그인
    account0:'계정',
    account: 'BNBNCOIN 계정 로그인',
    phone: '휴대폰번호',
    email: '이메일',
    sign: '로그인',
    password: '비밀번호',
    password2: '비밀번호',
    password3: '비밀번호를 잊으셨나요',
    account2: '계정 만들기',
    logErr: '계좌번호를 입력하세요',
    logErr2: '비밀번호를 입력하세요',
    logErr3: '비밀번호 길이는 6자보다 작을 수 없습니다.',
    logSuccess: '로그인 성공',
    Resetpassword:'비밀번호 재설정',
    reset:'재설정',
    
    //奖池
    pool1: 'The activity has not started yet!',
	pool2: 'Number of prizes',
	pool3: 'Prize details',
	pool4: 'Join now',
	pool5: 'I have participated in the event!',
	pool6: 'Participating users',
	pool7: 'The event has ended, and the list of winners is as follows',
	pool8: 'List of Award Winners',
    // 등록하다
    account3: '개인 계정 만들기',
    phone1: '휴대전화 등록',
    email1: '이메일 등록',
    email2: '이메일 주소',
    code: 'SMS 인증코드',
    code2: '인증코드 보내기',
    code3: '인증코드',
    password4: '비밀번호 확인',
    invite: '초대 코드',
    agree: '읽고 동의합니다',
    agreemen: '사용자 계약',
    register: '등록',
    regErr: 'SMS 인증번호를 입력하세요',
    verifypwdtip: '확인 비밀번호를 입력하세요',
    verifypwderr: '두 개의 비밀번호 입력이 일치하지 않습니다!',
    //비밀번호를 잊어버렸습니다
    wjtab: '휴대폰 비밀번호 재설정',
    wjtab2: '이메일 비밀번호 재설정',
    wjlabel: '새 비밀번호',
    wjerr: '새 비밀번호를 입력하세요',
    Tailpage:'끝 페이지',
	homepage:'첫 페이지',
	lastpage:'이전 페이지',
	nextpage:'다음 페이지',
	off:'닫기',
	problem:'만약 문제가 발생하면 실명인증을 포함하고, 예금이 도착하지 않은 상황은 즉시 고객센터에 문의하시기 바랍니다',
    //공지사항
    announcementtitle:'공지 제목',
    announcement: '<p>2019년 4월 메인넷 출시 이후 바이낸스 체인은 고속 성능과 높은 처리량 설계를 선보였습니다. 주요 초점인 기본 분산형 애플리케이션("dApp") Binance Chain DEX(분산형 거래소)는 거래 엔진의 낮은 성능을 반영하여 짧은 시간 내에 수백만 건의 거래를 처리하도록 테스트되었습니다. </p>\
    <p>유연성과 가용성은 성능과 밀접한 관련이 있는 경우가 많습니다. 사람들이 디지털 자산의 발행과 거래를 위한 편리한 장소를 제공하는 방법에 집중할 때 이 디자인은 \
    어느 정도 제한됩니다. 커뮤니티에서 가장 큰 목소리는 \
    바이낸스 체인은 프로그래밍 가능한 확장 기능을 추가하거나\
    직설적으로 말하면 스마트 계약과 가상 머신 기능입니다. </p><p> 현재 바이낸스 체인의 제한된 기능으로 인해 디지털 자산 발행자와 소유자가 새로운\
    자산 전반에 걸쳐 기능을 확장하거나 어떤 형태로든 \
    커뮤니티 관리 및 커뮤니티 활동. </p>\
    <p>바이낸스가 스마트 계약 기능을 추가할 것이라는 높은 기대에도 불구하고\
    체인, "어려운 결정"이었습니다. 스마트 계약 실행은 DEX 속도를 늦추고 자산 거래에 불확실성을 더할 수 있습니다. 이런 타협조차\
    허용 가능하며 생각하기 가장 쉬운 솔루션은 가상\을 구현하는 것입니다.\
    현재 기본 Tendermint 합의 프로토콜과 기본 RPC를 기반으로 하는 머신\
    바이낸스 체인 인터페이스. 그러나 이 솔루션은 기존 dApp 커뮤니티의 학습 비용을 증가시키고 대중적인 솔루션이 되지는 않을 것입니다. \
    여기서 우리는 높은 수준을 유지하기 위해 바이낸스 체인 병렬 블록체인 개념을 제안합니다.\
    네이티브 DEX의 성능은 스마트 계약 기능도 지원합니다. </p>',
    // 혁신 연구소
    labTxt: '비티산연구소·혁신성과 공유',
    labTxt2: '투자자, 프로젝트 당사자, 거래소 및 기타 당사자를 위한 상생 개방형 실험실',
    labTab1: '모두',
    labTab2: '시작하려고 합니다',
    labTab3: '진행 중',
    labTab4: '배포',
    labTab5: '완료',
    tokendistribute: "토큰이 배포되고 있습니다",
    Activitytype: "구독 유형",
    labStart: '시작 시간',
    labEnd: '종료 시간',
    labStartPlaceholder: '시작 시간을 선택하세요',
    labDetail: '세부정보 보기',
    labSpeen: "진행 중",
    labNumber: "총 활동량",
    labType1: '첫 번째 온라인(급한 구매)',
    labType2: '처음 온라인(균등 분할)',
    labType3: '위치 공유',
    labType4: '무료 구독',
    labType5: '클라우드 마이닝 머신 구독',
    labType6: '잠금 해제',
    baseinfo: '기본 정보',
    ruledetail: "유형 설명",
    releaseType: "릴리스 유형",
    releaseType0: "동일 릴리스",
    releaseType1: "균등 비율 해제",
    lock: "해제",
    releaseDetail: "릴리스 세부정보",
    lockday: "일",
    lockweek: "주",
    lockmonth: "월",
    lockyear: "연도",
    releasePercent: "출시 비율",
    lockFee: "잠금 임계값",
    releaseTimes: "여러 개 릴리스",
    lockFee: "시간",
    releaseTimes: "구독 가격",
    Holdcoin: "포지셔닝에 필요한 통화",
    limittimes: "개인 구매 제한 시간",
    limitamount: "개인 구매 한도 수량",
    unlimited: "제한 없음",
    leveloneCount: "필요한 레벨 1 친구 수",
    alreadyamount: "구매 수량",
    leftamount: "남은 금액",
    myalreadyholdamount: "내가 참여하고 있는 보유 자산",
    alreadyholdamount: "이벤트에 참여하는 총 보유량",
    currentdivided: "내 현재 위치는 계약으로 분할될 수 있습니다",
    Holdtips: "[보류 공유] 유형 활동에 참여하려면 활동이 끝날 때까지 위치를 잠가야 합니다.",
    inputholdamount: "잠금 금액을 입력하세요",
    pleaseinputamount: "상환 금액을 입력해주세요",
    pleaseinputholdamount: "잠금 금액을 입력하세요",
    inputamount: "교환 금액을 입력하세요",
    inputminingamount: "구매할 채굴기 수를 입력하세요",
    attend: "지금 참여하세요",
    attention:"참고",
    attentiontxt1: "1. [위치 공유], [무료 구독], [클라우드 마이닝 머신 구독], [락업] 유형 활동에 참여하기 위해 제출한 후 자산은 동결되며 활동이 완료된 후 동결 해제되거나 잠금 해제됩니다. 위에",
    attentiontxt2: "2. 사용자는 [위치 공유], [무료 구독], [클라우드 마이닝 머신 구독], [락업] 유형 활동에 참여한 후에는 참여 신청을 취소할 수 없습니다.",
    attentiontxt3: "3. 프로젝트 당사자 또는 기타 불가항력 요인으로 인해 행사가 취소되는 경우 동결된 자산은 원래 경로로 반환됩니다.",
    attentiontxt4: "* 본 이벤트의 최종 해석권은 공식 Bitisan(WWW.BITISAN.NET)에 있습니다.",
    // 코인 마진 계약 계좌
    transfer: '자금 이체',
    placeholder: '통화 검색',
    bith1: '계약 거래',
    bith2: '계정 자산',
    bit3: '미실현 손익',
    bith4: '사용 가능한 마진',
    bith5: '사용된 마진',
    bith6: '마진 동결',
    bith7: '계약 계정',
    //현금계좌
    btn1: '충전',
    btn2: '철회',
    btn3: '전송',
    btn4: '입금',
    btn5: '코인 출금',
    totalAssets: '변환된 총 자산',
    th1: '통화명',
    th2: '사용 가능한 자산',
    th3: '동결된 자산',
    th4: '공개할 자산',
    th5: '작업',
    // 충전
    czTxt: "디지털 화폐 충전",
    czTxt2: "통화",
    czTxt3: "네트워크 전송",
    czTxt4: "사용 가능한 잔액",
    czTxt5: "출금 주소",
    czTxt6: "출금 금액",
    czTxt7: "받은 금액",
    czTxt8: "취급 수수료",
    czTxt9: "알림",
    czTxt10: "최소 입금액:",
    czTxt10_1: "최소 금액보다 적은 금액의 충전은 적립되지 않습니다.",
    czTxt11: "위 주소에 비통화 자산을 입금하지 마십시오. 그렇지 않으면 자산이 검색되지 않습니다.",
    czTxt12: "위 주소로 충전한 후 전체 블록체인 네트워크 노드의 확인이 필요합니다. 일반적으로 메인 블록체인 네트워크에서 3번의 네트워크 확인 후에 계정에 적립됩니다.",
    czTxt13: "충전 주소는 자주 변경되지 않으며 반복해서 충전할 수 있습니다. 변경 사항이 있는 경우 홈페이지 공지사항이나 이메일을 통해 알려드리도록 최선을 다하겠습니다.",
    czTxt14: "정보가 변조되거나 유출되는 것을 방지하려면 컴퓨터와 브라우저가 안전한지 확인하세요.",
    czTxt15: "충전 기록",
    czTxt16: "자금 보안을 보장하기 위해 계정 보안 정책이 변경되거나 비밀번호가 수정되거나 새 주소를 사용하여 코인을 인출하는 경우 인출에 대한 수동 검토를 수행합니다. 인내심을 갖고 직원을 기다려 주십시오. 전화나 이메일로 연락주세요.",
    czTxt17: "최소 인출 금액:",
    czth1: '출금 시간',
    czth2: '에서',
    czth3: '에',
    czth4: '플래시 교환 수량',
    czth5: '환율',
    czth6: '교환 수량',
    placeholder2: '입력하세요',
    placeholder3: '선택하세요',
    czEmpty: '기록 없음',
    logintip: '먼저 로그인하세요! ',
    cztip: '충전 주소를 신청하지 않았습니다. 충전 주소를 얻으려면 버튼을 클릭하세요',
    czget: '충전 주소 얻기',
    czplaceholder: '프로토콜을 선택하세요',
    //두 번째 컨트랙트 계정
    mAccount: "두 번째 계약 계정",
    // 지갑 내역
    btn6: '검색',
    wtTxt: '시작 및 종료 시간',
    wtTxt2: '작업 유형',
    wtth1: '거래 시간',
    wtth2: '유형',
    wtth3: '통화',
    wtth4: '수량',
    wtth5: '지불할 수수료',
    wtth6: '취급 수수료 공제',
    wtth7: '실제 수수료',
    wtth8: '상태',
    // 계정 보안
    nav1: '계정 보안',
    nav2: '내 초대',
    nav3: '내 프로모션 카드',
    acTxt: '보안 수준',
    acbtn1: '인증되지 않음',
    acbtn2: '바인딩',
    acbtn3: '수정',
    acbtn4: '인증됨',
    acbtn5: '바운드',
    acbtn6: '설정',
    acbtn7: '검토중',
    acbtn8: '재시도',
    acTxt2: '실명인증',
    acTxt3: '사서함',
    acTxt4: '휴대전화',
    acTxt5: '로그인 비밀번호',
    acTxt6: '펀드 비밀번호',
    acTxt7: '구글 인증기',
    acTxt8: '펀드 비밀번호 설정',
    acTxt9: '펀드 비밀번호 변경',
    acTxt10: '펀드 비밀번호 검색',
    acDesc2: '계정 보안을 위해 거래 전 실명인증을 완료해주세요! ',
    acDesc3: '이메일 바인딩',
    acDesc4: '휴대폰 바인딩',
    acDesc5: '플랫폼에 로그인할 때 사용',
    acDesc6: '계좌 자금이 변경되면 자금 비밀번호를 확인해야 합니다.',
    acDesc7: 'Google 인증자를 연결해 주세요',
    acDesc8: 'Google 인증자가 연결되었습니다',
    acDesc9: '바인딩 해제',
    actTit: '휴대폰 바인딩',
    actTit2: '이메일 바인딩',
    actTit3: '구글 바인딩',
    actTit4: 'Google 바인딩 해제',
    mailtip: '이메일을 입력해주세요',
    emailerr2: '이메일 형식이 올바르지 않습니다. 다시 입력해 주세요',
    agreementtip: '이용약관을 읽고 동의해주세요',
    acverify: "검토가 실패했습니다",
    again: "다시 시도해 주세요",
    Verifiedmsg: '실명인증을 먼저 진행해주세요',
    
        
    //로그인 비밀번호 변경
    chTxt: '로그인 비밀번호 변경',
    chTxt2: '바인딩할 코드 스캔',
    chTxt3: '인증번호를 입력해주세요',
    chTxt4: '원래 펀드 비밀번호',
    chTxt5: '새 펀드 비밀번호',
    chTxt6: '새 비밀번호 확인',
    chTxt7: '비밀번호를 잊어버렸습니다',
    chTxt8: '휴대폰 인증 변경',
    chTxt9: '원래 로그인 비밀번호',
    chTxt10: '새 로그인 비밀번호',
    chTxt11: '이메일 인증코드',
    chTxt12: '휴대폰 인증번호',
    telerr: '휴대폰번호가 올바르지 않습니다',
    emailerr: '이메일이 올바르지 않습니다',
    codeerr: '인증 코드가 올바르지 않습니다',
    save: '저장',
    chtip: '원래 비밀번호를 입력해주세요',
    chtip2: '6자리 이상의 새로운 로그인 비밀번호를 입력하세요',
    chtip3: '새 로그인 비밀번호가 일치하지 않습니다',
    chtip4: '올바른 펀드 비밀번호를 입력하세요',
    chtip5: '비밀번호는 6자 이상이어야 합니다.',
    chtip6: '6자리 이상의 비밀번호를 입력하세요',
    chtip7: '비밀번호가 일치하지 않습니다',
    chtip8: '펀드 비밀번호를 입력해주세요',
    chtip9: '휴대폰번호를 입력하세요',
    chtip10: '로그인 비밀번호를 입력하세요',
    realnametip: '실명을 입력하세요',
    idcardtip: 'ID 번호를 입력하세요',
    
    //결제방법
    clTxt: '결제수단을 설정하시고 반드시 실명계좌를 이용해주세요',
    clTxt2: '결제 수단 추가',
    edit: '편집',
    delete: '삭제',
    name: '이름',
    clTxt3: 'ID 번호',
    clTxt4: 'IBAN 번호',
    clTxt5: '모든 결제 수단',
    clLink1: '계정 이름',
    clLink2: '결제 수단 수정',
    clLink3: '결제 세부정보(선택사항)',
    clplaceholder1: '전체 이름을 입력하세요',
    clplaceholder2: '은행 계좌 비밀번호를 입력하세요',
    clplaceholder3: '결제 세부정보를 입력하세요',
    cltip: '특별 팁',
    cltip2: '즉시 결제를 위해 은행 카드 번호를 추가하세요. 다른 은행이나 결제 수단 세부정보는 포함하지 마세요. 선택한 은행의 결제/영수증 정보를 추가해야 합니다. ',
    cltip3: '알림: 디지털 화폐를 판매할 때 선택한 결제 방법이 구매자에게 표시됩니다. 정보가 정확하게 입력되었는지 확인해주세요. 바이낸스 플랫폼 KYC 신원 확인과 일치하는 결제 계정을 사용하세요. ',
    clTxt6: '선택사항',
    upload: '업로드',
    uploadTip: '(JPG/JPEG/PNG/BMP 형식 지원, 1MB 미만)',
    //내 프로모션 카드
    tgbtn: '교환 코드',
    tgth1: '카드 이름',
    tgth2: '교환 코드',
    tgth3: '카드 화폐',
    tgth4: '카드 앞면 금액',
    tgth5: '총 개수',
    tgth6: '구원됨',
    tgth7: '수집 시간',
    tgbtn2: '홍보 자료',
    tgbtn3: '지금 사용하기',
    tgplaceholder: '교환 코드를 입력하세요',
    exchangesuccess: "교환 성공! 자산 관리에 가서 잔액을 확인해주세요!",
    // 내 초대장
    inth1: '사용자 초대',
    inth2: '등록 시간',
    inth3: '실명현황',
    // 내 광고
    adTxt: '내 광고',
    adTxt2: '광고의 최소 거래 금액에 처리 수수료를 더한 금액이 남은 광고 수량보다 클 경우 광고가 자동으로 제거됩니다.',
    adbtn: '조건 지우기',
    adbtn2: '선반에',
    adbtn3: '제거',
    adth1: '광고 번호',
    adth2: '광고 유형',
    adth3: '주문 제한',
    adth4: '남은 수량',
    adtip: '삭제하시겠습니까?',
    num_text1: '원하는 내용을 입력하세요',
    num_text2: '수량',
    // 현재 대리자
    wTxt: '재고 있음',
    wTxt2: '현재 위임',
    wTxt3: '거래 쌍',
    wTxt4: '거래 방향',
    wth1: '시간',
    wth2: '방향',
    wth3: '가격',
    wth4: '거래됨',
    wth5: '거래 금액',
    wth6: '대리자 유형',
    wth7: '트리거 가격',
    wth8: '거래 가격',
    wth9: '수수료 금액',
    wth10: '정산손익',
    wth11: '위임 상태',
    wth12: '열기/닫기',
    wth13: '마진',
    wbtn: '주문 취소',
    wtip: '취소 팁',
    wtiptxt: '주문 취소를 확인하셨나요',
    // 돈을 얻다
    zbplaceholder: '검색을 시작하려면 주문 번호를 입력하세요',
    zbtab1: '고정 투자 계획',
    zbtab2: '고정 투자 기록',
    zbth1: '투자 주기',
    zbth2: '켜기/끄기',
    zbth3: '누적 투자 금액',
    zbth4: '수량 보유',
    zbth5: '평균 비용',
    zbth6: '미실현 손익',
    zbth7: '다음 투자 날짜',
    zbth8: '계획 생성 날짜',
    // 현재 대리자 -1
    etTxt: '공지사항 센터',
    etTxt2: '공유하려면 스캔하세요',
    etmenu1: '암호화폐',
    etmenu2: 'Bitisan 서버 업데이트 안내',
    //현재 커미션-3
    et2menu1: 'RX.Wave 필드 소개',
    et2menu2: '비트코인은 폰지 사기입니까?',
    // 역사 커미션
    hisTxt: '역사적 커미션',
    // 내 주문
    orTab1: '미지급',
    orTab2: '유료',
    orTab3: '완료',
    orTab4: '취소됨',
    orTab5: '항소',
    orth1: '주문 번호',
    orth2: '거래 통화',
    orth3: '거래 유형',
    orth4: '거래 개체',
    ortho5: '금액',
    // 옵션 계약
    qcTab1: '이전 결과',
    qcTab2: '실시간 시세',
    qcTxt: '아니요.',
    qcTxt2: '마침표',
    qcTxt3: '개방 시간',
    qcTxt4: '폐점 시간',
    qcTxt5: '시가',
    qcTxt6: '종가',
    qcTxt7: '총 구매 가격',
    qcTxt8: '구매 및 판매 금액',
    qcTxt9: '내 오픈 포지션',
    qcTxt10: '예측 진행 상황',
    qcTxt11: '시가',
    qcTxt12: '종가',
    qcTxt13: '이 기간의 예측 기간',
    qcbtn1: '강세',
    qcbtn2: '약세',
    qcth1: '계약기간',
    qcth2: '개봉 금액',
    qcth3: '예측 방향',
    qcth4: '예측 결과',
    qcth5: '보너스 금액',
    qcth6: '포지션 개설 수수료',
    qcth7: '펌프',
    
    // 통화 세부정보
    Internet: '네트워크 연결 오류',
    mplaceholder: '검색할 통화 이름을 입력하세요',
    mTab: '선택',
    mth: '컬렉션',
    mth1: '최신 가격',
    mth2: '24시간 상승 및 하락',
    mTxt: '24시간 최고 가격',
    mTxt2: '24시간 최저 가격',
    mTxt3: '거래 수량',
    mTxt4: '펀딩 비율',
    mTxt5: '설정',
    mTxt6: '높음',
    mTxt7: '낮음',
    mTxt8: '수신',
    indexth: '총 거래 금액',
    indexbtn: '세부정보',
    indexbtn1:'가격',
    indexbtn2:'수량',
    indexbtn3:'거래량',
    indexbtn4:'시간',
    
    echat1: 'K선 차트',
    echat2: '깊이 지도',
    mTab2: '현재 위치',
    mTab3: '위임 내역',
    mth3: '보상 보장',
    mth4: '시가',
    mth5: '기간',
    mth6: '종가',
    mth7: '이익',
    mTxt9: '거래 모드',
    mTxt10: '위치 개방 시간',
    mTxt11: '수익률',
    mTxt12: '균형',
    mTxt13: '구매량',
    mTxt14: '중',
    //광고 만들기
    cadTxt: '광고 거래 생성',
    cadTxt2: '자주 거래하는 경우 나만의 거래 광고를 만들 수 있습니다. ',
    cadTxt2_1: '가끔만 거래하는 경우 직접 검색하는 것이 좋습니다',
    cadTxt2_2: '무역 광고 작성은 무료입니다. ',
    cadLink2: '무역 광고',
    cadTxt3: '만들어진 광고를 직접 편집하고 싶다면 확인해주세요',
    cadLink3: '내 광고',
    cadTxt4: '시장 참여 가격',
    cadTxt5: '프리미엄은 판매 시 현재 시장 가격보다 높은 비율을 나타냅니다.',
    cadTxt6: '가격 공식',
    cadTxt7: '[Tip] 개인 센터에 가서 결제 수단을 바인딩/추가할 수 있습니다',
    cadTxt8: '최대 거래 금액은 총 판매 금액 0CNYI를 초과할 수 없습니다.',
    cadTxt9: '활성화한 후 사용자가 이 광고를 통해 귀하와 거래를 시작하면 시스템은 귀하가 선택한 자동 응답 문구를 상대방에게 자동으로 보냅니다. ',
    cadTxt10: '프리미엄은 구매 시 현재 시장 가격보다 높은 비율을 의미합니다.',
    cadLabel1: '내가 원하는 것:',
    cadLabel2: '통화 거래:',
    cadLabel3: '국가:',
    cadLabel4: '통화:',
    cadLabel5: '고정 가격 활성화',
    cadLabel6: '프리미엄:',
    cadLabel7: '거래 가격',
    cadLabel8: '판매 수량:',
    cadLabel9: '결제 방법:',
    cadLabel10: '최소 거래 금액:',
    cadLabel11: '최대 거래 금액:',
    cadLabel12: '자동 회신 켜기',
    cadLabel13: '펀드 비밀번호:',
    cadLabel14: '비고:',
    cadLabel15: '고정 가격:',
    cadLabel16: '자동응답:',
    cadLabel17: '구매 수량:',
    radio: '온라인 판매',
    radio2: '온라인 구매',
    cadplaceholder: '프리미엄을 설정하세요',
    cadplaceholder2: '판매하려는 수량을 입력하세요',
    cadplaceholder3: '원하는 최소 거래 금액을 입력하세요',
    cadplaceholder4: '원하는 최대 거래 금액을 입력하세요',
    cadplaceholder5: '비고 정보에 구매자 요구사항, 온라인 시간 등과 같은 특별한 요구사항을 입력할 수 있습니다.',
    cadplaceholder6: '거래 가격을 입력하세요',
    cadplaceholder7: '주문을 받은 후 결제 방법, 결제 계좌 번호 등과 같은 구매자 정보에 자동으로 응답합니다. ',
    cadplaceholder8: '구매하려는 수량을 입력하세요',
    caderr1: '최소 거래 금액을 입력하세요',
    caderr2: '최소 거래 금액은 100 이상이어야 합니다! ',
    caderr3: '최소 거래 금액은 최대 거래 금액보다 작아야 합니다',
    caderr4: '최대 거래 금액을 입력하세요! ',
    caderr5: '정수를 입력하세요',
    caderr6: '최대 거래 금액은 최소 거래 금액보다 커야 합니다! ',
    caderr7: '최대 거래 금액은 총 판매 금액을 초과할 수 없습니다',
    caderr8: '거래 방법을 선택하세요',
    제출: '제출',
    cadnew: '활성화한 후에는 통화 가격이 시장에 따라 변동되지 않으며 가격은 변경되지 않습니다. ',
    //빠른 교환
    dhTxt: '원클릭 교환으로 혜택을 누려보세요',
    dhTxt2: '한도 가격',
    dhTxt3: '판매',
    dhTxt4: '구매',
    dhTxt5: '최대',
    dhTxt6: '금액을 입력하세요',
    dhplaceholder: '현물 지갑 사용 가능 잔액',
    dhSuccess: '축하합니다! 상환에 성공했습니다! ',
    // 명목화폐 거래
    crTxt: '명목화폐 거래',
    crTxt2: '편리하고 안전하며 빠른 디지털 화폐 구매 및 판매',
    crTxt3: '동적 조정',
    crTxt4: '수수료 없음',
    crTxt5: '즉시 거래',
    crTxt6: '플랫폼 보장',
    crTxt7: '시장 가격에 따른 실시간 변동',
    crTxt8: '사용자가 보는 것은 그들이 얻는 것이며, 구매 및 판매 가격 외에는 플랫폼 수수료가 없습니다',
    crTxt9: '매칭을 기다리지 않고 판매자, 지능형 매칭, 거래 주문을 처리할 수 있는 플랫폼 도입',
    crTxt10: '플랫폼 인증 가맹점, 안전한 보안, 거래 보호를 위한 24시간 고객 서비스',
    crth1: '판매자',
    crth2: '거래 수',
    crth3: '결제 방법',
    crth4: '한계',
    crth5: '단가',
    crbtn: '구매',
    crbtn2: '상인이 되세요',
    // 명목화폐 거래 1
    cr1Txt: '축하합니다! 귀하의 판매자 인증 검토가 통과되었습니다',
    cr1Txt2: '정보 준비',
    cr1Txt3: '검토를 위해 제출',
    cr1Txt4: '인증됨',
    cr1Txt5: '당신은 다음 권한을 가지고 있습니다',
    cr1Txt6: '전용 부스',
    cr1Txt7: '일대일 서비스',
    cr1Txt8: '판매자는 거래 성공률을 높이기 위해 전용 광고 부스를 보유하고 있습니다.',
    cr1Txt9: '낮은 처리 수수료',
    cr1btn: '광고 게시',
    cr1btn2: '항복 신청',
    // 정기적인 재무관리
    reTxt: '비티산 재무관리• 원스톱 투자 솔루션',
    reTxt2: '간편하고 안전하게 즉시 수익을 창출하세요',
    reTab: '정기적인 재무 관리',
    reTab2: '고정 투자 및 재무 관리',
    reTxt3: '일반',
    reTxt4: '유연한 사용자 정의, 더 높은 잠재 수입',
    reTxt6: '고정 투자',
    reTxt7: '고정 투자를 시작하고 자산을 늘리세요',
    reTxt8: '18개 정기 제품 모두 확장',
    reth1: '연간 수익률',
    reth2: '기간(일)',
    reth3: '거래 한도',
    reth4: '제품',
    reth5: '과거 투자 수익',
    rebtn: '계획 만들기',
    redTxt: '하나 만들기',
    redTxt2: '고정 투자 계획',
    redTxt3: '투자 금액을 입력하세요',
    redTxt4: '내 사용 가능한 잔액은',
    redTxt5: '사용 가능',
    redTxt6: '주기 기간',
    redTxt7: '매일',
    redTxt8: '매주',
    redTxt9: '매월',
    redTxt10: '현지 시간',
    redTxt11: '첫 번째 자동 투자 주기는 다음과 같습니다.',
    redTxt12: '시작',
    confirm: '확인',
    confirm2: '알았어',
    cancel: '취소',
    redongoing: "진행 중",
    redcompleted: "완료",
    minNumErr: '최소 시작 금액보다 작습니다',
    minNumErr2: '시작 수량은 0일 수 없습니다',
    buyamounttip: '구매 금액을 입력하세요',
    Pricetipwarning: '거래 금액은 다음보다 높을 수 없습니다.',
    buyamounttipwarning: '구매 금액은 다음보다 높을 수 없습니다',
    Sellamounttip: '판매 금액을 입력하세요',
    Sellamounttipwarning: '판매 수량은 다음보다 높을 수 없습니다.',
    Sellpricetip: '판매 가격을 입력하세요',
    Sellpricetipwarning: '판매 가격은 다음보다 높을 수 없습니다.',
    Sellmore: '판매할 수 있는 최대 금액',
    Sellunit: '조각',
    loginFirst: "먼저 로그인하세요",
    betuccess: "축하합니다! 내기가 성공했습니다!",
    selectAmount: "베팅 금액을 선택하세요",
    Balancenotenough: "사용 가능한 잔액이 부족합니다!",
    Balancenotenough2: "잔고가 부족합니다!",
    minlimitamountfailed: "상환 금액은 최소 상환 금액보다 작을 수 없습니다!",
    maxlimitamountfailed: "상환 금액은 최대 구매 금액보다 클 수 없습니다!",
    limittimesfailed: "참여 횟수가 최대 개인 구매 횟수를 초과했습니다!",
    commitfailed: "참여 제출 실패!",
    // 현물 거래
    trplaceholder: '쿼리',
    trplaceholder2: "최신 가격",
    trplaceholder3: '시장에서 가장 좋은 가격으로 구매하세요',
    trplaceholder4: '시장에서 가장 좋은 가격에 판매하세요',
    triggleplaceholder: "기본 주문 가격은 시장 가격입니다",
    Triggerpriceshouldbigger: "트리거 가격은 0보다 커야 합니다",
    marginModeSuccessTip: "계약 계정 모드가 성공적으로 수정되었습니다",
    noenoughbalance: "계좌 증거금이 부족합니다",
    pleaseinputopenvolume: "열림 볼륨은 0보다 커야 합니다",
    pleaseinputcorretopenvolume: "올바른 오프닝 볼륨을 입력하세요",
    pleaseinputrightentrustprice: "올바른 주문 가격을 입력하세요",
    noenoughposition: "청산 가능한 포지션이 부족합니다",
    pleaseinputclosevolume: "마감 수량은 0보다 커야 합니다",
    pleaseinputcorretclosevolume: "올바른 마감 볼륨을 입력하세요",
    option1: '안테나',
    option2: '주간 라인',
    option3: '월별 회선',
    trTxt: '거래 금액',
    trTxt2: '시장 가격',
    trTxt3: '판매량',
    trSuccess: '제출 성공',
    //U 기반
    udTxt: '마진 모드',
    udTxt2: '계약 배수 조정',
    udTxt3: '레버리지',
    udTxt4: '영구',
    udTxt4_1: '영구',
    udTxt5: '이체 가능 금액',
    udTxt6: '이체 수량',
    udTxt7: '모두 전송',
    udTxt8: '영구 계정',
    ubtn: '전체 위치',
    ubtn2: '고립된 위치',
    uplaceholder: '영구 계약 계정',
    uradio1: '시간 공유',
    uradio2: '15분',
    uradio3: '1시간',
    uradio4: '4시간',
    uradio5: '1일',
    uth1: '여러 계약',
    uth2: '수익',
    uth3: '수익률',
    uth4: '위치',
    uth5: '측정 가능',
    uth6: '평균 개시 가격',
    uth7: '강력한 패리티',
    uth8: '현재 예금',
    uth9: '마진율',
    zhang: '',
    or: '또는',
    ureg: '지금 등록하세요',
    uTxt8: '트랜잭션 수행',
    uTab: '포지션 개설',
    uTab2: '포지션 닫기',
    uTxt9: '오래 열 수 있음',
    uTxt10: '짧게 열 수 있음',
    uTxt11: '주문 가격',
    uplaceholder2: '시장에서 가장 좋은 가격으로 거래',
    uTxt12: '장기 매수를 위해 매수',
    uTxt13: '매도 및 공매도 개시',
    closeup: "마감하기 위해 구매하세요",
    closedown: "장기 마감을 위해 매도",
    uTab3: '이익을 취하고 손실을 중지하세요',
    uTab3_yprice: '이익실현 가격',
    uTab3_sprice: '손절매 가격',
    uTab3Txt: '이익 실현/손실 중지',
    uTxt14: '내 계약 계정',
    ulabel1: '계정 모드',
    ulabel2: '총 계정 수입',
    ulabel3: '위치 여백',
    ulabel4: '자금 활용률',
    ulabel5: '다중',
    ulabel6: '비어 있음',
    uTxt15: '긴',
    uTxt16: '짧음',
    total: '총계',
    Limited_price: '제한가 주문',
    market_price: '시장 가격 주문',
    spot_price: '이익을 취하고 손실을 중지하세요',
    ent_status1: "위임 중",
    ent_status2: "취소됨",
    ent_status3: "위임 실패",
    ent_status4: "위임 성공",
    ent_statusblast: "청산",
    uTxt17: '매수 포지션을 청산할 수 있습니다',
    uTxt18: '매도 포지션 청산 가능',
    //
    prev: '이전 기사',
    next: '다음 글',
    // 프로모션 파트너
    prtxt: '내 초대 링크',
    copy: '복사',
    prtxt2: '나의 1레벨 친구',
    prtxt3: '내 2급 친구',
    prtxt4: '수수료 수입(USDT와 동일)',
    prtxt5: '추가 보상',
    prtxt6: '파트너십 수준',
    prtit: '규칙 세부정보',
    prdesc: 'Bitisan의 [프로모션 파트너]는 전체 네트워크에서 가장 높은 리베이트 비율과 가장 긴 리베이트 기간(최대 평생 리베이트)을 제공하는 프로모션 활동으로, 이에 상응하는 노력을 기울인 프로모터가 플랫폼의 진정한 "파트너"가 될 수 있습니다.”, 비티산 트레이딩 플랫폼과 함께 성장하는 배당금을 누려보세요! 구체적인 내용은 다음과 같습니다. ',
    prli1: '1. 디지털 자산의 개념을 홍보하고 사용자 규모를 확대하기 위해 Bitisan은 오랫동안 효과적인 [프로모션 파트너] 프로그램을 출시했습니다. ',
    prli2: '2. 친구 초대는 두 가지 레벨로 나누어집니다. A가 B를 초대하면 B가 C를 초대하면 C가 B의 1레벨 친구이자 A의 2레벨 친구가 됩니다. ',
    prli3: '3. 프로모션 친구 등록 시 초대자가 제공한 링크를 이용하거나 초대자의 초대코드를 직접 입력해야 초대자의 1급 친구가 됩니다. ',
    prli4: '4. 리베이트 인센티브는 초대받은 사람이 초대자의 초대 코드를 통해 등록하고 실명 인증을 완료한 후에만 유효합니다. ',
    prli5: '5. 리베이트 기간은 초대받은 사람이 실명인증을 완료한 시점부터 N개월입니다. 레벨에 따라 리베이트 비율이 다릅니다. ',
    prli6: '6. 프로모션 리베이트 자산은 하위 친구 통화의 거래 수수료이며 리베이트 통화는 USDT, BTC 및 ETH의 세 가지 주요 시장 기본 통화입니다. ',
    prli7: '7. 프로모션 리베이트 비율과 리베이트 기간은 승진한 1급 친구의 수와 정비례합니다. 홍보하는 사람이 많을수록 리베이트 비율도 높아집니다. 구체적인 프로모션 리베이트 비율은 다음과 같습니다:',
    prli8: '8. 프로모션 커미션 순위 목록에서 상위 10위 또는 상위 100위는 때때로 Bitisan에서 공식적으로 발행하는 추가 보상을 받게 됩니다. ',
    prli9: '9. 다른 활동이 [프로모션 파트너] 활동과 상충될 경우, 임시 변경은 파트너 여러분의 양해를 바랍니다. ',
    prli10: 'Ten, L4(Governor), L5(Prefect) 및 L6(Jiedushi) 레벨 파트너는 각각 총 연간 커미션 리베이트의 5%, 10% 및 15%의 배당금 보상을 누립니다. ',
    prli11: '11. 이 활동의 최종 해석권은 Bitisan에게 있습니다. ',
    prth1: '레벨',
    prth2: '1급 친구 수',
    prth3: '1급 친구 리베이트 비율/리베이트 시간',
    prth4: '2차 친구 리베이트 비율/리베이트 시간',
    prth5: '파트너 배당금',
    prlev1: '리 정',
    prlev2: '카운티 치안판사',
    prlev3: '완벽한',
    prlev4: '지사',
    prlev5: '반장',
    prlev6: '지예두시',
    prtd1: '월',
    prtd2: '평생 커미션',
    prtit2: '예제',
    prtxt7: '사용자 Xiao Yan이 1급 친구 100명을 초대하고 그의 1급 친구 각각이 5명의 2급 친구를 초대하면 Xiao Yan은 100명의 1급 친구와 500명의 2급 친구를 갖게 됩니다. 평균 개인이 하루에 3,000위안을 거래한다면 Xiao Yan의 월 수입은 대략 다음과 같습니다: (100*3000*0.001*30% + 500*3000*0.001*10%) * 30 = 7200/월. ',
    prtxt8: '사용자 Xiao Yan이 1,000명의 1레벨 친구를 초대하고 각 1레벨 친구가 5명의 2레벨 친구를 초대하면 Xiao Yan은 1,000명의 1레벨 친구와 5,000명의 2레벨 친구를 갖게 됩니다. 평균 개인이 하루에 3,000위안을 거래한다면 Xiao Yan의 월 수입은 대략 다음과 같습니다: (1000*3000*0.001*50% + 5000*3000*0.001*20%) * 30 = 135,000/월. ',
    prtit3: '프로모션 도구 상자',
    prtit4: '총 프로모션 리베이트 TOP20 (전체 목록)',
    prtit5: 'TOP20 프로모터 (전체 목록)',
    prtxt9: '통계 기한:',
    prth6: '순위',
    prth7: '회원',
    prth8: '프로모션 번호',
    prth9: '리베이트(USDT와 동일)',
    prth10: '추가 보상',
    prtip1: '위 커미션 데이터는 실시간으로 업데이트되지 않으며 시스템은 24시간마다 이를 계산하고 업데이트합니다. ',
    prtip2: '초대인 수는 1급 친구 수이며, 이 목록은 총 리베이트 금액 순위입니다.',
    prtip3: '초대자 수는 1급 친구 수이고, 이 목록은 1급 초대자 수 순위입니다.',
    // 코인 입금
    Recth1: '도착 시간',
    Recth2: '네트워크 프로토콜',
    Recth3: '입금 주소',
    Recth4: '충전 수량',
    recbtn: '충전하려면 코드를 인출하세요',
    replaceholder: '출금 코드를 입력하세요',
    recstatus_0: '실패',
    recstatus_1: '수신되지 않음',
    recstatus_2: '도착',
    // 코인 출금
    wittit: '디지털 화폐 인출',
    wittext: '출금기록',
    wittext2: '출금 코드',
    wittext3: '출금 코드 목록',
    witbtn: '코드로 코인 출금',
    witbtn2: '현금 출금 코드 생성',
    witdia: '보안 확인',
    witdia2: '출금 코드가 성공적으로 생성되었습니다',
    witplaceholder: '인출 금액을 입력하세요',
    witerr: '통화를 선택하세요',
    addresstip: '주소를 입력해주세요',
    witstate: '구속되다',
    witstate2: '실패',
    witstate3: '교환 완료',
    witstatus_1: '검토중',
    witstatus_2: '전송 진행 중',
    witstatus_3: '실패',
    witstatus_4: '성공',
    // 작업 유형
    type1: '전송',
    type2: '코인 대 코인 거래',
    type3: '법정 화폐 구매',
    type4: '법정화폐 판매',
    type5: '활동 보상',
    type6: '승진 보상',
    type7: '배당금',
    type8: '투표',
    type9: '수동 충전',
    type10: '쌍',
    type11: '활동 상환',
    type12: 'C2C 구매',
    type13: 'C2C 판매',
    type14: '빨간 봉투 보내기',
    type15: '빨간 봉투 받기',
    type16: '코인 출금 코드',
    type17: '출금코드 및 입금',
    type18: '영구 계약 처리 수수료',
    type19: '영구 계약 이익',
    type20: '영구 계약 손실',
    type21: '옵션 계약 실패',
    type22: '옵션 계약 처리 수수료',
    type23: '옵션 계약 보너스',
    type24: '계약 리베이트',
    type25: '레벨 보상',
    type26: '플랫폼 수수료 수입',
    type27: '두 번째 계약 실패',
    type28: '두 번째 계약 보너스',
    type29: '재무관리 관심분야',
    type30: '자금 지출',
    type31: '자금 획득 수수료',
    type32: '고정 투자',
    type33: '고정 투자 매도',
    type34: '일반 구매',
    type35: '정기 상환',
    type36: '코인 기반 계약 양도',
    type37: '코인 기반 계약 이체',
    type38: 'U 기반 계약 이전',
    type39: 'U 기반 계약 전송',
    type40: '두 번째 계약 양도 양도',
    type41: '두 번째 계약 전송',
    type42: '코인 이체 이체',
    type43: '코인 전송',
    // 재무 회계
    lctit: '재무 관리 계좌',
    lcbtn: '금융 이체',
    lcth1: '누적이자',
    일: '일',
    lctxt: '활동 통화',
    lctxt2: '통화 허용',
    lctxt3: '시작 금액',
    lctxt4: '시간 선택',
    연도: '연도',
    월: '월',
    // 재무관리 명령
    ldtab1: "위치 기록",
    ldtab2: "기록 닫기",
    ldth1: "수익 시작일",
    ldth2: "구속일",
    ldth3: "예상 수익",
    ldth4: "이익",
    // 고정 투자 계획
    attit: "고정 투자 계획 수정",
    attxt: "투자 수량",
    attxt2: "투자 주기",
    atth1: '고정 투자 날짜',
    atth2: '고정 투자 금액',
    atth3: '거래율',
    성공: '성공',
    // 혁신경영
    cxth1: '이름',
    cxth2: '유형',
    cxth3: '구독 수량',
    cxth4: '참여 통화',
    cxth5: '구독 단위',
    cxth6: '현재 상태',
    cxth7: '거래량',
    cxth8: '생성 시간',
    cxnav1: '참여했습니다',
    cxnav2: '내 채굴기',
    cxnav3: '내 자물쇠',
    cxtit2: '내 채굴기 목록',
    cxtit3: '내 잠금 목록',
    cxkth1: '출력 통화',
    cxkth2: '출력 주기',
    cxkth3: '채굴 시간',
    cxkth4: '채굴 시간',
    cxkth5: '기본 용량',
    cxkth6: '실제 용량',
    cxstate1: '배포되지 않음',
    cxstate2: '배포됨',
    cxstate3: '해지됨',
    cxstate4: '완료 예정',
    cxstate5: '종료됨',
    cxstate6: '해제 예정',
    cxstate7: '릴리스',
    cxcth1: '통화 해제',
    cxcth2: '전체 잠금',
    cxcth3: '총 릴리스 기간',
    cxcth4: '사이클이 해제되었습니다',
    cxcth5: '기본 릴리스',
    cxcth6: '실제 릴리스',
    more : '더',
    // 회사 소개
    abtit: '플랫폼 기능',
    abtit2: '회사 소개',
    abtit3: '연락처',
    abtit4: '비티산 커뮤니티',
    abdesc: 'Bitisan은 기술을 갖춘 세계 최고의 디지털 자산 거래 플랫폼으로 케이맨 제도에 등록되어 있으며 핵심 운영팀은 홍콩에 있습니다. Bitisan의 핵심 구성원은 최고의 인터넷 및 금융 회사 출신이며 대부분의 구성원은 비트코인과 블록체인에 대한 깊은 신봉자입니다. 우리는 블록체인이 전통적인 독점 금융 시스템을 바꾸고 보다 민주적이고 자율적인 사회 구조를 구축할 것이라고 굳게 믿습니다. ',
    abdesc2: 'Bitisan 플랫폼은 전문적인 금융 수준 거래 구조와 자체 개발한 높은 동시성 메모리 매칭 거래 엔진을 갖추고 있습니다. 플랫폼은 완전 콜드 재충전 지갑 시스템 + 다중 서명 + 높은 보호 DDOS 공격 시스템 및 기타 거래를 채택합니다. 고객 자산의 안전을 보장하는 구조입니다. ',
    abdesc3: '고객이 투자 기회를 더 잘 파악할 수 있도록 Bitisan은 고객 서비스 부서 내 평면 관리를 채택하고 매우 빠른 서비스 응답 메커니즘을 구축합니다. 자산 고객 서비스 관리자는 연중무휴 온라인으로 고객에게 서비스를 제공합니다. 자산 충전 및 출금 등 고객이 5분 이내에 자산 입출금을 완료할 수 있도록 보장합니다. ',
    abdesc4: 'Bitisan은 고품질 프로젝트를 엄격하게 선정하여 안전하고 안정적인 자산 보관 서비스를 제공합니다. Bitisan은 "정직, 공정성, 열정, 개방성"이라는 개념을 고수하며 사용자를 위한 안전하고 신뢰할 수 있으며 효율적이며 친근한 최고의 거래소를 만들기 위해 노력하고 있습니다. ',
    abdesc5: '지금은 디지털 자산이 작은 범위에 불과하지만, 누구나 완벽하게 통제할 수 있는 이런 종류의 자산이 앞으로는 확실히 인기를 끌 것이라고 믿습니다. 함께 노력하고 기대해 보세요! ',
    abdesc6: '고객 서비스',
    abdesc7: '기술 지원',
    abdesc8: '화폐 목록 신청',
    abdesc9: '불만 및 제안',
    abdesc10: '사업 협력',
    abdesc11: '위챗',
    abdesc12: '웨이보',
    abdesc13: '트위터',
    abdesc14: '코인 사용',
    abdesc15: '텔레그램',
    abdesc16: '"bmate601"을 WeChat 친구로 추가하고 WeChat 복지 커뮤니티에 들어가세요',
    // 확인됨
    realtxt: '실명',
    realtxt2: '신분증 번호',
    realtxt3: '신분증 앞면 사진',
    realtxt4: '신분증 뒷면 사진',
    realtxt5: '신분증과 개인 서명을 들고 있는 사진',
    realtxt6: '1. JPG 및 PNG 형식이 지원되며, 파일 크기는 8M 미만입니다. 인증서의 정보가 명확하게 표시되어야 하며, 인증서 번호와 이름이 명확하게 표시되어야 합니다. ',
    realtxt7: '2. 사진은 모자를 쓰지 않은 채 촬영되어야 하며, 신분증을 들고 있는 사람의 얼굴 특징이 뚜렷하게 보이고, 팔이 완전히 노출되는 것이 좋습니다. ',
    realtxt8: "3. 인증서와 자필 사본(이름 + 신청 날짜)을 동시에 보유해야 합니다. '이 사이트에서 나의 모든 활동은 나 자신에 의해 수행되며 관련 위험을 알고 있으며 기꺼이 이 계정으로 인해 발생하는 모든 법적 결과는 본인이 부담합니다.'",
    realbtn: '업로드하려면 클릭하세요',
    realstate: '실명 아님',
    realstate2: '실현됨',
    uploadimg: "신분증 앞면 사진을 업로드해주세요",
    uploadimg2: "신분증 뒷면 사진을 업로드해주세요",
    uploadimg3: "신분증 사진을 업로드해주세요",
    uploaderr: "업로드된 이미지의 크기는 초과할 수 없습니다",
    save_success: '성공적으로 저장했습니다!',
    save_failure: '저장 실패!',
    // 옮기다
    close: '닫기',
    hztxt: '이전 확인',
    //가맹점 인증
    submittip1: '자금 안전을 위해 먼저 [실명인증]을 진행해주세요! ',
    submittip2: '자금의 안전을 위해 먼저 [휴대폰 바인딩]을 진행해주세요! ',
    submittip3: '자금 보안을 위해 먼저 [펀드 비밀번호]를 설정해주세요! ',
    submittip4: '최소 하나의 결제 수단을 연결해 주세요',
    aderr1: '올바른 숫자를 입력하세요',
    aderr2: '프리미엄 값은 0-20입니다',
    aderr3: '프리미엄 값은 0-20이며 0이 될 수 없습니다.',
    aderr4: '올바른 고정 가격을 입력하세요',
    aderr5: '올바른 숫자를 입력하세요. 최대 거래 금액은 100코인을 초과하지 않습니다.',
    orderTypeNo: "아니요",
    orderTypeYes: "예",
    win: "성공",
    lose: "실패",
    tied: "넥타이",
    cancelsuccess: "취소 성공!",
    shelves: '광고는 선반에서 제거된 후에만 삭제할 수 있습니다! ',
    shelf2: '추가 항목은 제거된 후에만 편집할 수 있습니다! ',
    business: '먼저 가맹점 인증 신청을 해주세요! ',
    swappleaseselectwallet: "먼저 입출금 계좌를 선택해주세요",
    swappleaseinputamount: "이체 금액을 입력하세요",
    clickchange: "전송 방향을 전환하려면 클릭하세요",
    daterange: "검색 날짜 범위를 선택하세요",
    // API
    apierr: '비고정보를 입력해주세요',
    apierr2: 'IP 주소를 입력하세요',
    apierr3: '올바른 IP 주소를 입력하세요',
    apierr4: '인증번호를 입력해주세요',
    clickget: '가져오려면 클릭하세요',
    apisecond: '두 번째',
    apitips: "키는 추가될 때만 표시됩니다. 시간에 맞춰 저장해주세요",
    API액세스: "키",
    // 사용자 정의
    do_favorite: '즐겨찾기',
    cancel_favorite: '즐겨찾기 취소',
    fail_favorite: '실패',
    deptotal: '누적',
    chart: '차트',
    book: '주문장',
    trade: '최신 거래',
    pt_title: "디지털 화폐 기프트 카드",
    pt_desc: "교환 후 자동으로 하위 친구가 되어 친구, 친척, 고객 및 파트너에게 선물을 제공합니다. 프로모션 파트너가 더 나은 홍보를 할 수 있도록 도와주세요.",
    pt_more: "더 많은 도구를 계속 지켜봐 주시기 바랍니다",
    pt_tips: "좋은 아이디어가 있으시면 Promotion@Bitisan.biz로 이메일을 보내주세요. 채택하시면 보상을 받으실 수 있습니다!",
    pt_card_amount: "카드 액면가",
    pt_card_deadline: "유효 기간",
    pt_card_noend: "무기한",
    pt_card_btn: "프로모션 파트너는 카드 30장을 무료로 받을 수 있습니다(약 2,000위안)",
    pt_card_btn_login: "로그인 후 30장의 무료 카드를 받으세요",
    pt_card_rule: "규칙 세부정보",
    pt_card_summary: "디지털 통화 기프트 카드는 프로모션 파트너가 오프라인 친구를 더 잘 초대할 수 있도록 개발된 프로모션 도구 중 하나입니다. 프로모션 대상 사용자가 기프트 카드를 사용하면 자동으로 프로모터의 오프라인 친구가 됩니다.",
    pt_card_rule1: "1. 각 사용자는 30개의 무료 기프트 카드로 제한됩니다. 더 많은 기프트 카드를 발행해야 하는 경우 사용자는 맞춤 비용을 지불해야 합니다. 카드 값, 로고, 설명 텍스트 등을 맞춤 설정할 수 있습니다. 사용자 정의 요구 사항에 대해 이메일을 보내주십시오.",
    pt_card_rule2: "2. 각 무료 기프트 카드는 사용자당 한 번만 사용할 수 있습니다. Bitisan이 공식적으로 발행한 각 무료 기프트 카드는 사용자당 한 번만 사용할 수 있습니다. 다른 사용 코드가 있는 무료 기프트 카드를 다른 사용자를 통해 받았더라도 사용할 수 있습니다. 1개의 무료 기프트 카드만 사용하세요. 맞춤 제작된 기프트 카드에는 이 제한이 적용되지 않습니다.",
    pt_card_rule3: "3. 사용자가 기프트 카드를 사용하기 전에 \"초대자\"가 없는 경우(즉, 계정 등록 시 초대 코드를 입력하지 않은 경우) 사용자는 자동으로 카드의 1급 친구로 연결됩니다. 예를 들어, A는 계정 등록 시 초대 코드를 입력하지 않았으며, 이때 A는 누구의 1급 또는 2급 친구에도 속하지 않지만 A가 B가 발급한 기프트 카드를 사용하면 A가 자동으로 사용됩니다. B의 1급 친구가 된다.",
    pt_card_rule4: "4. 사기 방지를 위해 기프트카드 도서 금액은 실시간으로 환급되지 않습니다. 수령 후 실명인증을 완료해야 이용이 가능합니다.",
    pt_card_rule5: "5. 무료 기프트 카드를 받을 때 실명 인증을 완료해야 합니다. 교환할 때 실명 인증을 완료할 필요가 없습니다. 이는 프로모터가 낮은 레벨의 친구를 더 빨리 얻을 수 있도록 하기 위한 것입니다.",
    pt_card_rule6: "6. 무료 상품권의 최종 해석권은 Bitisan에게 있습니다.",
    pt_card_day: "일",
    pt_card_title_tips: "2,000위안 프로모션 보조금을 받으세요",
    pt_invite_desc: "전용 QR 코드입니다. QR 코드를 통해 등록하면 자동으로 하위 친구가 됩니다. 다양한 그룹이나 포럼에 전달하기에 적합합니다.",
    saveimage: "이미지 저장",
    imagetips: "이미지를 정상적으로 다운로드할 수 없는 경우, 스크린샷 도구를 사용하여 스크린샷을 찍어 저장해주세요!",
    pt_card_receivew_success: "축하합니다! 파트너 프로모션 기프트 카드를 성공적으로 받았습니다! [개인 센터]->[카드 및 쿠폰 센터]에서 확인하세요!",
    usepromotion: "홍보 자료 사용",
    context_title: "프로모션 파트너 독점 사용 코드",
    context_title1: "자세한 내용을 보려면 스캔하세요",
    context_title2: "내 초대 코드",
    context_title3: "초대장 사진",
    pt_card_title: "파트너 프로모션 카드",
    copysuccess: '복사 성공! ',
    copyerr: '복사에 실패했습니다! 수동으로 복사해 주세요',
    noopening: "보류 중인 옵션 계약이 없습니다",
    createSuccess: "성공적으로 생성되었습니다",
    tradewarning1: '소수점 2자리까지 입력하세요',
    tradewarning2: '주문 금액은',
    tradewarning3: '소수점 8자리까지 입력하세요',
    tradewarning4: '주문 수량은',
    tradeconfirmbuyin: '구매 확인',
    tradeconfirmsellout: '판매 확인',
    발:{
    aboutus1:'회사 소개',
    aboutus: '<p>2019년 4월 메인넷을 출시한 후 바이낸스 체인은 빠른 속도를 보여주었습니다\
    처리량이 많은 디자인. 주요 초점 - 기본 분산형 애플리케이션(\
    dApp) Binance Chain DEX(분산형 거래소)는 수백만 달러를 처리하는 것으로 테스트되었습니다.\
    단기간의 거래량은 거래 엔진의 대기 시간이 낮다는 것을 보여줍니다\
    일치 기능. </p>\
    <p>유연성과 가용성은 성능과 밀접한 관련이 있는 경우가 많습니다. 디지털 자산의 발행 및 거래를 촉진하는 방법에 중점을 둘 때 이 디자인은 \
    어느 정도 제한됩니다. 커뮤니티에서 가장 큰 목소리는 \
    바이낸스 체인은 프로그래밍 가능한 확장 기능을 추가하거나 \
    직설적으로 말하면 스마트 계약과 가상 머신의 기능이다. 현재 바이낸스 체인의 제한된 기능으로 인해 디지털 자산 발행자와 소유자가 새로운\
    기능을 자산으로 분산시키거나 어떤 형태로든 \
    커뮤니티 관리 및 커뮤니티 활동. \
    바이낸스에 스마트 계약 기능이 추가될 것이라는 기대는 높지만\
    체인, "어려운 결정"이었습니다. 스마트 계약 실행은 DEX 속도를 늦추고 자산 거래에 불확실성을 더할 수 있습니다. 이런 타협조차\
    허용 가능하지만 가장 간단한 솔루션은 가상\을 구현하는 것입니다.\
    현재 기본 Tendermint 합의 프로토콜과 기본 RPC를 기반으로 하는 머신\
    바이낸스 체인 인터페이스. 그러나 이 솔루션은 기존 dApp 커뮤니티의 학습 비용을 증가시키며 대중적인 솔루션이 되지 않을 것입니다. \
    여기서 우리는 높은 수준을 유지하기 위해 바이낸스 체인의 병렬 블록체인 개념을 제안합니다.\
    로컬 DEX의 성능과 스마트 계약 기능을 친절하게 지원합니다. \
    바이낸스 체인 생태계에 두 개의 병렬 블록체인을 구축한 후\
    블록체인은 독립적으로 작동하며 다양한 서비스를 제공합니다. 새로운 병렬 체인은 "바이낸스 스마트 체인"(이하 "BNBN")으로 불리며, 기존 메인넷은 여전히 "바이낸스 체인"(이하 "BNB")으로 불립니다. \
    BNBN의 설계는 다음 원칙을 따릅니다.\
    독립형 블록체인: 기술적으로 BNBN은 독립형 블록체인이며 두 번째 레이어 솔루션이 아닙니다. BSC의 기본 기술 및 비즈니스 기능 대부분은\
    독립적이므로 BNB가 잠시 중단되더라도 BNBN은 여전히 잘 작동할 수 있습니다. \
    이더리움과 호환 가능: 최초로 실용적이고 널리 사용되는 스마트 계약 플랫폼은 \
    이더 리움. 상대적으로 성숙한 애플리케이션과 커뮤니티를 연결하기 위해 BSC는\
    기존 이더리움 메인넷과 호환됩니다. 이는 대부분의 dApp, 생태계가\
    부품 및 도구는 수정 없이 BSC와 호환되거나\
    사소한 변경만 필요합니다. BNBN 노드에는 유사하거나 약간 더 높은 하드웨어만 필요합니다.\
    작동 사양 및 작동 기술. 이 구현에서는 \
    BNBN 및 이더리움의 향후 버전과 지속적인 호환성을 위한 공간\
    지분 담보 기반 합의 및 온체인 관리: 지분 기반 합의\
    스테이킹(PoS)은 보다 환경 친화적이며\
    커뮤니티 거버넌스. 이번 합의는 더 좋아질 것으로 예상할 수 있습니다\
    성능은 PoW 합의보다 낫습니다. 즉, 블록 생성 시간이 짧습니다.\
    높은 거래 처리 능력. 로컬 크로스체인 통신: BNB 및 \
    BNBN은 기본적으로 두 블록체인 간의 크로스체인 통신을 지원합니다. 이것\
    통신 프로토콜은 2-\
    방식은 분산되어 있고 제3자를 불신합니다. 전송에 중점을 둘 것입니다\
    BNB와 BNBN 사이의 디지털 자산, 즉 BEP2 토큰 및 기타 BNBN 토큰\
    나중에 소개되었습니다. 프로토콜은 몇 가지 예외를 제외하고는 블록체인에 저장된 다른 정보에 지나치게 관심을 가져서는 안 됩니다. </p>\
    <p>위의 설계 원칙을 바탕으로 BNBN의 합의 프로토콜이 구현됩니다\
    다음 목표:\
    블록 시간은 이더리움 시간보다 짧아야 합니다(예: 5초 이하). \
    거래가 완료될 때까지 기다리는 시간은 제한되어 있습니다(예: 1분 이내). \
    인플레이션 없이 소득\
    블록체인 부분은 BNBN\ 형식으로 지불되는 처리 수수료에서 비롯됩니다.\
    .가능한 한 이더리움과 호환되어야 합니다. \
    이는 지분 약속을 기반으로 하는 온체인 거버넌스 메커니즘을 갖추고 있습니다. \
    지분 기반 지분 인증 증명서\
    작업 증명(PoW)이 구현된 것으로 입증되었지만\
    분산형 네트워크, 환경에 친화적이지 않으며 많은 비용이 필요합니다.\
    네트워크 보안을 유지하는 행위자의 수. \
    Ethereum 및 MATIC Bor, TOMOChain, GoChain 및 xDAI와 같은 여러 기타 네트워크,\
    다음을 포함한 다양한 시나리오에서 PoA(Proof of Authorization) 또는 그 변형을 사용하십시오.\
    테스트넷과 메인넷. PoA는 공격의 51%를 방어할 수 있어 더욱 효과적입니다\
    일부 비잔틴 노드가 악행을 저지르는 것을 방지하세요. PoA를 기본으로 선택하세요.\
    합의에 도달하는 것은 이상적인 선택 중 하나입니다. \
    위에서 영감을 받아 BNBN은 DPoS와 PoA를 결합하여 합의에 도달합니다. 해결책\
    다음이 사용됩니다: \
    블록은 제한된 수의 검증인이 교대로 \를 생성하는 방식으로 생성됩니다.\
    Ethereum의 Clique 합의 엔진과 유사한 PoA 방식으로 차단됩니다.\
    검증인 세트가 선택되었으며\
    지분 약속을 기반으로 한 온체인 거버넌스 제거. \
    네트워크 시작의 원본 블록 단계 동안 많은 신뢰할 수 있는 노드가\
    초기 검증자 세트로 작동합니다. 블록 생산이 시작된 후에는 누구나\
    검증인 선출에 후보자로 참여할 수 있습니다. 지분 담보 상태에 따라 가장 많은 지분을 보유한 상위 21개 노드가 다음 그룹이 됩니다.\
    검증인. 이러한 선택과 제거는 24시간마다 이루어집니다. \
    BNBN은 BNB 지분 담보의 상징입니다. \
    이더리움 합의 프로토콜(\ 포함)과의 협력을 유지하기 위해\
    업그레이드 예정) BNBN은 자산 관리를 위해 BNB를 사용하기로 결정했습니다. BNBN 자산 전용 모듈이 있습니다\
    BNB에 베팅하세요. BNBN 보유자로부터 BNB 지분 담보를 수락하고\를 계산합니다.\
    가장 큰 지분을 가진 노드 집합입니다. UTC가 0에 도달할 때마다 BNB는 \를 전송합니다.\
    확인 가능한 "ValidatorSetUpdate" 크로스체인 메시지로 BNBN에 해당\을 업데이트하도록 알립니다.\
    검증인 세트. 새로운 블록을 생성하기 전에 기존 BNBN 검증인은 주기적으로 "\"가 있는지 확인합니다.\
    ValidatorSetUpdate" 메시지가 BNB\로 전달됩니다.\
    그렇다면 특정 높이(즉, 사전 정의된 블록 간격) 이후에 유효성 검사기 세트를 업데이트합니다. \
    예를 들어 BNB가 5초마다 블록을 생성하고 확인 주기가 240블록인 경우 현재\
    검증인 세트는 1200초(20분) 이내에 확인되어 다음 주기를 위해 업데이트됩니다. \
    안전성과 최종성\
    ½*N+1 검증자의 절반 이상이 정직하고 신뢰할 수 있다는 점을 고려하면 PoA 기반 네트워크는 일반적으로 안전하고 올바르게 작동합니다. \
    그러나 어떤 경우에는 비잔틴 검증인이 "클론 공격" 등을 통해 여전히 네트워크를 공격할 수 있습니다. BNBN의 높이가 동일한지 확인하려면\
    BNB 보안 문제로 BNBN 사용자는 블록이 수신될 때까지 기다리는 것이 좋습니다.\
    여러 ⅔*N+1개의 다른 검증인에 의해 확인되었습니다. 이러한 방식으로 BNBN은 \
    1/3*N\ 미만의 비잔티움을 허용할 수 있는 BNB와 유사한 보안 수준\
    검증인. 21명의 검증인의 경우 블록 시간이 5초이면 ⅔* N+1\
    다른 유효성 검사기에서는 확인하는 데 (2/3*21+1)*5=75초가 필요합니다. BNBN의 중요한 적용은 상대적으로 안전한 최종 결과를 보장하기 위해 ⅔*N+1을 기다려야 할 것입니다. \
    이에 대해서는 추후 “자본약정 및 관리” 편에서 소개하겠습니다. 이 형벌\
    이 메커니즘은 매우 짧은 시간 내에 악성 유효성 검사기를 노출시킵니다\
    , "클론 공격"을 수행하기가 매우 어렵거나 매우 비경제적입니다.\
    구현하다. 이 페널티 메커니즘을 통해 ½*N+1 이하의 블록이면 충분합니다.\
    대부분의 거래의 최종성. \
    현재 검증인 세트의 모든 BNB 검증인은 지불된 수수료에서 수입을 얻습니다.\
    BNB는 인플레이션 토큰이 아니기 때문에 채굴 수익을 창출하지 않습니다\
    Bitcoin 및 Ethereum 네트워크와 같은. 취급 수수료가 주요 수입원입니다\
    검증인. BNB는 다른 애플리케이션을 위한 유틸리티 토큰이기도 하므로\
    검증인은 BNBN 보유로 인한 다른 혜택을 계속 받게 됩니다. </p>',
    초보자:'',
    newbie1:'초보자 가이드',
    commonproblem:'',
    commonproblem1:'자주 묻는 질문',
    information:'',
    information1:'통화정보',
    disclaimer:'',
    disclaimer1:'면책 조항',
    privacyclause:'',
    Privacyclause1:'개인정보 보호 조항',
    agreement:'',
    agreement1:'사용자 계약',
    },
    tradeinfo: {
    emaildone: '이메일이 인증되었습니다',
    emailundo: '이메일이 인증되지 않았습니다',
    teldone: '휴대폰번호가 인증되었습니다',
    telundo: '휴대전화 번호가 확인되지 않았습니다',
    idcarddone: '신분증이 인증되었습니다',
    idcardundo: 'ID 카드가 인증되지 않았습니다',
    exchangetimes: '거래 수',
    price: '가격',
    num: '수량',
    paymethod: '결제 방법',
    exchangelimitamount: '거래 한도',
    location: '위치',
    location_text: '중국',
    exchange기간: '거래 기간',
    minute: '분',
    amounttip: '금액을 입력해주세요',
    numtip: '수량을 입력해주세요',
    commenttip: '그에게 당신의 요청을 말해주세요',
    Remarktitle: '비고 정보',
    exchangetitle: '거래 메모',
    exchange_tip1: '거래 요청을 시작한 후 디지털 통화는 보관되어 플랫폼에 의해 보호됩니다. 판매자인 경우 거래 요청을 시작한 후 재충전하고 구매자가 지불할 때까지 기다릴 수 있습니다. 구매자는 지불 기한 내에 지불합니다. 귀하는 지불을 받는 즉시 에스크로에 보관된 디지털 통화를 해제해야 합니다. ',
    exchange_tip2: '거래 전 "플랫폼 네트워크 서비스 약관"과 FAQ, 거래 가이드 등 도움말 문서를 읽어보시기 바랍니다. ',
    exchange_tip3: '사기꾼을 조심하세요! 거래 전 사용자가 받은 리뷰를 확인하시고, 새로 생성된 계정에 더욱 주의를 기울여 주시기 바랍니다. ',
    exchange_tip4: '반올림 및 가격 변동은 거래되는 디지털 통화의 최종 금액에 영향을 미칠 수 있다는 점에 유의하세요. 입력한 고정 금액에 따라 최종 금액이 결정되며, 디지털 통화 금액은 요청이 발행되는 동시에 실시간 환율로 계산됩니다. ',
    exchange_tip5: '에스크로 서비스는 온라인 거래의 구매자와 판매자를 보호합니다. 분쟁이 발생할 경우 당사는 제공된 모든 정보를 평가하고 에스크로된 디지털 통화를 적법한 소유자에게 공개합니다. ',
    warning1: '소수점 2자리까지 입력하세요',
    warning2: '주문금액은',
    warning3: '소수점 8자리까지 입력하세요',
    warning4: '주문 수량은',
    verifybuyin: '구매 확인',
    verifysellout: '판매 확인',
    buyin: '구매',
    sellout: '판매',
    warning5: '필요한 대로 주문을 작성하세요',

    },
}