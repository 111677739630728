import axios from 'axios'
import {
	Message
} from 'element-ui'
const baseURL = `${process.env.VUE_APP_API_URL1}/`
const service = axios.create({
	baseURL,
	timeout: 100000,
})
service.interceptors.response.use(
	response => {
		const res = response.data
		if (res.code == 404) {
            Message({
                message: res.msg,
                type: 'error',
                duration: 3000
            })
			//window.location.href = "#/login";
		}
		return res;
	},
	error => {
		if(localStorage.getItem('lang')=='ar_AR'){
			Message({
				message:" اتصال الشبكة خطأ",
				type: 'error',
				duration: 3000
			})
		  }   
		  else{
			Message({
				message: "Network connection error",
				type: 'error',
				duration: 3000
			})
		  }
		return Promise.reject(error)
	})
export default service