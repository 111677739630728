<template>
<div class="page-web page-home">
		<Head />
    <div style="max-width: 1200px;  margin: 0 auto;">
   
 <el-col :span="12" v-for="(mining,key) in tableData" :key="key" >
    <el-card style="width:500px; margin: 10px auto;">
  <div slot="header">
    <span style="font-size:20px;">{{mining.name}}</span>
     <el-button style="float: right; padding: 8px ;color: #000; " type="warning" round @click="submit(mining.id)">{{$t('mining1')}}</el-button>
  </div>
  <el-col :span="12" style="height: 250px;">
  <div style="height: 160px; width:160px">
     <img :src="mining.image" class="image" alt=""/>
  </div>
  </el-col>
  <div style="margin: 20px 0 0 0;">
    {{$t('mining2')}}:   {{mining.price}}  
  </div>
  <div style="margin: 20px 0 0 0;">
    {{$t('mining3')}}:   {{mining.earnings}} 
  </div>
  <div style="margin: 20px 0 0 0;">
    {{$t('mining4')}}:   {{mining.lockoutDay}} 
  </div>
  <!-- <div style="margin: 20px 0 0 0;">
    {{$t('mining5')}}:   {{mining.grade}}
  </div> -->
  <div style="margin: 20px 0 0 0;">
    {{$t('mining6')}}:   {{mining.earnings*mining.lockoutDay}} 
  </div>
    </el-card>
     </el-col>
      <el-button type="primary" round style="width:100%;" @click="gotoMiningMachine">{{$t('miningmachine')}}</el-button>
</div>

<div>
<Foot />
</div>
</div>

</template>

<script>
import Head from '@/components/Head.vue'
import Foot from '@/components/Foot.vue'
// import {listData} from '@/api/virtual/dict/data'
import { getlocked ,controllerlist } from '@/api/virtual/virtual.js'
  export default {
    methods: {
       gotoMiningMachine() {
      // 假设'mining-machine'是路由的name或者path
      this.$router.push({ path: '/mymining' }); 
    },
      handleClick(row) {
        this.dialogFormVisible=true;
        this.locked.day=row.dictValue;
      },
      submit(miningid)
      {
        const id=  localStorage.getItem('userId');
        this.locked.userId=id;
        this.locked.miningContentId=miningid;
        this.getlocked();
      },
      gitcontrollerlist()
      {
      controllerlist().then(response => {
          this.tableData = response.data;
      });
      },
      getlocked(){
      getlocked(this.locked).then(response=>{
        if(response.code==500)
        {
          this.$message.error(response.msg);
        }
        else if(response.code==200)
        {
          this.$message({
          message: response.msg,
          type: 'success'
        });
        }
        else{
          this.$message.error(response.msg);
        }
      });
      }
    },components: {
			Head,
			Foot
		},
     created() {
    //this.gitlistData();
    this.gitcontrollerlist();
  },
    data() {
      return {
        dialogFormVisible : false,
        inputsumber: 0,
        tableData: [],
        kf:'virtual_yield',
        locked:{
          userId:"",
          miningContentId: "",
        }
      }
    },  
    
  }
</script>
<style>
  .text {
    font-size: 14px;
  }

  .item {
    margin: 10px !important;
  }

  .clearfix:before,

  .clearfix:after {
    display: table;
    content: "";
  }

  .clearfix:after {
    clear: both
  }
</style>