import request from '../request'

export function useridlist(id) {
    return request({
      url: '/virtual/virtualmining/useridlist?id='+id,
      method: 'get',
    })
  }
  export function wholeUser(id) {
    return request({
      url: '/virtual/virtualsubscribe/useridlist?id='+id,
      method: 'get',
    })
  }
  export function gituserid(id) {
    return request({
      url: '/virtual/virtualuser/gituser?userId='+id,
      method: 'get',
    })
  }
  export function getwallet(id) {
    return request({
      url: '/virtual/virtualcurrency/getwallet?id='+id,
      method: 'get',
    })
  }
  //锁仓挖币
  export function getlocked(data) {
    return request({
      url: '/virtual/virtualmining/locked',
      method: 'post',
      data: data
    })
  }
  //usdt提现
  export function getapplication(data) {
    return request({
      url: '/virtual/virtualembody/application',
      method: 'post',
      data: data
    })
  }
    //平台币申购
    export function getsubscribe(data) {
      return request({
        url: '/virtual/virtualsubscribe/subscribe',
        method: 'post',
        data: data
      })
    }

// 查询锁仓挖币详情列表
export function controllerlist() {
  return request({
    url: '/virtual/virtualminingcurrency/controllerlist',
    method: 'get',
  })
}
//红包领取
export function getredenvelope(data) {
  return request({
    url: '/virtual/virtualredenvelope/receiveenvelope',
    method: 'post',
    data: data
  })
}
//上传实名认证信息
export function addAuthentication(formData) {  
  return request({  
    url: '/virtual/virtualauthentication/file',  
    method: 'post',  
    data: formData,  
    headers: {  
      'Content-Type': 'multipart/form-data'  
    }  
  });  
}
//获取对应交易地址
export function getobtainerc(data) {
  return request({
    url: '/virtual/virtualorders/obtainerc',
    method: 'post',
    data: data
  })
}
//获取对应交易地址
export function getobtaintrc(data) {
  return request({
    url: '/virtual/virtualorders/obtaintrc',
    method: 'post',
    data: data
  })
}
//获取重置验证码
export function getresetcaptcha(userId) {
  return request({
    url: '/virtual/register/resetcaptcha?email='+userId,
    method: 'get',
  })
}
//重置密码
export function getresetpassword(data) {
  return request({
    url: '/virtual/register/resetpassword',
    method: 'post',
    data: data
  })
}
//启动矿机
export function getactivation(data) {
  return request({
    url: '/virtual/virtualmining/activation',
    method: 'post',
    data: data
  })
}
//BNBN->USDT
export function getbnbn2usdt(data) {
  return request({
    url: '/virtual/virtualcurrency/getexchange',
    method: 'post',
    data: data
  })
}
//申请epusdt支付地址
export function getepusdt(data) {
  return request({
    url: '/epusdt/epusdtobtaintrc',
    method: 'post',
    data: data
  })
}
//-------------------------------------------------------

//获取奖池状态
export function prizeConfig() {
  return request({
    url: '/virtual/virtualprize/prizeConfig',
    method: 'post',
  })
}
//获取奖品列表
export function prizeList() {
  return request({
    url: '/virtual/virtualprize/listAll',
    method: 'get',
  })
}
//获取用户是否参与奖池
export function prizeUser(userId) {
  return request({
    url: '/virtual/virtualparticipate/isParticipate?userId='+userId,
    method: 'get',
  })
}
//获取获奖信息
export function prizeUserList() {
  return request({
    url: '/virtual/virtualprize/getPrize/list',
    method: 'get',
  })
}
//获取所有参与用户
export function prizeUserListAll() {
  return request({
    url: '/virtual/virtualparticipate/listAll',
    method: 'get',
  })
}
//参加抽奖
export function addParticipate(userId) {
  return request({
    url: '/virtual/virtualparticipate/addParticipate?userId='+userId,
    method: 'get',
  })
}