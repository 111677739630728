<template >
<div>
     
        <el-upload
                multiple
                :limit="5"
                class="file-box"
                ref="upload"
                action="#"
                :on-preview="handlePreview"
                :on-remove="handleRemove"
                :on-change="handleChange"
                :on-exceed="handleExceed"
                :before-upload="beforeAvatarUpload"
                :file-list="images"
                list-type="picture"
                :auto-upload="false"> 
               
            <el-button style="width:120px" slot="trigger" size="small" type="primary">{{$t('authentication1')}}</el-button>
            <el-button style="margin-left: 15px; width:120px" size="small" type="success" @click="submitFile">{{$t('authentication2')}}</el-button>
             <el-input
                    style="width: 255px; margin: 0px auto;"
                    :placeholder="$t('chtip9')"
                    prefix-icon="el-icon-phone"
                    v-model.number="phone"
                    type="number">
                </el-input> 
            <div slot="tip" class="el-upload__tip">{{$t('authentication3')}}</div>
        </el-upload>
        <!-- <el-row :gutter="20">
            <el-col v-for="(url,i) in imageurls" :key="i" :span="6">
                <el-image
                        style="width: 100px; height: 100px"
                        :src="url"
                        fit="fill"></el-image>
            </el-col>
        </el-row> -->
    </div>

</template>

<script>
import {addAuthentication } from '@/api/virtual/virtual'
    export default {
        data() {
            return {
                images: [],
                imageurls: [],
                phone: undefined,
            };
        },
        methods: {
            submitFile() {  
                if (this.images.length === 0) {
                    this.$message.warning(this.$t('authentication4'))
                    return
                }
                let formData = new FormData() //创建一个表单
                this.images.forEach(file => {
                    formData.append("files", file.raw) //将文件传到表单中，files属性是后端接受的参数名
                })

                formData.append('userId', localStorage.getItem('userId'));  
                formData.append('phone',this.phone)
                // ... 添加其他字段  
             if(this.phone==undefined)
             {
                this.$message.error(this.$t('chtip9')); 
             }
             else{
                    addAuthentication(formData).then(res => {  
            if (res.code === 200) {  
                    this.$emit('transmit', false)
                    this.$message.success(this.$t('authentication5'));
                    }  
                }).catch(error => {  
                    console.log(error);
                    this.$message.error(this.$t('authentication6'));  
                });
             }   
            },
            beforeAvatarUpload(file) {
                const isJPG = file.type === 'image/jpeg';
                const isLt2M = file.size / 1024 / 1024 < 2;
                if (!isJPG) {
                    this.$message.error(this.$t('authentication7'));
                }
                if (!isLt2M) {
                    this.$message.error(this.$t('authentication8'));
                }
                return isJPG && isLt2M;
            },
            //移除文件列表时的钩子
            handleRemove(file, fileList) {
                this.images = fileList
                console.log("移除文件列表时的钩子", file);
            },
            //点击某个文件时的钩子
            handlePreview(file) {
                console.log("点击某个文件时的钩子", file);
            },
            //添加到上传列表时的钩子
            handleChange(file, fileList) {
                let flag = true
                let type = file.name.substr(file.name.indexOf('.')+1)
                const isJPG = type === 'jpg'|| type==='png' || type==='gif';
                const isLt2M = file.size / 1024 / 1024 < 2;
                if (!isJPG) {
                    flag = false
                    this.$message.error(this.$t('authentication7'));
                }
                if (!isLt2M) {
                    flag = false
                    this.$message.error(this.$t('authentication8'));
                }
                //文件不符合时移除
                this.images = fileList
                if(flag===false){
                    let rindex = this.images.findIndex(item=>{
                        return item.uid === file.uid
                    })
                    this.images.splice(rindex,1)
                }

                console.log("添加到上传列表时的钩子", file)
                return isJPG && isLt2M;

            },
            //文件超出个数限制时的钩子
            handleExceed() {
                this.$message.warning(this.$t('authentication9'))
            },
            // getImages() {
            //     this.$axios.get("/filelist")
            //         .then(response => {
            //             this.imageurls = response.data
            //             console.log("获取图片列表成功")
            //         })
            //         .catch(error => {
            //             this.$message.error("获取图片列表失败")
            //         })
            // }
        },
        created() {
            this.getImages()
        }
    }
</script>
<style scoped>

    .file-box {
        border: 1px solid #DCDFE6;
        width: 270px;
        margin: 10px auto;
        padding: 5px 5px 5px 5px;
        border-radius: 5px;
        -webkit-border-radius: 5px;
        -moz-border-radius: 5px;
        box-shadow: 0 0 25px #909399;
    }

    .el-row {
        margin-bottom: 20px;

    &
    :last-child {
        margin-bottom: 0;
    }

    }
    .el-col {
        border-radius: 4px;
    }

    .bg-purple-dark {
        background: #99a9bf;
    }

    .bg-purple {
        background: #d3dce6;
    }

    .bg-purple-light {
        background: #e5e9f2;
    }

    .grid-content {
        border-radius: 4px;
        margin-top: 10px;
        min-height: 36px;
    }

    .row-bg {
        padding: 10px 0;
        background-color: #f9fafc;
    }
</style>
