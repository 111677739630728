<template>
 <div class="page-web page-home">
		<Head />
  <div class="personal-center" >
    
    <el-card style="width: 360px; margin: 2px auto; background-color: #0b0e11; border-style:none; color:#fff">
      <img src="@/assets/img/user.png" alt="" style="width: 70px; " />
       
      <!-- <p style="width:600px; height:50px">{{$t('personal10')}}：     {{ userInfo.grade }}</p> -->
      <p style="width:600px; height:50px">{{$t('personal7')}}：{{ userInfo.userName }}</p>
      <p style="width:600px; height:50px">{{$t('personal8')}}：{{ userInfo.email }}</p>
      <p style="width:600px; height:50px">{{$t('personal9')}}：{{ userInfo.phonenumber }}</p>
      <p style="width:600px; height:50px">{{$t('personal12')}}：{{ userInfo.invite }}</p>  
      <div class="actions">
      <button @click="dialogFormVisible = true" style="width : 320px;">{{$t('personal15')}}</button>
    <el-dialog :title="$t('personal15')" :visible.sync="dialogFormVisible">
  <el-form ref="form" :model="updatapass" :rules="rules" label-width="120px">
    <el-form-item :label="$t('personal16')" >
      <el-input v-model="updatapass.invitepassword" show-password></el-input>
    </el-form-item>
    <el-form-item :label="$t('personal17')"  >
      <el-input v-model="updatapass.password" show-password></el-input>
    </el-form-item>
    <el-form-item :label="$t('personal18')"  >
      <el-input v-model="updatapass.affirmpassword" show-password></el-input>
    </el-form-item>
  </el-form>
  <div slot="footer">
    <el-button @click="dialogFormVisible = false">{{$t('transaction10')}}</el-button>
    <el-button type="primary" @click="changepassword()">{{$t('transaction11')}}</el-button>
  </div>
    </el-dialog> 
    </div>
    </el-card>
      <el-card style="width: 360px; margin: 10px auto; background-color: #0b0e11; border-style:none; color:#fff">
       <el-col :span="12"> 
      <img src="@/assets/img/smrz.svg" alt="" style="width: 200px; margin: 5px auto; " /></el-col>
      <el-col :span="12"> 
      <p style="width:600px; height:50px ; font-size:30px ;margin: 30px auto; ">{{$t('personal2')}}</p></el-col>
      <el-tag type="success"  v-show="userInfo.authentication==1" >{{$t('personal3')}}</el-tag>
      <el-tag type="success"  v-show="userInfo.authentication==2" >{{$t('personal4')}}</el-tag>
      <el-tag type="danger" v-show="userInfo.authentication==0"  @click="authentication()">{{$t('personal5')}}</el-tag>
      <el-tag type="danger" v-show="userInfo.authentication==3"  @click="authentication()">{{$t('personal6')}}</el-tag> 
    </el-card>
    <div class="user-info">
      <el-dialog :visible.sync="isVerified" >
        <h1 style="color:#ffffff; font-size:20px">{{$t('personal13')}}</h1>
        <div>{{$t('personal14')}}</div>
        <FileUpload @transmit="getMessage"/>
      </el-dialog>
    </div>

  
    <!-- <div class="actions">
      <button @click="dialogFormuser = true" style="width : 400px;">编辑信息</button>
    <el-dialog title="个人信息" :visible.sync="dialogFormuser">

  <el-form :model="form">
    <el-form-item label="手机号码" >
      <el-input v-model="userInfo.username"  onkeyup="this.value=this.value.replace(/\D/g,'')"></el-input>
    </el-form-item>
  </el-form>
  <div slot="footer">
    <el-button @click="dialogFormuser = false">取 消</el-button>
    <el-button type="primary" @click="dialogFormuser = false">确 定</el-button>
  </div>
    </el-dialog> 
    </div> -->
     
     
  </div>

  <Foot />
 </div>
</template>

<script>
import Head from '@/components/Head.vue'
import Foot from '@/components/Foot.vue'
import FileUpload from '@/components/FileUpload.vue'
import { gituserid } from '@/api/virtual/virtual'
import { updatapassword } from '@/api/virtual/register'
export default {
  components: {
			Head,
			Foot,
      FileUpload
		},
  data() {
    return {
      userInfo: {
      },
       rules: {
        fileList2: [{name: 'food.jpeg', url: 'https://fuss10.elemecdn.com/3/63/4e7f3a15429bfda99bce42a18cdd1jpeg.jpeg?imageMogr2/thumbnail/360x360/format/webp/quality/100'}],
        password: [
          { required: true, message: "$t('personal19')", trigger: 'blur' },
          { min: 6, message: "$t('personal20')", trigger: 'blur' }
        ],
        affirmpassword: [
          { required: true, message: "$t('personal21')", trigger: 'blur' },
          { validator: this.validatePassword, trigger: 'blur' }
        ]
      },
      updatapass:{
        invitepassword:'',
        password:'',
        affirmpassword:''
      },
      isVerified: false,
      dialogFormVisible: false,
      dialogFormuser: false,
      from:{},
    };
  },
  created(){
    this.gituser();
  },
  methods: {
            getMessage(data) {
                this.isVerified = data; //data即为子组件发送的数据
                this.gituser();
            },
      handleRemove(file, fileList) {
        console.log(file, fileList);
      },
      handlePreview(file) {
        console.log(file);
      },
    authentication(){
      this.isVerified=true;
      //this.userInfo.authentication=1;
    },
    validatePassword(rule, value, callback) {
      if (value !== this.updatapass.password) {
        callback(new Error("$t('personal22')"));
      } else {
        callback();
      }
    },
    editProfile() {
      // 编辑个人资料逻辑
      //console.log('点击了编辑个人资料按钮');
    },
    changepassword(){
      console.log("调用了具体值：",this.updatapass);
        updatapassword(this.updatapass).then(response =>{
            if(response.code==200)
            {
              localStorage.removeItem('token')
              localStorage.removeItem('invite')
              localStorage.removeItem('userId')
              localStorage.removeItem('userName')
              this.$store.commit('SET_ISLOGIN', false)
              this.$store.commit('SET_MEMBER', null)
              this.$message({
              message: response.msg,
              type: 'success'
             });
             this.$router.push('/')
            }
            else{
              this.$message.Error(response.msg)
            }
        })
        this.dialogFormVisible = false
    },
   gituser()
      {
        const id=  localStorage.getItem('userId');
        gituserid(id).then(response => {
          this.userInfo= response.data;
          if(this.userInfo.grade==0)
          {
            this.userInfo.grade=this.$t('personal11');
          }
          else{
            this.userInfo.grade="SVIP"+this.userInfo.grade;
          }
      });
  }
  },
   
};
</script>

<style scoped>
.personal-center {
  max-width: 400px;
  margin: 0 auto;
  padding: 3px;
  height: 750px;
}

.user-info{
  margin-bottom: 20px;
  border-radius: 4px;
  padding-left:50px;
}
.verification-status{
  margin-bottom: 20px;
}
.actions {
  margin-bottom: 20px;
}

button {
  padding: 10px 20px;
  margin-right: 10px;
}
</style>
