export default {
//Mensagem de erro
 error1: 'Erro do sistema. Contate o atendimento ao cliente.',
 error2: 'Sessão expirada. Por favor, faça login novamente!',
 meun1: 'Home',
 meun2: 'Tendências do Mercado',
 meun3: 'Subscrição BNBN',
 meun4: 'Compra de Mineração',
 meun5: 'Promoção Rápida',
 meun6: 'Livro Branco',
 meun7: 'Aviso',
 meun8: 'Entrada',
 meun9: 'Registro',
 meun10: 'Carteira',
 meun10_1: 'Conta Spot',
 meun10_2: 'Conta de Contrato em U',
 meun10_3: 'Conta de Contrato em Moeda',
 meun10_4: 'Contrato por Segundo',
 meun10_5: 'Conta de Gestão Financeira',
 meun10_6: 'Histórico da Carteira',
 meun11: 'Pedidos',
 meun11_1: 'Pedidos de Subscrição',
 meun11_2: 'Pedidos de Mineração',
 meun12: 'Atendimento',
 meun12_1: 'Informações de Atendimento',
 meun12_2: 'Número de Atendimento',
 meun12_3: 'Email de Atendimento',
 meun13: 'Bolsa de Recompensas',
 meun13_2: 'Receber Bolsa de Recompensas',
 meun13_3: 'Por favor, digite o código de recebimento da bolsa de recompensas',
 meun13_4: 'Cancelar',
 meun13_6: 'Receber',
 meun13_7: 'Cancelamento bem sucedido',
 meun13_1: 'Gestão de Conta',
 meun13_5: 'Sair',
 meun14_1: 'Idioma',
 meun14_3: 'Escolher Idioma',
 scan: 'Baixar através do código QR para iOS & Android',
 footmeun1: 'Sobre',
 footmeun1_1: 'Sobre Nós',
footmeun1_2: 'Junte-se a nós',
 footmeun1_3: 'Avisos da Plataforma',
 footmeun1_4: 'Livro Branco',
 footmeun2: 'Centro de Ajuda',
 footmeun2_1: 'Guia para Iniciantes',
 footmeun2_2: 'Perguntas Frequentes',
 footmeun2_3: 'Guia de Negociação',
 footmeun2_4: 'Informações sobre Moedas',
 footmeun3: 'Termos e Condições',
 footmeun3_1: 'Cláusula de Isenção de Responsabilidade',
 footmeun3_2: 'Cláusula de Privacidade',
 footmeun3_3: 'Acordo do Utilizador',
 footmeun3_4: 'Instruções de Taxas',
 footmeun4: 'Contate-nos',
 footmeun4_1: 'Email de Atendimento',
 footmeun4_2: 'Parcerias Comercial',
 footmeun4_3: 'Solicitação de Listagem',
 footmeun4_4: 'Reclamações e Denúncias',
 foottext: 'Plataforma Global de Solicitação de BNBN',
 countdown: 'A campanha já terminou',
 indexTxt1: 'Solicitação Global Inicial de BNBN',
 indexTxt2: 'Solicitação Global Inicial de BNBN',
 indexTxt3: 'Contagem Regressiva para o Lançamento',
 indexLabel1: 'Tendências do Mercado',
 indexVal1: 'Preços em Tempo Real de Moedas Criptografadas',
 indexLabel2: 'Solicitação de BNBN',
 indexVal2: 'Troca diretamente com uma moeda digital',
 indexLabel3: 'Compra de Mineração',
 indexVal3: 'Mineração de Moedas Criptografadas BNBN',
 indexLabel4: 'Promoção Rápida',
 indexVal4: 'Benefícios para Usuários Solicitantes',
 indexh5: 'Segurança Financeira',
 indexh6: 'Depósito e Levantamento Rápidos',
 indexh7: 'Serviço Global',
 indexh8: 'Projetos Rigorosamente Selecionados',
 indexp1: 'BITISAN foi criado por um grupo de participantes e geeks anteriores do Bitcoin. Os membros principais da equipe vêm de empresas conhecidas como Google, Microsoft, Alibaba, Tencent, etc., possuindo profunda capacidade de desenvolvimento e vasta experiência em operação de produtos da internet.',
 indexp2: 'BITISAN se posiciona como um provedor de serviços básicos de blockchain, com o objetivo de oferecer aos usuários globais uma plataforma de negociação de moedas criptografadas de alta qualidade. Seguindo o princípio básico de “não fazer mal”, mantemos um serviço honesto, justo e caloroso aos clientes, recebendo com atitude aberta todos os parceiros/projetos que beneficiam os interesses fundamentais dos usuários.',
 indexp3: 'Sistema de controle de risco financeiro e de segurança anti-roubo completo, com carteira fria/quente e sistema de assinatura múltipla para garantir a segurança dos fundos',
 indexp4: 'Depósito e levantamento podem ser feitos em até 3 minutos, com revisão online 24 horas, protegendo os clientes de não perderem as melhores oportunidades de investimento',
 indexp5: 'Cobertura global de rede de serviços para ajudá-lo a investir em moedas criptografadas globais e negociar com usuários de todo o mundo',
indexp6: 'Seleção rigorosa de projetos de criptografia de alta qualidade, filtrando 80% dos projetos de alto risco para você',
market: 'Informações de Mercado',
//Nova subscrição de moeda - Carteira
wallet1: 'Recharge Quantity',
    wallet2: 'The current USDT payment blockchain protocol is TRC20',
    wallet3: 'The payment amount received must be consistent with the payment amount displayed below, otherwise the system cannot confirm whether the recharge was successful.',
    wallet4: 'Order Number',
    wallet5: 'Payment amount',
    wallet6: '！! If you encounter any payment issues, please contact customer service promptly with your order number',
    wallet7: 'Payment timeout, please initiate payment again!',
usdt:'USDT',
platform:'BNBN',
usdt1: 'Saldo disponível',
usdt2: 'Saldo bloqueado',
platform1: 'Saque',
platform2: 'Recarga',
platform3: 'Solicitação',
transaction: 'Detalhes da Transação',
transaction0: 'Número',
transaction1: 'Número da Transação',
transaction2: 'Quantidade da Transação',
transaction3: 'Horário da Transação',
transaction4: 'Recarga de USDT',
transaction5: 'Endereço de Recarga',
transaction6: 'Saque de USDT',
transaction7: 'Saque sujeito à dedução de uma taxa',
transaction8: 'Endereço de Saque',
transaction9: 'Quantidade do Saque',
transaction10: 'Cancelar',
transaction11: 'Confirmar',
transaction12: 'Solicitação',
transaction13: 'A quantidade de solicitação refere-se à quantidade de USDT solicitada',
transaction14: 'Quantidade Solicitada',
transaction15: 'A quantidade solicitada deve ser superior a 0',
transaction16: 'A quantidade do saque deve ser superior a 10',
transaction17: 'Tipo de Moeda',
transaction18: 'Sem detalhes de transação',
transaction19: 'Por favor, selecione o endereço de recarga',
transaction20: 'Por favor, clique no botão novamente após 10 minutos',
transaction21: 'Exchange quantity',
	transaction22: 'exchange',
	transaction23: ' The number of exchanges means how many BNBNs need to be exchanged into USDT. Only the available balance can be exchanged. The locking balance does not support the exchange. (1BNBN=0.8USDT)',
  transaction24: 'The amount of exchange must be greater than 0',
//Mineração bloqueada
mining1: 'Solicitação',
mining2: 'Preço de Compra',
mining3: 'Rendimento Diário',
mining4: 'Dias de Bloqueio',
mining5: 'Nível de Compra',
mining6: 'Rendimento Total da Compra',
//convidar
inviteusers1:'Link de Convite',
inviteusers2: 'Número',
inviteusers5: 'Hora do Convite',
inviteusers6: 'Copiado com sucesso',
inviteusers7: 'Sem usuários convidados',
inviteusers8: 'Copiar',
inviteusers10:'Number of subscribers',
inviteusers11:'Is it active',
// Formulário de encomenda
order1: 'Ordem de Solicitação',
order2: 'Ordem de Gestão Financeira',
order3: 'Número da Solicitação',
order4: 'Quantidade Solicitada',
order5: 'Horário da Solicitação',
order7: 'Número da Gestão Financeira',
order8: 'Quantidade Bloqueada',
order9: 'Quantidade de Recompensas',
order10: 'Horário de Início',
order11: 'Horário de Fim',
//Centro Pessoal
personal1: 'Informações Pessoais',
personal2: 'Status de Autenticação Pessoal',
personal3: 'Autenticado',
personal4: 'Em Autenticação',
personal5: 'Não autenticado, clique aqui para autenticar',
personal6: 'Autenticação falhou, clique aqui para tentar novamente',
personal7: 'Nome de Usuário',
personal8: 'Email',
personal9: 'Número de Telefone',
personal10: 'Nível VIP',
personal11: 'Membro Comum',
personal12: 'Código de Convite',
personal13: 'Informações de Autenticação',
personal14: 'Favor carregue as informações de autenticação de identidade',
personal15: 'Modificar Senha',
personal16: 'Senha Original',
personal17: 'Nova Senha',
personal18: 'Confirmar Senha',
personal19: 'Favor digitar a senha',
personal20: 'A senha deve ter pelo menos 6 dígitos',
personal21: 'Favor digitar novamente a senha',
personal22: 'As senhas digitadas não são iguais',
authentication1: 'Adicionar Informações de Autenticação',
authentication2: 'Confirmar Carregamento',
authentication3: 'Somente arquivos jpg/png, com tamanho não superior a 2MB podem ser carregados',
authentication4: 'Carregar Arquivo',
authentication5: 'Adição de usuário e carregamento de arquivo bem sucedidos',
authentication6: 'Falha na adição de usuário e carregamento de arquivo',
authentication7: 'A imagem de perfil só pode ser em formato JPG!',
authentication8: 'O tamanho da imagem de perfil não pode exceder 2MB!',
authentication9: 'Número de arquivos excedido: 5',
// Login
account0: 'Nome de usuário ',
account: 'Login à conta BNBNCOIN',
phone: 'Número de telefone',
email: 'Email',
sign: 'Entrar',
password: 'Senha',
password2: 'Senha',
password3: 'Esqueci minha senha ',
account2: 'Criar conta ',
logErr: 'Por favor, digite o nome de usuário',
logErr2: 'Por favor, digite a senha',
logErr3: 'A senha deve ter pelo menos 6 dígitos',
logSuccess: 'Login bem sucedido',
resetpassword: 'Redefinir senha',
reset: 'Redefinir',


    //奖池
    pool1: 'The activity has not started yet!',
	pool2: 'Number of prizes',
	pool3: 'Prize details',
	pool4: 'Join now',
	pool5: 'I have participated in the event!',
	pool6: 'Participating users',
	pool7: 'The event has ended, and the list of winners is as follows',
	pool8: 'List of Award Winners',
  
// Registro
account3: 'Criar conta pessoal',
phone1: 'Registro via telefone',
email1: 'Registro via email',
email2: 'Endereço de email',
code: 'Código de verificação',
code2: 'Enviar código',
code3: 'Código',
password4: 'Confirmar senha',
invite: 'Código de convite',
agree: 'Eu li e concordei',
agreement: 'Termos de serviço',
register: 'Registro',
regErr: 'Por favor, digite o código de verificação',
confirmpwdtip: 'Por favor, digite a senha de confirmação',
confirmpwderr: 'As senhas inseridas não são idênticas!',
// Esqueci minha senha
wjtab: 'Redefinir senha via telefone',
wjtab2: 'Redefinir senha via email',
wjlabel: 'Nova senha',
wjerr: 'Por favor, digite a nova senha',
Tailpage:'Página de cauda',
	homepage:'Página inicial',
	lastpage:'última página',
	nextpage:'página seguinte',
	off:'fechar',
	problem:'Se você encontrar quaisquer problemas, incluindo autenticação de nome real ou depósitos que ainda não chegaram, consulte o serviço de atendimento ao cliente em tempo hábil',

// Anúncio
announcementtitle: 'Título do anúncio',
announcement: '<p>Depois da lançamento da mainnet em abril de 2019, a Binance Chain demonstrou seu desempenho rápido e design de alta capacidade. O seu principal foco - a aplicação descentralizada nativa ("dApp") Binance Chain DEX (Exchange Descentralizado), após testes, mostra que pode processar milhões de transações em pouco tempo, revelando a baixa latência do seu motor de negociação.</p>\
<p>Flexibilidade e usabilidade geralmente acompanham o desempenho. Enquanto o foco está em fornecer um lugar fácil para emissão e negociação de ativos digitais, esse design também traz algum grau de limitação. A voz mais alta da comunidade sempre foi para ver\
a Binance Chain adicionando funcionalidades de extensão programáveis, ou seja,\
contratos inteligentes e funcionalidades de máquinas virtuais.</p><p> Devido às limitações nas funcionalidades atuais da Binance Chain, é difícil para emissores e proprietários de ativos digitais adicionar novas\
funcionalidades aos seus ativos ou introduzir qualquer forma de\
gestão e atividades comunitárias.</p>\
<p>Embora as expectativas são altas para a Binance acrescentar funcionalidades de contrato inteligente\
à cadeia, esta é uma "decisão difícil". A execução de contratos inteligentes pode reduzir a velocidade do DEX e acrescentar incerteza às transações de ativos. Mesmo que essa concessão\
fosse tolerável, a solução mais óbvia talvez seria implementar máquinas\
virtuais baseadas no atual protocolo de consenso Tendermint e RPC principal da\
interface da Binance Chain. Mas essa solução aumentaria o custo de aprendizagem da comunidade existente de dApps, não sendo uma solução popular.\
Aqui nós apresentamos o conceito de uma cadeia paralela da Binance Chain, para manter o alto\
desempenho do DEX nativo enquanto apoiando amigavelmente funcionalidades de contrato inteligente.</p>',
 // Laboratório de Inovação
 labTxt: 'Laboratório Bitisan • Compartilhando resultados inovadores',
 labTxt2: 'Laboratório aberto de vantagens mútuas para investidores, projetos, exchanges e outros',
 labTab1: 'Todos',
 labTab2: 'Em breve',
 labTab3: 'Em curso',
 labTab4: 'Distribuindo',
 labTab5: 'Concluído',
 tokendistribute: "Distribuindo Tokens",
 activitytype: "Tipo de inscrição",
 labStart: 'Hora de início',
 labEnd: 'Hora de término',
 labStartPlaceholder: 'Selecione a hora de início',
 labDetail: 'Ver detalhes',
 labSpeen: "Progresso",
 labNumber: "Total de atividades",
 labType1: 'Listagem inicial (corrida)',
 labType2: 'Listagem inicial (divisão igual)',
 labType3: 'Divisão de posições',
 labType4: 'Inscrição livre',
 labType5: 'Inscrição em mineradoras de nuvem',
 labType6: 'Liberação de bloqueio',
 baseinfo: 'Informações básicas',
 ruledetail: "Detalhes do tipo",
 releaseType: "Tipo de liberação",
releaseType0: "Liberação igualária",
releaseType1: "Liberação proporcional",
lock: "Liberação após período de bloqueio",
releaseDetail: "Detalhes da liberação",
lockday: " dias",
lockweek: " semanas",
lockmonth: " meses",
lockyear: " anos",
releasePercent: "Percentual de liberação",
lockFee: "Limite de bloqueio",
releaseTimes: "Multiplicador de liberação",
times: "x",
publishprice: "Preço de inscrição",
holdcoin: "Moeda necessária para participação",
limittimes: "Número máximo de inscrições por pessoa",
limitamount: "Quantidade máxima por pessoa",
unlimited: "Sem limite",
leveloneCount: "Número de amigos nível 1 necessários",
alreadyamount: "Quantidade já comprada",
leftamount: "Quantidade remanescente",
myalreadyholdamount: "Minha participação atual",
alreadyholdamount: "Total de participação da campanha",
currentdivided: "Atualmente, minha participação pode receber aproximadamente",
holdtips: "Para participar da campanha de 'Divisão de Posições', será necessário bloquear a moeda até o término da campanha",
inputholdamount: "Digite a quantidade para bloquear",
pleaseinputamount: "Digite a quantidade para trocar",
pleaseinputholdamount: "Digite a quantidade para bloquear",
inputamount: "Digite a quantidade para trocar",
inputminingamount: "Digite a quantidade de mineradoras de nuvem para compra",
attend: "Participar imediatamente",
attention: "Atenção",
attentiontxt1: "1. Após submeter a participação nas campanhas de 'Divisão de Posições', 'Inscrição Livre', 'Inscrição em Mineradoras de Nuvem' e 'Bloqueio', os recursos serão bloqueados e serão desbloqueados ou liberados após o término da campanha.",
attentiontxt2: "2. Após participar nas campanhas de 'Divisão de Posições', 'Inscrição Livre', 'Inscrição em Mineradoras de Nuvem' e 'Bloqueio', os usuários não podem cancelar o pedido de participação.",
attentiontxt3: "3. Se a campanha for cancelada devido à equipa do projeto ou outros fatores de força maior, os recursos bloqueados serão retornados ao usuário.",
attentiontxt4: "* A Bitisan (WWW.BITISAN.NET) reserva o direito final de interpretação desta campanha.",
// Conta contratual baseada em moeda
transfer: 'Transferência de recursos',
placeholder: 'Pesquisar moeda',
bith1: 'Negociação de contratos',
bith2: 'Direitos da conta',
bith3: 'Lucro/Prejuízo não realizado',
bith4: 'Margem disponível',
bith5: 'Margem já utilizada',
bith6: 'Margem bloqueada',
bith7: 'Conta de contrato',
// Conta de spot
btn1: 'Recarregar',
btn2: 'Levantar dinheiro',
btn3: 'Transferência',
btn4: 'Recarregar moeda',
btn5: 'Levantar moeda',
totalAssets: 'Total de ativos convertidos',
th1: 'Nome da moeda',
th2: 'Ativos disponíveis',
th3: 'Ativos bloqueados',
th4: 'Ativos a serem liberados',
th5: 'Ações',
// Recarregar
czTxt: "Recarregamento de criptomoeda",
czTxt2: "Moeda",
czTxt3: "Rede de transferência",
czTxt4: "Saldo disponível",
czTxt5: "Endereço de levantamento",
czTxt6: "Quantidade a levantar",
czTxt7: "Quantidade recebida",
czTxt8: "Taxa",
czTxt9: "Aviso",
czTxt10: "Mínimo para recarregamento:",
czTxt10_1: "Recarregamentos inferiores à quantidade mínima não serão creditados.",
czTxt11: "Não recarregue nenhuma moeda não especificada no endereço acima, pois os ativos não serão recuperáveis.",
czTxt12: "Após recarregar o endereço acima, será necessário a confirmação de todos os nós da rede blockchain. Geralmente, os depósitos serão creditados após 3 confirmações da rede principal da blockchain.",
czTxt13: "O endereço de recarregamento não muda frequentemente, podendo ser usado para futuras recarregamentos; em caso de alteração, nós faremos o possível para avisá-lo através de avisos no site ou por e-mail.",
czTxt14: "Certifique-se de que seu computador e navegador estão seguros, evitando alterações ou vazamentos de informações.",
czTxt15: "Histórico de recarregamentos",
czTxt16: "Para garantir a segurança dos fundos, quando há alteração na estratégia de segurança da conta, mudança de senha ou uso de um novo endereço para levantamento, nós realizaremos uma revisão manual dos levantamentos. Por favor, aguarde um contato telefônico ou por e-mail de nossa equipe.",
czTxt17: "Mínimo para levantamento:",
czth1: 'Horário de levantamento',
czth2: 'De',
czth3: 'até',
czth4: 'Quantidade de troca rápida',
czth5: 'Taxa de troca',
czth6: 'Quantidade trocada',
placeholder2: 'Digite',
placeholder3: 'Selecione',
czEmpty: 'Sem registros',
logintip: 'Por favor, faça login primeiro!',
cztip: 'Você ainda não solicitou um endereço de recarregamento. Clique no botão para obter o endereço de recarregamento',
czget: 'Obter endereço de recarregamento',
czplaceholder: 'Selecione um protocolo',
// Conta de contrato rápida
mAccount: "Conta de contrato rápida",
// Histórico da carteira
btn6: 'Pesquisar',
wtTxt: 'Período',
wtTxt2: 'Tipo de operação',
wtth1: 'Hora da transação',
wtth2: 'Tipo',
wtth3: 'Moeda',
wtth4: 'Quantidade',
wtth5: 'Taxa devida',
wtth6: 'Taxa descontada',
wtth7: 'Taxa efetiva',
wtth8: 'Status',
// Segurança da conta
nav1: 'Segurança da conta',
nav2: 'Minha convite',
nav3: 'Meu cartão promocional',
acTxt: 'Nível de segurança',
acbtn1: 'Não autenticado',
acbtn2: 'Associar',
acbtn3: 'Modificar',
acbtn4: 'Autenticado',
acbtn5: 'Associado',
acbtn6: 'Configurar',
acbtn7: 'Em análise',
acbtn8: 'Tentar novamente',
acTxt2: 'Autenticação real',
acTxt3: 'E-mail',
acTxt4: 'Celular',
acTxt5: 'Senha de login',
acTxt6: 'Senha de fundos',
acTxt7: 'Validador do Google',
acTxt8: 'Configurar senha de fundos',
acTxt9: 'Alterar senha de fundos',
acTxt10: 'Recuperar senha de fundos',
acDesc2: 'Para garantir a segurança de sua conta, por favor, complete a autenticação real antes de realizar operações de negociação!',
acDesc3: 'Associar e-mail',
acDesc4: 'Associar celular',
acDesc5: 'Usado para fazer login na plataforma',
acDesc6: 'Para alterações de fundos na conta, será necessário validar a senha de fundos',
acDesc7: 'Por favor, associar o validador do Google',
acDesc8: 'Validador do Google já associado',
acDesc9: 'Desassociar',
acTit: 'Associar celular',
acTit2: 'Associar e-mail',
acTit3: 'Associar Google',
acTit4: 'Desassociar Google',
mailtip: 'Por favor, digite o e-mail',
emailerr2: 'Formato do e-mail incorreto, por favor tente novamente',
agreementtip: 'Por favor, leia e aceite o acordo do usuário',
acverify: "Avaliação não aprovada",
again: "Por favor, tente novamente",
verifiedmsg: 'Por favor, efetue a autenticação real primeiro',
// Alterar senha de login
chTxt: 'Alterar senha de login',
chTxt2: 'Associar através de código QR',
chTxt3: 'Por favor, digite o código de validação',
chTxt4: 'Senha de fundos antiga',
chTxt5: 'Nova senha de fundos',
chTxt6: 'Confirmar nova senha',
chTxt7: 'Esqueci minha senha',
chTxt8: 'Alterar validação de celular',
chTxt9: 'Senha de login antiga',
chTxt10: 'Nova senha de login',
chTxt11: 'Código de validação do e-mail',
chTxt12: 'Código de validação do celular',
telerr: 'Número de celular incorreto',
emailerr: 'E-mail incorreto',
codeerr: 'Código de validação incorreto',
save: 'Salvar',
chtip: 'Por favor, digite a senha antiga',
chtip2: 'Por favor, digite uma nova senha de login com pelo menos 6 dígitos',
chtip3: 'Nova senha de login não corresponde',
chtip4: 'Por favor, digite a senha de fundos correta',
chtip5: 'A senha deve ter pelo menos 6 caracteres',
chtip6: 'Por favor, digite uma senha com pelo menos 6 dígitos',
chtip7: 'Senhas não correspondem',
chtip8: 'Por favor, digite a senha de fundos',
chtip9: 'Por favor, digite o número de celular',
chtip10: 'Por favor, digite a senha de login',
realnametip: 'Por favor, digite o nome verdadeiro',
idcardtip: 'Por favor, digite o número do documento de identificação',
// Métodos de recebimento
clTxt: 'Por favor, configure os seus métodos de recebimento. Certifique-se de usar uma conta em nome próprio',
clTxt2: 'Adicionar método de recebimento',
edit: 'Editar',
delete: 'Apagar',
name: 'Nome',
clTxt3: 'Número de ID',
clTxt4: 'Número IBAN',
clTxt5: 'Todos os métodos de recebimento',
clLink1: 'Nome da conta',
clLink2: 'Editar método de recebimento',
clLink3: 'Detalhes do pagamento (opcional)',
clplaceholder1: 'Por favor, digite o seu nome completo',
clplaceholder2: 'Por favor, digite a senha da sua conta bancária',
clplaceholder3: 'Por favor, digite os detalhes do seu pagamento',
cltip: 'Dica especial',
cltip2: 'Certifique-se de adicionar o seu número de cartão bancário para efetuar pagamentos instantâneos. Não incluir detalhes de outros bancos ou métodos de pagamento. Você deve adicionar informações de pagamento/recebimento do banco selecionado.',
cltip3: 'Dica: Quando você vende criptomoedas, o método de recebimento escolhido será exibido para o comprador. Por favor, confirme que as informações estão corretas. Utilize uma conta de recebimento compatível com a sua KYC (Know Your Customer) verificação na plataforma Binance.',
clTxt6: 'Opcional',
upload: 'Carregar',
uploadTip: '(Suportados: JPG/JPEG/PNG/BMP, tamanho inferior a 1MB)',
// Meu cartão promocional
tgbtn: 'Trocar código promocional',
tgth1: 'Nome do cartão',
tgth2: 'Código promocional',
tgth3: 'Moeda do cartão',
tgth4: 'Valor do cartão',
tgth5: 'Total',
tgth6: 'Trocados',
tgth7: 'Horário de recebimento',
tgbtn2: 'Material promocional',
tgbtn3: 'Trocar agora',
tgplaceholder: 'Por favor, digite o código promocional',
exchangesuccess: "Troca efetuada com sucesso! Vá à gestão de recursos para verificar o saldo!",
// Meus convites
inth1: 'Usuários convidados',
inth2: 'Horário de registro',
inth3: 'Status de autenticação',
// O meu anúncio
adTxt: 'Meu anúncio',
adTxt2: 'Quando a quantidade comprada pelo valor mínimo do anúncio, mais a taxa, for superior à quantidade remanescente do anúncio, o anúncio será automaticamente retirado',
adbtn: 'Limpar condições',
adbtn2: 'Publicar',
adbtn3: 'Retirar',
adth1: 'Número do anúncio',
adth2: 'Tipo de anúncio',
adth3: 'Limites de ordem',
adth4: 'Quantidade remanescente',
adtip: 'Tem certeza que deseja excluir?',
num_text1: 'Por favor, insira a quantidade que você deseja',
num_text2: '',
// Comissão actual
wTxt: 'Mercado spot',
wTxt2: 'Ordens ativas',
wTxt3: 'Par de negociação',
wTxt4: 'Direção da negociação',
wth1: 'Hora',
wth2: 'Direção',
wth3: 'Preço',
wth4: 'Concluídas',
wth5: 'Valor negociado',
wth6: 'Tipo de ordem',
wth7: 'Preço de disparo',
wth8: 'Preço de negociação',
wth9: 'Quantidade da ordem',
wth10: 'Lucro/Prejuízo líquido',
wth11: 'Status da ordem',
wth12: 'Abertura/Encerramento',
wth13: 'Garantia',
wbtn: 'Cancelar ordem',
wtip: 'Aviso de cancelamento',
wtiptxt: 'Tem certeza que deseja cancelar a ordem?',
// Ganhar moedas
zbplaceholder: 'Insira o número da ordem para começar a pesquisar',
zbtab1: 'Planos de investimento periódico',
zbtab2: 'Registros de investimento periódico',
zbth1: 'Ciclo de investimento',
zbth2: 'Ligado/Desligado',
zbth3: 'Valor total investido',
zbth4: 'Quantidade detida',
zbth5: 'Custo médio',
zbth6: 'Lucro/Prejuízo não realizado',
zbth7: 'Data da próxima compra periódica',
zbth8: 'Data de criação do plano',
// Comissão actual-1
etTxt: 'Centro de avisos',
etTxt2: 'Compartilhar através de QR Code',
etmenu1: 'Criptomoedas',
etmenu2: 'Avisos de atualização do servidor Bitisan',
// Comissão actual 3
et2menu1: 'Introdução à RX.Tron',
et2menu2: 'O Bitcoin é uma pirâmide de Ponzi?',
//Comissão histórica
hisTxt: 'Ordens históricas',
// Minha ordem
orTab1: 'Pendente',
orTab2: 'Pago',
orTab3: 'Completado',
orTab4: 'Cancelado',
orTab5: 'Em reclamação',
orth1: 'Número da ordem',
orth2: 'Moeda negociada',
orth3: 'Tipo de negociação',
orth4: 'Parte negociada',
orth5: 'Valor',
// Contrato de opção
qcTab1: 'Resultados anteriores',
qcTab2: 'Cotações em tempo real',
qcTxt: 'Período número',
qcTxt2: '',
qcTxt3: 'Horário de abertura',
qcTxt4: 'Horário de encerramento',
qcTxt5: 'Preço de abertura',
qcTxt6: 'Preço de encerramento',
qcTxt7: 'Total de apostas em alta',
qcTxt8: 'Total de apostas em baixa',
qcTxt9: 'Minha abertura de posição',
qcTxt10: 'Progresso da previsão',
qcTxt11: 'Preço de abertura',
qcTxt12: 'Preço de encerramento',
qcTxt13: 'Período de previsão deste período',
qcbtn1: 'Aposta em alta',
qcbtn2: 'Aposta em baixa',
qcth1: 'Número do contrato',
qcth2: 'Valor aberto',
qcth3: 'Direção da previsão',
qcth4: 'Resultado da previsão',
qcth5: 'Quantia de premio',
qcth6: 'Taxa de abertura de posição',
qcth7: 'Retirada',
// Detalhes da Moeda
Internet: 'Erro de conexão à internet',
mplaceholder: 'Insira o nome da moeda para pesquisar',
mTab: 'Selecionados',
mth: 'Favorito',
mth1: 'Preço atual',
mth2: 'Variação 24h',
mTxt: 'Preço máximo 24h',
mTxt2: 'Preço mínimo 24h',
mTxt3: 'Volume de negociação',
mTxt4: 'Taxa de funding',
mTxt5: 'A',
mTxt6: 'M',
mTxt7: 'M',
mTxt8: 'F',
indexth: 'Total negociado',
indexbtn: 'Detalhes',
indexbtn1: 'Preço',
indexbtn2: 'Quantidade',
indexbtn3: 'Volume negociado',
indexbtn4: 'Horário',
echat1: 'Gráfico K-line',
echat2: 'Gráfico de profundidade',
mTab2: 'Posições abertas',
mTab3: 'Histórico de ordens',
mth3: 'Cobertura',
mth4: 'Preço de abertura',
mth5: 'Período',
mth6: 'Preço de encerramento',
mth7: 'Lucro',
mTxt9: 'Modo de negociação',
mTxt10: 'Horário de abertura',
mTxt11: 'Taxa de lucro',
mTxt12: 'Saldo',
mTxt13: 'Volume comprado',
mTxt14: 'M',
// Criar anúncios
cadTxt: 'Criar uma oferta de negociação',
cadTxt2: 'Se você realiza transações frequentemente, você pode criar seus próprios anúncios de negociação.',
cadTxt2_1: 'Se você negocia apenas ocasionalmente, sugerimos que você pesquisa diretamente',
cadTxt2_2: 'Criar um anúncio de negociação é gratuito.',
cadLink2: 'Anúncio de negociação',
cadTxt3: 'Para editar diretamente um anúncio já criado, vá em',
cadLink3: 'Meus anúncios',
cadTxt4: 'Preço de participação no mercado',
cadTxt5: 'O preço acima do mercado é um percentual acima do preço atual de venda',
cadTxt6: 'Fórmula de cálculo',
cadTxt7: '【Dica】Vá ao centro de usuários para vincular/adicionar métodos de pagamento',
cadTxt8: 'O valor máximo de negociação não pode exceder o total do seu valor de venda de 0CNYI',
cadTxt9: 'Após ativado, os usuários podem iniciar uma negociação através deste anúncio, e o sistema enviará automaticamente a resposta automática escolhida pelo vendedor ao comprador.',
cadTxt10: 'O preço abaixo do mercado é um percentual abaixo do preço atual de compra',
cadLabel1: 'Eu quero:',
cadLabel2: 'Moeda para negociação:',
cadLabel3: 'País:',
cadLabel4: 'Moeda:',
cadLabel5: 'Ativar preço fixo',
cadLabel6: 'Preço acima do mercado:',
cadLabel7: 'Preço de negociação',
cadLabel8: 'Quantidade à venda:',
cadLabel9: 'Método de pagamento:',
cadLabel10: 'Valor mínimo de negociação:',
cadLabel11: 'Valor máximo de negociação:',
cadLabel12: 'Ativar resposta automática',
cadLabel13: 'Senha de fundos:',
cadLabel14: 'Informações complementares:',
cadLabel15: 'Preço fixo:',
cadLabel16: 'Resposta automática:',
cadLabel17: 'Quantidade à compra:',
radio: 'Venda online',
radio2: 'Compra online',
cadplaceholder: 'Por favor, defina o seu preço acima do mercado',
cadplaceholder2: 'Digite a quantidade que você deseja vender',
cadplaceholder3: 'Digite o valor mínimo de negociação',
cadplaceholder4: 'Digite o valor máximo de negociação',
cadplaceholder5: 'Em informações complementares, você pode inserir seus requisitos especializados, por exemplo: requisitos para o comprador, horários online, etc.',
cadplaceholder6: 'Digite o preço de negociação',
cadplaceholder7: 'A mensagem automática que será enviada ao comprador após receber o pedido, por exemplo: método de pagamento, conta de recebimento, etc.',
cadplaceholder8: 'Digite a quantidade que você deseja comprar',
caderr1: 'Digite o seu valor mínimo de negociação',
caderr2: 'O valor mínimo de negociação deve ser igual ou superior a 100!',
caderr3: 'O valor mínimo de negociação deve ser inferior ao valor máximo de negociação',
caderr4: 'Digite o seu valor máximo de negociação!',
caderr5: 'Digite um número inteiro',
caderr6: 'O valor máximo de negociação deve ser superior ao valor mínimo de negociação!',
caderr7: 'O valor máximo de negociação não pode exceder o seu total de venda',
caderr8: 'Escolha o método de negociação',
submit: 'Enviar',
cadnew: 'Após ativado, o preço de sua moeda não será afetado pelas flutuações do mercado, mantendo-se constante.',
// Resgate rápido
dhTxt: 'Troca rápida · Lucro garantido',
dhTxt2: 'Preço limitado',
dhTxt3: 'Vender',
dhTxt4: 'Comprar',
dhTxt5: 'Máximo',
dhTxt6: 'Digite o valor',
dhplaceholder: 'Saldo disponível na carteira de ações',
dhSuccess: 'Parabéns! Troca realizada com sucesso!',
// Negociação de moeda legal
crTxt: 'Troca de moeda legal',
crTxt2: 'Comprar e vender criptomoedas com conveniência, segurança e rapidez',
crTxt3: 'Ajuste dinâmico',
crTxt4: 'Sem taxas',
crTxt5: 'Negociação imediata',
crTxt6: 'Garantia da plataforma',
crTxt7: 'Flutuações em tempo real com base no preço de mercado',
crTxt8: 'O preço de compra e venda exibido pelo usuário é final, sem taxas da plataforma',
crTxt9: 'Comerciantes de serviços da plataforma são introduzidos para correspondência inteligente de pedidos, sem necessidade de espera',
crTxt10: 'Os comerciantes certificados pela plataforma oferecem segurança garantida, com atendimento 24 horas para garantir a segurança das transações',
crth1: 'Comerciante',
crth2: 'Número de negociações',
crth3: 'Método de pagamento',
crth4: 'Limite',
crth5: 'Preço unitário',
crbtn: 'Comprar',
crbtn2: 'Tornar-se comerciante',
// Negociação de moeda legal 1
cr1Txt: 'Parabéns! Sua certificação de comerciante já foi aprovada',
cr1Txt2: 'Preparar documentos',
cr1Txt3: 'Enviar para aprovação',
cr1Txt4: 'Certificado',
cr1Txt5: 'Você obtém os seguintes privilégios',
cr1Txt6: 'Espaço exclusivo',
cr1Txt7: 'Serviço personalizado',
cr1Txt8: 'Os comerciantes têm um espaço publicitário exclusivo, aumentando a taxa de sucesso das negociações',
cr1Txt9: 'Taxas mais baixas',
cr1btn: 'Publicar anúncio',
cr1btn2: 'Solicitar cancelamento do seguro',
// Gestão regular da riqueza
reTxt: 'Bitisan Gestão Financeira • Solução completa de investimento',
reTxt2: 'Ganhe rendimentos imediatamente, simples & seguro',
reTab: 'Gestão Financeira Fixa',
reTab2: 'Investimento Fixo',
reTxt3: 'Fixo',
reTxt4: 'Personalização flexível, maiores ganhos potenciais',
reTxt6: 'Investimento Fixo',
reTxt7: 'Comece a usar investimento fixo e aumente seus bens',
reTxt8: 'Expandir todos os 18 produtos fixos',
reth1: 'Taxa de retorno anual',
reth2: 'Prazo (dias)',
reth3: 'Limite de negociação',
reth4: 'Produto',
reth5: 'Taxa de retorno histórico',
rebtn: 'Criar plano',
redTxt: 'Criar um',
redTxt2: 'Plano de investimento fixo',
redTxt3: 'Digite a quantidade de investimento',
redTxt4: 'Meu saldo disponível é',
redTxt5: 'Disponível',
redTxt6: 'Ciclo de repetição',
redTxt7: 'Todos os dias',
redTxt8: 'Semanal',
redTxt9: 'Mensal',
redTxt10: 'Horário local',
redTxt11: 'O seu primeiro ciclo de investimento automático começará em',
redTxt12: 'Comece',
confirm: 'Confirmar',
confirm2: 'Confirmar',
cancel: 'Cancelar',
redongoing: "Em curso",
redcompleted: "Concluído",
minNumErr: 'Menor que a quantidade mínima de investimento',
minNumErr2: 'A quantidade mínima de investimento não pode ser 0',
buyamounttip: 'Digite a quantidade de compra',
pricetipwarning: 'A quantia de negociação não pode ser superior a',
buyamounttipwarning: 'A quantidade de compra não pode ser superior a',
sellamounttip: 'Digite a quantidade de venda',
sellamounttipwarning: 'A quantidade de venda não pode ser superior a',
sellpricetip: 'Digite o preço de venda',
sellpricetipwarning: 'O preço de venda não pode ser superior a',
sellmore: 'Máximo possível de venda',
sellunit: 'unidades',
loginFirst: "Faça login primeiro",
betsuccess: "Parabéns! Aposta bem sucedida!",
selectAmount: "Selecione a quantidade de apostas",
balancenotenough: "Saldo disponível insuficiente!",
balancenotenough2: "Saldo insuficiente!",
minlimitamountfailed: "A quantidade de troca não pode ser inferior à mínima!",
maxlimitamountfailed: "A quantidade de troca não pode ser superior à máxima!",
limittimesfailed: "A sua participação ultrapassou o limite máximo personalizado!",
commitfailed: "Falha ao submeter a participação!",
// Negociação à vista
trplaceholder: 'Pesquisa',
trplaceholder2: "Preço mais recente",
trplaceholder3: 'Comprar pelo melhor preço do mercado',
trplaceholder4: 'Vender pelo melhor preço do mercado',
triggleplaceholder: "Preço padrão de oferta é o preço de mercado",
triggerpriceshouldbigger: "O preço de disparo deve ser maior que 0",
marginModeSuccessTip: "Modo de conta de contrato alterado com sucesso",
noenoughbalance: "Saldo de garantia da conta insuficiente",
pleaseinputopenvolume: "A quantidade de abertura de posição deve ser maior que 0",
pleaseinputcorrectopenvolume: "Digite a quantidade correta de abertura de posição",
pleaseinputrightentrustprice: "Digite o preço correto de oferta",
noenoughposition: "Posição disponível para fechamento insuficiente",
pleaseinputclosevolume: "A quantidade de fechamento de posição deve ser maior que 0",
pleaseinputcorrectclosevolume: "Digite a quantidade correta de fechamento de posição",
option1: 'Linha diária',
option2: 'Linha semanal',
option3: 'Linha mensal',
trTxt: 'Volume de negociação',
trTxt2: 'Preço de mercado',
trTxt3: 'Quantidade de venda',
trSuccess: 'Submissão bem sucedida',
// u本位
udTxt: 'Modo de garantia',
udTxt2: 'Ajustar multiplicador do contrato',
udTxt3: 'Alavancagem',
udTxt4: 'Perpétuo',
udTxt4_1: 'Perpétuo',
udTxt5: 'Limite transferível',
udTxt6: 'Quantidade a transferir',
udTxt7: 'Transferir tudo',
udTxt8: 'Conta perpétua',
ubtn: 'Todas as posições',
ubtn2: 'Posições individualizadas',
uplaceholder: 'Conta de contrato perpétuo',
uradio1: 'Intervalo',
uradio2: '15 minutos',
uradio3: '1 hora',
uradio4: '4 horas',
uradio5: '1 dia',
uth1: 'Multiplicador do contrato',
uth2: 'Lucro',
uth3: 'Taxa de retorno',
uth4: 'Quantidade de posição',
uth5: 'Quantidade disponível para fechamento',
uth6: 'Preço médio de abertura',
uth7: 'Preço forte para venda',
uth8: 'Caução atual',
uth9: 'Taxa de caução',
zhang: 'zhang',
or: 'ou',
ureg: 'Registrar agora',
uTxt8: 'Realizar negociação',
uTab: 'Abrir posição',
uTab2: 'Fechar posição',
uTxt9: 'Abrir longa possível',
uTxt10: 'Abrir curta possível',
uTxt11: 'Preço de oferta',
uplaceholder2: 'Negociação pelo melhor preço do mercado',
uTxt12: 'Comprar para abertura longa',
uTxt13: 'Vender para abertura curta',
closeup: "Comprar para fechar curta",
closedown: "Vender para fechar longa",
uTab3: 'Limite de lucro/perda',
uTab3_yprice: 'Preço de limite de lucro',
uTab3_sprice: 'Preço de limite de perda',
uTab3Txt: 'Limite de lucro/perda',
uTxt14: 'Minha conta de contrato',
ulabel1: 'Modo de conta',
ulabel2: 'Lucro total da conta',
ulabel3: 'Caução da posição',
ulabel4: 'Utilização de fundos',
ulabel5: 'Longa',
ulabel6: 'Curta',
uTxt15: 'Posição longa',
uTxt16: 'Vaga',
total: 'Total',
limited_price: 'Oferta limitada',
market_price: 'Oferta pelo mercado',
spot_price: 'Limite de lucro/perda',
ent_status1: "Oferta em aberto",
ent_status2: "Oferta cancelada",
ent_status3: "Falha na oferta",
ent_status4: "Oferta realizada",
ent_statusblast: "Liquidação forçada",
uTxt17: 'Posições longas disponíveis para fechamento',
uTxt18: 'Posições curtas disponíveis para fechamento',
//
prev: 'Anterior',
next: 'Próximo',
// Parceiro de promoção
prtxt: 'Meu link de convite',
copy: 'Copiar',
prtxt2: 'Meus amigos de nível 1',
prtxt3: 'Meus amigos de nível 2',
prtxt4: 'Rendimento de comissão (equivalente em USDT)',
prtxt5: 'Bônus adicional',
prtxt6: 'Nível de parceria',
prtit: 'Detalhes do regulamento',
prdesc: 'O programa 【Parceiro Promotor】 da Bitisan é a campanha de retorno de comissão com a maior proporção de retorno e a maior duração (até retorno de comissão vitalício) em toda a rede. Ele permite aos promotores que se esforçam para serem verdadeiros “parceiros” da plataforma, compartilhando os lucros da crescimento conjunto da Bitisan! Detalhes do regulamento estão abaixo:',
prli1: 'I. Para promover a ideia de ativos digitais e expandir a escala de usuários, a Bitisan lançou o programa 【Parceiro Promotor】 que estará válido permanentemente.',
prli2: 'II. Convidar amigos é dividido em dois níveis. Por exemplo, se A convidar B, então B será amigo de nível 1 de A. Se B convidar C, então C será amigo de nível 1 de B e também amigo de nível 2 de A.',
prli3: 'III. Para registrar um amigo convidado, ele deve acessar o link fornecido pelo convidador ou inserir manualmente o código de convite do convidador para ser reconhecido como amigo de nível 1.',
prli4: 'IV. O convidado deverá completar o processo de autenticação para que a comissão de retorno possa entrar em vigor.',
prli5: 'V. O período de retorno de comissão começa no mês seguinte à conclusão da autenticação do convidado e dura N meses, dependendo do nível, há diferentes proporções de retorno de comissão.',
prli6: 'VI. A comissão de retorno da promoção é composta pela taxa de transação de moeda-para-moeda dos amigos convidados. As moedas de retorno são as três principais moedas do mercado, ou seja: USDT, BTC, ETH.',
prli7: 'VII. A proporção de retorno de comissão, a duração do retorno e o número de amigos convidados de nível 1 estão em correlação positiva. Quanto mais pessoas sejam convidadas, maior será a proporção de retorno de comissão. As proporções de retorno de comissão específicas são as seguintes:',
prli8: 'VIII. Os TOP 10 ou TOP 100 da lista de retorno de comissão da promoção receberão periodicamente bônus extras emitidos pela Bitisan oficial.',
prli9: 'IX. Se outras atividades entrarem em conflito com o programa 【Parceiro Promotor】, então será necessário decidir, após negociação, se haverá alteração temporária. Pedimos compreensão aos parceiros.',
prli10: 'X. Os parceiros níveis L4 (Patrocinador), L5 (Prefeito), e L6 (Governador Militar) receberão, respectivamente, 5%, 10% e 15% do total de comissões retornadas anualmente como recompensa de dividendos.',
prli11: 'XI. A Bitisan reserva-se o direito final de interpretação desta campanha.',
prth1: 'Nível',
prth2: 'Número de amigos de nível 1',
prth3: 'Proporção de comissão retornada / Tempo de retorno para amigos de nível 1',
prth4: 'Proporção de comissão retornada / Tempo de retorno para amigos de nível 2',
prth5: 'Dividendos do parceiro',
prlev1: 'Li Zheng',
prlev2: 'Xian Ling',
prlev3: 'Zhi Fu',
prlev4: 'Xu Fu',
prlev5: 'Tai Shou',
prlev6: 'Jie Du Shi',
prtd1: 'meses',
prtd2: 'Retorno de comissão vitalício',
prtit2: 'Exemplo',
prtxt7: 'Se o usuário Xiao Yan convidar 100 amigos de nível 1, e cada um desses amigos convidar 5 amigos de nível 2, então Xiao Yan terá 100 amigos de nível 1 e 500 amigos de nível 2. Se cada um deles fizer uma média de US3.000emtransac c​oespordia,arendamensaldeXiaoYansera aproximadamente:(100∗3000∗0.001∗307.200/mês.',
prtxt8: 'Se o usuário Xiao Yan convidar 1000 amigos de nível 1, e cada um desses amigos convidar 5 amigos de nível 2, então Xiao Yan terá 1000 amigos de nível 1 e 5000 amigos de nível 2. Se cada um deles fizer uma média de US3.000emtransac c​oespordia,arendamensaldeXiaoYansera aproximadamente:(1000∗3000∗0.001∗50135.000/mês.',
prtit3: 'Caixa de ferramentas de promoção',
prtit4: 'TOP 20 do total de comissões retornadas da promoção',
prtit5: 'TOP 20 do total de pessoas convidadas',
prtxt9: 'Data final do relatório:',
prth6: 'Ranking',
prth7: 'Membro',
prth8: 'Número de pessoas convidadas',
prth9: 'Comissões retornadas (equivalente em USDT)',
prth10: 'Bônus adicional',
prtip1: 'Os dados de comissões acima não são atualizados em tempo real, sendo atualizados pelo sistema uma vez por 24 horas.',
prtip2: 'O número de convidados é o número de amigos de nível 1, e este ranking é baseado no total de comissões retornadas.',
prtip3: 'O número de convidados é o número de amigos de nível 1, e este ranking é baseado no número de convites de nível 1.',
// 充币
recth1: 'Tempo de chegada',
recth2: 'Protocolo de rede',
recth3: 'Endereço de depósito',
recth4: 'Quantidade depositada',
recbtn: 'Depósito via código de retirada',
recplaceholder: 'Por favor, digite o código de retirada',
recstatus_0: 'Falha',
recstatus_1: 'Não recebido',
recstatus_2: 'Recebido',
// Retirada de moedaswittit:
 //'Retirada de criptomoeda',
wittext: 'Histórico de retiradas',
wittext2: 'Código de retirada',
wittext3: 'Lista de códigos de retirada',
witbtn: 'Retirada via código',
witbtn2: 'Gerar código de retirada',
witdia: 'Verificação de segurança',
witdia2: 'Código de retirada gerado com sucesso',
witplaceholder: 'Por favor, digite a quantidade de retirada',
witerr: 'Por favor, selecione a moeda',
addresstip: 'Por favor, preencha o endereço',
witstate: 'Pendente de troca',
witstate2: 'Falha',
witstate3: 'Troca completada',
witstatus_1: 'Em análise',
witstatus_2: 'Em transferência',
witstatus_3: 'Falha',
witstatus_4: 'Sucesso',
//Tipo de operação
type1: 'Transferência',
type2: 'Troca de criptomoedas',
type3: 'Compra de moeda fiduciária',
type4: 'Venda de moeda fiduciária',
type5: 'Recompensa por atividade',
type6: 'Recompensa por promoção',
type7: 'Dividendos',
type8: 'Votação',
type9: 'Recarga manual',
type10: 'Emparelhamento',
type11: 'Troca por atividade',
type12: 'Compra C2C',
type13: 'Venda C2C',
type14: 'Enviar bónus',
type15: 'Receber bônus',
type16: 'Retirada via código',
type17: 'Depósito via código de retirada',
type18: 'Taxa de contrato perpétuo',
type19: 'Lucro do contrato perpétuo',
type20: 'Perda do contrato perpétuo',
type21: 'Falha do contrato de opções',
type22: 'Taxa de contrato de opções',
type23: 'Premiação de contrato de opções',
type24: 'Reembolso de comissões de contrato',
type25: 'Recompensa de nível igual',
type26: 'Receita de taxas de plataforma',
type27: 'Falha do contrato instantâneo',
type28: 'Premiação do contrato instantâneo',
type29: 'Juros de investimento',
type30: 'Despesas de saída de fundos',
type31: 'Receita de entrada de fundos',
type32: 'Compra programada',
type33: 'Venda programada',
type34: 'Compra periódica',
type35: 'Resgate periódico',
type36: 'Transferência de entrada de contrato de base em moeda',
type37: 'Transferência de saída de contrato de base em moeda',
type38: 'Transferência de entrada de contrato de base em U',
type39: 'Transferência de saída de contrato de base em U',
type40: 'Transferência de entrada de contrato instantâneo',
type41: 'Transferência de saída de contrato instantâneo',
type42: 'Transferência de entrada de troca de moeda',
type43: 'Transferência de saída de troca de moeda',
// Conta de gestão patrimonial
lctit: 'Conta de gestão financeira',
lcbtn: 'Transferência para gestão financeira',
lcth1: 'Juros acumulados',
day: 'dia',
lctxt: 'Moeda promocional',
lctxt2: 'Moeda aceita',
lctxt3: 'Quantidade mínima',
lctxt4: 'Escolher período',
year: 'ano',
month: 'mês',
// Ordens de gestão patrimonialldtab1: "Registro de posição",
ldtab2: "Registro de liquidação",
ldth1: "Data de início dos rendimentos",
ldth2: "Data de resgate",
ldth3: "Estimativa de rendimentos",
ldth4: "Rendimentos",
// Plano de investimento fixo
attit: "Modificar plano de investimento programado",
attxt: "Quantidade de investimento",
attxt2: "Ciclo de investimento",
atth1: 'Data do investimento programado',
atth2: 'Quantidade de investimento programado',
atth3: 'Taxa de negociação',
success: 'Sucesso',
//Gestão da inovação
cxth1: 'Nome',
cxth2: 'Tipo',
cxth3: 'Quantidade subscrita',
cxth4: 'Moeda participante',
cxth5: 'Unidade de subscrição',
cxth6: 'Status atual',
cxth7: 'Volume negociado',
cxth8: 'Data de criação',
cxnav1: 'Minhas participaçãos',
cxnav2: 'Minhas máquinas de mineração',
cxnav3: 'Minhas bloqueios',
cxtit2: 'Lista de minhas máquinas de mineração',
cxtit3: 'Lista de minhas bloqueios',
cxkth1: 'Moeda produzida',
cxkth2: 'Ciclo de produção',
cxkth3: 'Tempo de mineração',
cxkth4: 'Tempo já minerado',
cxkth5: 'Capacidade básica',
cxkth6: 'Capacidade real',
cxstate1: 'Não implantado',
cxstate2: 'Implantado',
cxstate3: 'Revogado',
cxstate4: 'Aguardando negociação',
cxstate5: 'Finalizado',
cxstate6: 'Aguardando lançamento',
cxstate7: 'Em lançamento',
cxcth1: 'Moeda lançada',
cxcth2: 'Total bloqueado',
cxcth3: 'Total ciclo de lançamento',
cxcth4: 'Ciclo já lançado',
cxcth5: 'Liberação básica',
cxcth6: 'Liberação real',
more: 'Mais',
// Sobre nós
abtit: 'Características da plataforma',
abtit2: 'Sobre nós',
abtit3: 'Contate-nos',
abtit4: 'Comunidade Bitisan',
abdesc: 'Bitisan é uma plataforma líder global de negociação de activos digitais, registrada nas Ilhas Cayman, com a sua equipa operativa centralizada em Hong Kong. Os membros da equipa core do Bitisan têm origem em empresas de topo da indústria da internet e financeira, sendo que a maioria deles são profundos crentes no Bitcoin e blockchain. Acreditamos que a blockchain irá mudar o sistema financeiro tradicional monopolizado, estabelecendo uma estrutura social mais democrática e autónoma.',
abdesc2: 'A plataforma Bitisan possui uma estrutura de negociação profissional de nível financeiro, um motor de negociação proprietário de alta capacidade de processamento em memória, além de um sistema completo de carteira de recarga offline, múltiplas assinaturas e um sistema de alta proteção contra ataques DDOS, garantindo a segurança dos ativos dos clientes.',
abdesc3: 'Para melhor aproveitamento das oportunidades de investimento dos clientes, o departamento de atendimento do Bitisan adota uma gestão horizontal, estabelecendo um mecanismo de resposta rápida aos serviços. Os gerentes de atendimento aos ativos estão online 24 horas por dia, 7 dias por semana, oferecendo serviços de recarga e saque de ativos aos clientes, garantindo que os ativos sejam recarregados ou saqueados em até 5 minutos.',
abdesc4: 'Bitisan seleciona rigorosamente projetos de alta qualidade e oferece serviços de custódia de ativos seguros e estáveis. Com o espírito de "honestidade, justiça, entusiasmo e abertura", Bitisan se esforza para criar uma plataforma de negociação segura, confiável, eficiente e amigável para os usuários.',
abdesc5: 'Embora os ativos digitais ainda estão confinados a um pequeno círculo, acreditamos que estes ativos, que podem ser totalmente controlados por cada um, certamente vão se tornar populares no futuro. Trabalhamos juntos e aguardamos ansiosamente!',
abdesc6: 'Atendimento ao Cliente',
abdesc7: 'Suporte Técnico',
abdesc8: 'Solicitação de Listagem',
abdesc9: 'Sugestões e Reclamações',
abdesc10: 'Parcerias Comercial',
abdesc11: 'WeChat',
abdesc12: 'Weibo',
abdesc13: 'Twitter',
abdesc14: 'CoinUse',
abdesc15: 'Telegram',
abdesc16: 'Adicione “bmate601” como amigo no WeChat e entre na comunidade de benefícios do WeChat',
// Autenticação de nomes reais
realtxt: 'Nome verdadeiro',
realtxt2: 'Número do documento de identificação',
realtxt3: 'Foto da frente do documento de identificação',
realtxt4: 'Foto da verso do documento de identificação',
realtxt5: 'Foto com o documento de identificação na mão e assinatura personalizada',
realtxt6: '1. Formatos suportados: JPG, PNG, arquivo menor que 8MB; As informações do documento devem ser claramente visíveis, sem qualquer modificação ou ocultação, permitindo que o número do documento e o nome sejam legíveis.',
realtxt7: '2. Foto sem gorro, sugerimos não usar maquiagem, os traços faciais devem ser claramente visíveis, mostrando todo o braço.',
realtxt8: '3. Necessário ter a foto com o documento de identificação na mão e um texto escrito à mão (nome + data de solicitação) "Todas as ações que eu realizar nesta plataforma são minhas, estou ciente dos riscos relacionados e estou disposto a assumir todas as consequências legalmente vinculativas da minha conta".',
realbtn: 'Clique para fazer upload',
realstate: 'Não autenticado',
realstate2: 'Autenticado',
uploadimg: 'Favor fazer upload da foto da frente do documento de identificação',
uploadimg2: 'Favor fazer upload da foto da verso do documento de identificação',
uploadimg3: 'Favor fazer upload da foto com o documento de identificação na mão',
uploaderr: 'O tamanho da imagem para upload não pode exceder',
save_success: 'Salvo com sucesso!',
save_failure: 'Falha ao salvar!',
// Transferência
close: 'Fechar',
hztxt: 'Confirmar transferência',
// Autenticação do comerciante
submittip1: 'Para segurança de seus fundos, por favor, primeiro realize a 【Autenticação Real】!',
submittip2: 'Para segurança de seus fundos, por favor, primeiro vincule um 【Celular】!',
submittip3: 'Para segurança de seus fundos, por favor, primeiro configure a 【Senha dos Fundos】!',
submittip4: 'Por favor, vincule pelo menos um método de pagamento',
aderr1: 'Favor inserir um número válido',
aderr2: 'O valor adicional deve ser de 0-20',
aderr3: 'O valor adicional deve ser de 0-20, e não pode ser 0',
aderr4: 'Favor inserir um preço fixo válido',
aderr5: 'Favor inserir um número válido, e a quantidade máxima de negociação não deve exceder 100 moedas',
orderTypeNo: "Não",
orderTypeYes: "Sim",
win: "Sucesso",
lose: "Falha",
tied: "Empate",
cancelsuccess: "Cancelamento bem sucedido!",
shelves: 'Após retirar o anúncio, você poderá excluí-lo!',
shelves2: 'Após retirar o anúncio, você poderá editá-lo!',
business: 'Por favor, primeiro solicite a certificação de comerciante!',
swappleaseselectwallet: "Favor selecionar a conta de entrada/saída",
swappleaseinputamount: "Favor inserir a quantidade de transferência",
clickchange: "Clique para mudar a direção da transferência",
daterange: "Favor selecionar o intervalo de pesquisa de datas",
// API
apierr: 'Favor inserir informações de comentário',
apierr2: 'Favor inserir o endereço IP',
apierr3: 'Favor inserir um endereço IP válido',
apierr4: 'Favor inserir o código de verificação',
clickget: 'Clique para obter',
apisecond: 'segundo',
apitips: "A chave só será exibida na criação, por favor, salve-a imediatamente",
apiaccess: "Chave",
// 自定义
do_favorite: 'Favoritado',
cancel_favorite: 'Cancelar favorito',
fail_favorite: 'Falha',
deptotal: 'Total acumulado',
chart: 'Gráfico',
book: 'Livro de ordens',
trade: 'Últimas negociações',
pt_title: "Cartão de presente de criptomoeda",
pt_desc: "Após troca, será automaticamente associado como amigo inferior. Dê aos amigos, familiares, clientes, parceiros. Ajuda os sócios promotores a melhorarem suas campanhas.",
pt_more: "Mais ferramentas, em breve",
pt_tips: "Se tiver alguma boa ideia, envie um e-mail para promotion@Bitisan.biz, haverá recompensa após aprovação!",
pt_card_amount: "Valor do cartão",
pt_card_deadline: "Período de validade",
pt_card_noend: "Sem termo",
pt_card_btn: "Sócios promotores podem receber gratuitamente 30 cartas (aproximadamente 2000 yuan)",
pt_card_btn_login: "Receber gratuitamente 30 cartas após login",
pt_card_rule: "Detalhes das regras",
pt_card_summary: "O cartão de presente de criptomoeda é uma ferramenta de promoção desenvolvida para permitir aos sócios promotores convidarem melhor os amigos inferiores. Quando um usuário promovido troca o cartão de presente, ele será automaticamente associado como amigo inferior do promotor.",
pt_card_rule1: "I. Cada usuário pode receber gratuitamente 30 cartas. Para emitir mais cartas de presente, o usuário terá que pagar, podendo customizar o valor, LOGO, descrição, etc. Para pedidos de customização, envie um e-mail para",
pt_card_rule2: "II. Cada usuário pode trocar gratuitamente apenas um cartão. Os cartas de presente emitidas oficialmente pela Bitisan podem ser trocadas apenas uma vez por usuário, mesmo que o usuário tenha recebido códigos de troca de cartas de presente de diferentes usuários. As cartas personalizadas não estão sujeitas a essa restrição.",
pt_card_rule3: "III. Antes de trocar o cartão de presente, se o usuário não possui um “convidador” (ou seja, não tiver inserido um código de convite durante o cadastro), ele será automaticamente associado como amigo principal do emitente. Por exemplo, se A não tiver inserido um código de convite durante o cadastro e não pertencer a ninguém como amigo principal ou secundário, quando A troca um cartão emitido por B, A se tornará automaticamente o amigo principal de B.",
pt_card_rule4: "IV. Para evitar abusos, o valor do cartão de presente não será pago em tempo real. Após recebê-lo, será necessário realizar a autenticação para poder operar.",
pt_card_rule5: "V. Para receber um cartão de presente gratuito, será necessário realizar a autenticação. Não será necessário realizar a autenticação para trocar o cartão, visando facilitar aos promotores obterem mais amigos inferiores.",
pt_card_rule6: "VI. Todos os direitos sobre as cartas de presente gratuitas pertencem à Bitisan.",
pt_card_day: "dias",
pt_card_title_tips: "Receber 2000 yuan de apoio à campanha de promoção",
pt_invite_desc: "Código QR exclusivo. Ao registrar através do código QR, o usuário será automaticamente associado como amigo inferior. Adequado para compartilhar em grupos ou fóruns.",
saveimage: "Salvar imagem",
imagetips: "Se não conseguir baixar a imagem normalmente, use uma ferramenta de captura de tela para salvá-la!",
pt_card_receivew_success: "Parabéns! Cartas de presente para sócios promotores recebidas com sucesso! Vá para 【Centro Personal】->【Centro de Cartas】 para verificar!",
usepromotion: "Usar material promocional",
context_title: "Código exclusivo de troca para sócios promotores",
context_title1: "Saiba mais através do QR Code",
context_title2: "Meu código de convite",
context_title3: "Imagem de convite",
pt_card_title: "Cartas promocionais para sócios",
copysuccess: 'Copiado com sucesso!',
copyerr: 'Falha na cópia! Copie manualmente',
noopening: "Nenhum contrato de prazo pendente",
createSuccess: "Criação bem suced",
tradewarning1: 'Insira no máximo 2 casas decimais',
 tradewarning2: 'Valor da ordem é',
 tradewarning3: 'Insira no máximo 8 casas decimais',
 tradewarning4: 'Quantidade da ordem é',
 tradeconfirmbuyin: 'Confirmar compra',
 tradeconfirmsellout: 'Confirmar venda',
 foot:{
  aboutus1:'Sobre nós',
  aboutus: '<p>Após o lançamento da rede principal em abril de 2019, a Binance Chain demonstrou seu design de alta velocidade \
  e alta capacidade. Seu principal ponto de atenção - aplicativos descentralizados locais (dApp) Binance Chain DEX (Exchange descentralizado), já foi testado para processar milhões de \
  transações em pouco tempo, mostrando a baixa latência do seu mecanismo de negociação \
  função de correspondência.</p><p>Flexibilidade e usabilidade são muitas vezes intimamente relacionadas com desempenho. Quando o foco é fornecer facilidades para a emissão e negociação de ativos digitais, esse design também apresenta \
  limitações em certo grau. As vozes mais sonoras da comunidade têm pedido para ver \
  a Binance Chain adicionar recursos de expansão programável, ou seja, \
  funcionalidades de contratos inteligentes e máquinas virtual. Devido às limitações atualmente existentes da Binance Chain, os emissores e proprietários de ativos digitais têm dificuldades em adicionar novas \
  funcionalidades descentralizadas aos seus ativos ou introduzir qualquer forma de \
  gerenciamento e atividades comunitárias.\
  Apesar da alta expectativa de adicionar funcionalidades de contrato inteligente à Binance \
  Chain, esta é uma "decisão difícil". A execução de contratos inteligentes pode retardar o DEX e acrescentar incerteza às transações de ativos. Mesmo que essa concessão \
  possa ser tolerada, a solução mais simples poderia ser a implementação de máquinas virtual \
  baseadas na atual consenso de baixo nível Tendermint e na interface RPC principal \
  da Binance Chain. No entanto, essa solução aumentaria o custo de aprendizado da comunidade existente de dApp e não seria uma solução popular.\
  Aqui, apresentamos o conceito de cadeia de blocos paralela da Binance Chain, para manter o alto \
  desempenho do DEX local, ao mesmo tempo que apoia amigavelmente funcionalidades de contrato inteligente.\
  Depois de construir duas cadeias de blocos paralelas no ecossistema da Binance Chain, \
  as cadeias de blocos funcionarão independentemente, oferecendo diferentes serviços. A nova cadeia paralela será chamada de "Binance Smart Chain" (abreviada como "BNBN"), enquanto a rede principal existente ainda será chamada de "Binance Chain" (abreviada como "BNB").\
  O design do BNBN segue os seguintes princípios:\
  Cadeia de blocos independente: Tecnicamente falando, o BNBN é uma cadeia de blocos independente, não uma solução de segunda camada. A maioria das tecnologias e funcionalidades básicas de negócios do BSC devem \
  ser independentes, portanto, mesmo que o BNB parar por um curto período, o BNBN ainda poderá funcionar bem.\
  Compatibilidade com a Ethereum: A primeira plataforma de contrato inteligente prática e amplamente utilizada é \
  a Ethereum. Para conectar aplicações e comunidades relativamente maduras, o BSC escolheu \
  ser compatível com a rede principal existente da Ethereum. Isso significa que a maioria dos dApp, componentes e ferramentas do ecossistema serão compatíveis com o BSC, sem necessidade de modificações ou \
  apenas pequenas alterações; Nós do BNBN só necessitam de hardware semelhante ou ligeiramente superior \
  normas de operação e habilidades operacionais. Essa implementação deve fornecer \
Consenso baseado na garantia de capital próprio e na gestão da cadeia: Consenso baseado em capital próprio\
Compromisso PoS é mais ecológico, fornecendo\
Governação comunitária. Pode-se esperar que este consenso seja melhor\
O desempenho é superior ao consenso PoW, ou seja, o tempo de geração de blocos é curto, e\
Alta capacidade de processamento de transações. Comunicação local entre cadeias: BNB e\
O BNBN apoiará nativamente a comunicação entre duas blockchains. Isto\
O protocolo de comunicação deve ser dois-\
Métodos, dispersão e desconfiança de terceiros. Concentrar-se-á na transferência.\
Os ativos digitais entre BNB e BNBN, nomeadamente tokens BEP2, bem como outros tokens BNBN\
Vamos apresentá-lo mais tarde. O protocolo não deve se concentrar excessivamente em outras informações armazenadas no blockchain, com apenas algumas exceções</ P>\
<p>Baseado nos princípios de projeto acima, o protocolo de consenso da BNBN é implementar\
Os seguintes objectivos:\
O tempo de bloqueio deve ser menor do que o tempo Ethereum, como 5 segundos ou até mais curto\
O tempo de espera para que a transação seja concluída é limitado, como cerca de 1 minuto ou menos\
Sem inflação, renda.\
A parte do blockchain vem de taxas de transação, que são pagas na forma de BNBN\
Tente ser compatível com Ethereum tanto quanto possível\
Ter um mecanismo de governança em cadeia baseado na garantia de capital próprio\
Certificado de autorização de capital próprio\
Embora a prova de trabalho PoW tenha sido comprovada para ser implementada\
Rede descentralizada, que não é amiga do ambiente e requer uma grande quantidade de recursos\
Número de participantes na manutenção da segurança da rede\
Ethereum e outras redes como MATIC Bor, TOMOChain, GoChain e xDAI\
Usando a prova de autorização PoA ou suas variantes em diferentes cenários, incluindo\
Rede de teste e rede principal. PoA pode se defender contra 51% dos ataques, tornando-o mais eficaz\
Impedir que alguns nós bizantinos cometam o mal. Escolha PoA como base\
Alcançar consenso é uma das escolhas ideais\
Inspirada pelo exposto, a BNBN combina DPoS e PoA para chegar a um consenso. Solução\
Adoptar:\
Os blocos são gerados por um número limitado de validadores, que se revezam gerando validadores\
Bloqueado de uma maneira PoA, semelhante ao mecanismo de consenso Clique da Ethereum\
Um conjunto de validadores foi seleccionado, e\
Eliminar a governança da cadeia com base na garantia de capital próprio\
Na fase de bloqueio de origem da inicialização da rede, muitos nós confiáveis irão\
Operar como o conjunto inicial de validadores. Após o início da produção de blocos, qualquer um\
Pode participar como candidato na eleição de validadores. O status de penhor de capital determina que os 21 principais nós com mais capital se tornarão o próximo grupo\
Verificador. Este tipo de eleição e eliminação é realizada a cada 24 horas\
BNBN é um símbolo de penhor de capital próprio do BNB\
A fim de manter o consenso com a Ethereum, o acordo inclui\
Na próxima atualização, a BNBN escolheu contar com o BNB para a gestão de ações. Existe um módulo projetado especificamente para o patrimônio da BNBN\
Aposta no BNB. Aceitará o penhor de capital próprio do BNBN dos detentores do BNBN e calculará\
O conjunto de nós com maior interesse. Sempre que UTC atingir zero, o BNB enviará\
Mensagem de cadeia cruzada "ValidatorSetUpdate" verificável, notificando BNBN para atualizá-lo\
Verificador pronto. Antes de gerar um novo bloco, o validador BNBN existente verifica periodicamente a presença de "\
Mensagem "ValidaterSetUpdate" encaminhada para BNB\
Se assim for, eles atualizarão o conjunto de validadores após uma certa altura, que é um intervalo de bloco predefinido\
Por exemplo, se BNB gera um bloco a cada 5 segundos com um ciclo de verificação de 240 blocos, então a corrente\
O conjunto de validadores verificará e atualizará o conjunto de validadores do próximo ciclo dentro de 20 minutos dentro de 1200 segundos\
Segurança e Resistência\
Considerando mais de metade dos ½* O validador N+1 é honesto e confiável, e as redes baseadas em PoA normalmente funcionam de forma segura e correta\
No entanto, em alguns casos, validadores bizantinos ainda podem atacar redes, como por exemplo através de "ataques de clonagem". Para garantir que o BNBN tenha a mesma alta\
Como medida de segurança para o BNB, incentivamos os usuários do BNBN a esperar até receber o bloqueio\
Múltiplas confirmações de validadores 2/3 * N+1 diferentes. Através desta abordagem, a BNBN pode alcançar\
Semelhante ao BNB, ele pode tolerar Byzâncio com um nível de segurança inferior a 1/3 * N\
Verificador. Para 21 validadores, se o tempo de bloqueio for de 5 segundos, então 2/3 * N+1\
Os validadores diferentes exigem 2/3 * 21+1 * 5=75 segundos para confirmar. Qualquer aplicação significativa de BNBN pode exigir a espera de 2/3 * N+1 para garantir um resultado final relativamente seguro\
Vamos apresentá-lo mais tarde na seção "Compromisso e Gestão de Capital". Esta punição\
Este mecanismo irá expor validadores maliciosos em um período muito curto de tempo\
E fazer "ataques de clonagem" muito difíceis de executar ou muito pouco econômicos, mesmo que\
Execução. Através deste mecanismo de punição, ½* N+1 ou menos blocos são suficientes para satisfazer\
A finalidade da maioria das transacções\
Todos os validadores BNB no atual conjunto de validadores receberão receita das taxas pagas\
Como o BNB não é um token inflacionário, ele não gerará receita de mineração\
Por exemplo, Bitcoin e a rede Ethereum. As taxas de tratamento são a principal fonte de rendimento\
Verificador. Como o BNB também é um token prático para outras aplicações\
O validador ainda se beneficiará de manter BNBN</p>',
newbie:'',  
newbie1:'Guia para Iniciantes',  
commonproblem:'',  
  
commonproblem1:'Problemas Comuns',  
  
information:'',  
  
information1:'Informações sobre Moedas',  
  
disclaimer:'',  
  
disclaimer1:'Cláusula de Isenção de Responsabilidade',  
  
privacyclause:'',  
  
privacyclause1:'Cláusula de Privacidade',  
  
agreement:'',  
  
agreement1:'Acordo do Usuário',  
  
},  
  
tradeinfo: {  
  
emaildone: 'Email já confirmado',  
  
emailundo: 'Email não confirmado',  
  
teldone: 'Celular já confirmado',  
  
telundo: 'Celular não confirmado',  
  
idcarddone: 'Documento de Identificação já confirmado',  
  
idcardundo: 'Documento de Identificação não confirmado',  
  
exchangetimes: 'Número de Transações',  
  
price: 'Preço',  
  
num: 'Quantidade',  
  
paymethod: 'Método de Pagamento',  
  
exchangelimitamount: 'Limite de Transação',  
  
location: 'Localização',  
  
location_text: 'China',  
  
exchangeperiod: 'Prazo de Transação',  
  
minute: 'Minuto',  
  
amounttip: 'Insira o Valor',  
  
numtip: 'Insira a Quantidade',  
  
remarktip: 'Informar seus requisitos',  
  
remarktitle: 'Informações Adicionais',  
  
exchangetitle: 'Informações sobre Transação',  
  
exchange_tip1: 'Após você iniciar o pedido de transação, a moeda digital será bloqueada em custódia e protegida pela plataforma. Se você é o vendedor, após iniciar o pedido de transação, você poderá efetuar um recarga e aguardar o pagamento do comprador. O comprador deverá efetuar o pagamento dentro do prazo estabelecido. Após você receber o pagamento, deverá liberar a moeda digital em custódia.',  
  
exchange_tip2: 'Antes de negociar, por favor, leia os Termos de Serviço da Plataforma, bem como os documentos de ajuda Problemas Comuns e Guia de Negociação.',  
  
exchange_tip3: 'Cuidado com golpistas! Antes de negociar, por favor, verifique as avaliações recebidas pelo usuário e presta atenção aos contas recém-criadas.',  
  
exchange_tip4: 'Tenha atenção que a arredondagem e as oscilações de preço podem afetar o valor final da moeda digital negociada. O valor fixo inserido por você determinará o valor final, sendo que o valor da moeda digital será calculado pela taxa de câmbio instantânea no mesmo momento da publicação do pedido.',  
  
exchange_tip5: 'O serviço de custódia protege os compradores e vendedores nas negociações online. Em caso de controvérsia, nós avaliaremos todas as informações fornecidas e liberaremos a moeda digital em custódia ao seu legítimo proprietário.',  
  
warning1: 'Insira no máximo 2 casas decimais',  
  
warning2: 'Valor do pedido é',  
  
warning3: 'Insira no máximo 8 casas decimais',  
  
warning4: 'Quantidade do pedido é',  
  
confirmbuyin: 'Confirmar Compra',  
  
confirmsellout: 'Confirmar Venda',  
  
buyin: 'Compra',  
  
sellout: 'Venda',  
  
warning5: 'Preencha o pedido conforme as exigências'  ,
  
}

}