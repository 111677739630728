<template>
  <div class="footer">
    <div class="container flex">
      <div class="foot-left">
        <div class="foot-logo">
         <img src="../assets/img/baloog.png" alt="" />
          <b style="font-weight: 500 !important;">BNBNCOIN</b>
        </div>
        <div class="copyright">
          {{ $t('foottext') }}<br />
          Bnbncoin ©2024
        </div>
        <ul class="foot-share clearfix">
          <li>
            <a href="https://t.me/BNBNCOIN" class="share-link" target="_blank">
              <el-tooltip class="item" effect="dark" content="https://t.me/BNBNCOIN" placement="top">
                <div class="share-link">
                  <span class="iconfont icon-fasongx"></span>
                </div>
              </el-tooltip>
            </a>
          </li>
          <li>
            <a href="https://twitter.com/BNBNCOINbrown" class="share-link" target="_blank">
              <el-tooltip class="item" effect="dark" content="https://twitter.com/BNBNCOINbrown" placement="top">
                <div class="share-link">
                  <span class="iconfont icon-tuite"></span>
                </div>
              </el-tooltip>
            </a>
          </li>
        </ul>
      </div>
      <div class="foot-nav flex clearfix">
        <ul class="foot-list">
          <li>
            <span class="foot-hd">{{ $t('footmeun1') }}</span>
          </li>
          <li>
            <router-link to="/" ><span @click="openpdf1">{{ $t('footmeun1_1') }}</span></router-link>
          </li>
          <li>
            <router-link to="/announcement">{{ $t('footmeun1_3') }}</router-link>
          </li>
          <li>
            <router-link  to="/whitepaper">{{ $t('footmeun1_4') }}</router-link>
          </li>
        </ul>
        <ul class="foot-list">
          <li>
            <span class="foot-hd">{{ $t('footmeun2') }}</span>
          </li>
          <li>
            <router-link  to="/"><span @click="openpdf2">
              {{ $t('footmeun2_1') }}</span>  
            </router-link>
          </li>
          <li>
            <!-- <router-link to="/"><span @click="openpdf(3)">
              {{ $t('footmeun2_2') }}</span>
            </router-link> -->
             <el-tooltip effect="dark" :content="$t('problem')" placement="top">
              <div class="foot-link">{{ $t('footmeun2_2') }}</div>
            </el-tooltip>
          </li>
          <li>
            <router-link to="/"><span @click="openpdf4">
              {{ $t('footmeun2_4') }}</span>
            </router-link>
          </li>
        </ul>
        <ul class="foot-list">
          <li>
            <span class="foot-hd">{{ $t('footmeun3') }}</span>
          </li>
          <li>
            <router-link  to="/"><span @click="openpdf5">
              {{ $t('footmeun3_1') }}</span>
            </router-link>
          </li>
          <li>
            <router-link  to="/"><span @click="openpdf6">
              {{ $t('footmeun3_2') }}</span>
            </router-link>
          </li>
          <li>
            <router-link  to="/"><span @click="openpdf7">
              {{ $t('footmeun3_3') }}</span>
            </router-link>
          </li>
        </ul>
        <ul class="foot-list">
          <li>
            <span class="foot-hd">{{ $t('footmeun4') }}</span>
          </li>
          <li>
            <el-tooltip class="item" effect="dark" content="bnbncoinvip@gmail.com" placement="right">
              <div class="foot-link">{{ $t('footmeun4_1') }}</div>
            </el-tooltip>
          </li>
          <li>
            <el-tooltip class="item" effect="dark" content="bnbncoinvip@gmail.com" placement="right">
              <div class="foot-link">{{ $t('footmeun4_2') }}</div>
            </el-tooltip>
          </li>
          <li>
            <el-tooltip class="item" effect="dark" content="bnbncoinvip@gmail.com" placement="right">
              <div class="foot-link">{{ $t('footmeun4_4') }}</div>
            </el-tooltip>
          </li>
        </ul>
      </div>
      <vueshowpdf @closepdf="closepdf"
                v-model="isshowpdf"
                :pdfurl="src"
                @pdferr="pdferr"
                :maxscale='4'
                :minscale='0.6'
                :scale='1.0'></vueshowpdf>
    </div>
  </div>
  
</template>

<script>
import vueshowpdf from 'vueshowpdf'
export default {
    name: 'Foot',
  components: {
    vueshowpdf
  },
  data () {
    return {
      src: '',
      isshowpdf: false
    }
  },
  methods: {
    closepdf () {
      this.isshowpdf = false
    },
    pdferr (errurl) {
      console.log(errurl)
    },
    openpdf1 () {
      const lang = localStorage.getItem('lang')
      console.log(lang);
        if(lang=="zh_CN")
          {
            this.src='/static/about_zh.pdf';
          }
        else if(lang=="en_US")
        {
          this.src='/static/about_en.pdf';
        }
        else if(lang=="zh_HK")
        {
          this.src='/static/about_hk.pdf';
        }
        else if(lang=="ja_JP")
        {
          this.src='/static/about_ja.pdf';
        }
        else if(lang=="ko_KR")
        {
          this.src='/static/about_ko.pdf';
        }
        else if(lang=="de_DE")
        {
          this.src='/static/about_de.pdf';
        }
        else if(lang=="fr_FR")
        {
          this.src='/static/about_fr.pdf';
        }
        else if(lang=="it_IT")
        {
          this.src='/static/about_it.pdf';
        }
        else if(lang=="es_ES")
        {
          this.src='/static/about_es.pdf';
        }
         else if(lang=="ar_AR")
        {
          this.src='/static/about_ar.pdf';
        }
         else if(lang=="pt_PT")
        {
          this.src='/static/about_pt.pdf';
        }
      this.isshowpdf = true
    },
     openpdf2 () {
      const lang = localStorage.getItem('lang')
      console.log(lang);
      if(lang=="zh_CN")
          {
            this.src='/static/guide_zh.pdf';
          }
        else if(lang=="en_US")
        {
          this.src='/static/guide_en.pdf';
        }
        else if(lang=="zh_HK")
        {
          this.src='/static/guide_hk.pdf';
        }
        else if(lang=="ja_JP")
        {
          this.src='/static/guide_ja.pdf';
        }
        else if(lang=="ko_KR")
        {
          this.src='/static/guide_ko.pdf';
        }
        else if(lang=="de_DE")
        {
          this.src='/static/guide_de.pdf';
        }
        else if(lang=="fr_FR")
        {
          this.src='/static/guide_fr.pdf';
        }
        else if(lang=="it_IT")
        {
          this.src='/static/guide_it.pdf';
        }
        else if(lang=="es_ES")
        {
          this.src='/static/guide_es.pdf';
        }
         else if(lang=="ar_AR")
        {
          this.src='/static/guide_ar.pdf';
        }
         else if(lang=="pt_PT")
        {
          this.src='/static/guide_pt.pdf';
        }
      this.isshowpdf = true
    }
    ,
     openpdf3 () {
      const lang = localStorage.getItem('lang')
      console.log(lang);
      if(lang=="zh_CN")
          {
            this.src='/static/problem_zh.pdf';
          }
        else if(lang=="en_US")
        {
          this.src='/static/problem_en.pdf';
        }
        else if(lang=="zh_HK")
        {
          this.src='/static/problem_hk.pdf';
        }
        else if(lang=="ja_JP")
        {
          this.src='/static/problem_ja.pdf';
        }
        else if(lang=="ko_KR")
        {
          this.src='/static/problem_ko.pdf';
        }
        else if(lang=="de_DE")
        {
          this.src='/static/problem_de.pdf';
        }
        else if(lang=="fr_FR")
        {
          this.src='/static/problem_fr.pdf';
        }
        else if(lang=="it_IT")
        {
          this.src='/static/problem_it.pdf';
        }
        else if(lang=="es_ES")
        {
          this.src='/static/problem_es.pdf';
        }
         else if(lang=="ar_AR")
        {
          this.src='/static/problem_ar.pdf';
        }
         else if(lang=="pt_PT")
        {
          this.src='/static/problem_pt.pdf';
        }
      this.isshowpdf = true
    }
    ,
     openpdf4 () {
      const lang = localStorage.getItem('lang')
      console.log(lang);
        if(lang=="zh_CN")
          {
            this.src='/static/currency_zh.pdf';
          }
        else if(lang=="en_US")
        {
          this.src='/static/currency_en.pdf';
        }
        else if(lang=="zh_HK")
        {
          this.src='/static/currency_hk.pdf';
        }
        else if(lang=="ja_JP")
        {
          this.src='/static/currency_ja.pdf';
        }
        else if(lang=="ko_KR")
        {
          this.src='/static/currency_ko.pdf';
        }
        else if(lang=="de_DE")
        {
          this.src='/static/currency_de.pdf';
        }
        else if(lang=="fr_FR")
        {
          this.src='/static/currency_fr.pdf';
        }
        else if(lang=="it_IT")
        {
          this.src='/static/currency_it.pdf';
        }
        else if(lang=="es_ES")
        {
          this.src='/static/currency_es.pdf';
        }
         else if(lang=="ar_AR")
        {
          this.src='/static/currency_ar.pdf';
        }
         else if(lang=="pt_PT")
        {
          this.src='/static/currency_pt.pdf';
        }
      this.isshowpdf = true
    },
     openpdf5 () {
      const lang = localStorage.getItem('lang')
      console.log(lang);
        if(lang=="zh_CN")
          {
            this.src='/static/disclaimer_zh.pdf';
          }
        else if(lang=="en_US")
        {
          this.src='/static/disclaimer_en.pdf';
        }
        else if(lang=="zh_HK")
        {
          this.src='/static/disclaimer_hk.pdf';
        }
        else if(lang=="ja_JP")
        {
          this.src='/static/disclaimer_ja.pdf';
        }
        else if(lang=="ko_KR")
        {
          this.src='/static/disclaimer_ko.pdf';
        }
        else if(lang=="de_DE")
        {
          this.src='/static/disclaimer_de.pdf';
        }
        else if(lang=="fr_FR")
        {
          this.src='/static/disclaimer_fr.pdf';
        }
        else if(lang=="it_IT")
        {
          this.src='/static/disclaimer_it.pdf';
        }
        else if(lang=="es_ES")
        {
          this.src='/static/disclaimer_es.pdf';
        }
         else if(lang=="ar_AR")
        {
          this.src='/static/disclaimer_ar.pdf';
        }
         else if(lang=="pt_PT")
        {
          this.src='/static/disclaimer_pt.pdf';
        }
      this.isshowpdf = true
    },
     openpdf6 () {
      const lang = localStorage.getItem('lang')
      console.log(lang);
        if(lang=="zh_CN")
          {
            this.src='/static/privacy_zh.pdf';
          }
        else if(lang=="en_US")
        {
          this.src='/static/privacy_en.pdf';
        }
        else if(lang=="zh_HK")
        {
          this.src='/static/privacy_hk.pdf';
        }
        else if(lang=="ja_JP")
        {
          this.src='/static/privacy_ja.pdf';
        }
        else if(lang=="ko_KR")
        {
          this.src='/static/privacy_ko.pdf';
        }
        else if(lang=="de_DE")
        {
          this.src='/static/privacy_de.pdf';
        }
        else if(lang=="fr_FR")
        {
          this.src='/static/privacy_fr.pdf';
        }
        else if(lang=="it_IT")
        {
          this.src='/static/privacy_it.pdf';
        }
        else if(lang=="es_ES")
        {
          this.src='/static/privacy_es.pdf';
        }
         else if(lang=="ar_AR")
        {
          this.src='/static/privacy_ar.pdf';
        }
         else if(lang=="pt_PT")
        {
          this.src='/static/privacy_pt.pdf';
        }
      this.isshowpdf = true
    },
     openpdf7 () {
      const lang = localStorage.getItem('lang')
      console.log(lang);
        if(lang=="zh_CN")
          {
            this.src='/static/user_zh.pdf';
          }
        else if(lang=="en_US")
        {
          this.src='/static/user_en.pdf';
        }
        else if(lang=="zh_HK")
        {
          this.src='/static/user_hk.pdf';
        }
        else if(lang=="ja_JP")
        {
          this.src='/static/user_ja.pdf';
        }
        else if(lang=="ko_KR")
        {
          this.src='/static/user_ko.pdf';
        }
        else if(lang=="de_DE")
        {
          this.src='/static/user_de.pdf';
        }
        else if(lang=="fr_FR")
        {
          this.src='/static/user_fr.pdf';
        }
        else if(lang=="it_IT")
        {
          this.src='/static/user_it.pdf';
        }
        else if(lang=="es_ES")
        {
          this.src='/static/user_es.pdf';
        }
         else if(lang=="ar_AR")
        {
          this.src='/static/user_ar.pdf';
        }
         else if(lang=="pt_PT")
        {
          this.src='/static/user_pt.pdf';
        }
      this.isshowpdf = true
    }
  }
}
</script>
