import request from '../request'
//获取验证码
export function getcaptcha(email) {
    return request({
      url: '/virtual/register/captcha?email='+email,
      method: 'get',
      headers: {
        'Content-Type': 'application/json'
      },
    })
  }
  //获取验证码
export function resetpassword(email) {
  return request({
    url: '/virtual/register/resetpassword?email='+email,
    method: 'get',
    headers: {
      'Content-Type': 'application/json'
    },
  })
}
  // 注册
  export function addUser(data) {
    return request({
      url: '/virtual/register/add',
      method: 'post',
      data: data
    })
  }
  
  // 登陆
  export function login(data) {
    return request({
      url: '/virtual/register/login',
      method: 'post',
      data: data
    })
  }
  //判断是否登陆
  export function checkLogin() {
    return request({
      url: '/virtual/register/verifylogin',
      method: 'POST',
    })
  }//退出登陆
  export function loginout() {
    return request({
      url: '/virtual/register/loginout',
      method: 'POST',
    })
  }
  //修改密码
  export function updatapassword(data) {
    return request({
      url: '/virtual/register/updatapassword',
      method: 'POST',
      data: data
    })
  }
  