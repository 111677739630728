import axios from 'axios'
import {
	Message
} from 'element-ui'
const baseURL = `${process.env.VUE_APP_API_URL}/`
const service = axios.create({
	baseURL,
	timeout: 100000,
	headers: {
		//'Content-Type': 'application/x-www-form-urlencoded',
		'Content-Type': 'application/json',
		'lang': 'en_US'
	}
})

service.interceptors.request.use(config => {
	let token = localStorage.getItem('token')
	let lang = localStorage.getItem('lang')
	if (token) {
		config.headers['x-auth-token'] = token 
	}
	if (lang) {
		config.headers['lang'] = lang
	}
	return config
}, error => {
	console.error('Error in request interceptor:', error);
    return Promise.reject(error);
})

service.interceptors.response.use(
	response => {
		const res = response.data
		if (res.code == 401) {
            Message({
                message: res.msg,
                type: 'error',
                duration: 3000
            })
			localStorage.removeItem('token')
        	localStorage.removeItem('invite')
        	localStorage.removeItem('userId')
        	localStorage.removeItem('userName')
          	this.$store.commit('SET_ISLOGIN', false);
			this.$store.commit('SET_MEMBER', null)
			window.location.href = "#/login";
		}
        if(res.code==405)
        { 
			Message({
                message: res.msg,
                type: 'error',
                duration: 3000
            })
            window.location.href = "#/login";
        }
		if(res.code==501)
        {
			Message({
                message: res.msg,
                type: 'error',
                duration: 3000
            })
            // window.location.href = "#/login";
        }
		return res;
	},
	error => {
		if(localStorage.getItem('lang')=='ar_AR'){
			Message({
				message: "خطأ في النظام",
				type: 'error',
				duration: 3000
			})
		  }   
		  else{
			Message({
				message: "System error",
				type: 'error',
				duration: 3000
			})
		  }
		return Promise.reject(error)
	})

export default service
