<template>
  <div id="app">
    <router-view v-if="isRouterAlive" />
  </div>
</template>
<script>
import { checkLogin } from '@/api/virtual/register'
export default {
  name: 'App',
  provide() {
    return {
      reload: this.reload,
    }
  },
  components: {},
  data() {
    return {
      isRouterAlive: true,
    }
  },
  created() {
    // let theme = localStorage.getItem('theme')
    // if (theme == 'black') {
    //   window.document.documentElement.setAttribute('data-theme', theme)
    // } else {
    //   window.document.documentElement.setAttribute('data-theme', 'white')
    // }
    window.document.documentElement.setAttribute('data-theme', 'black')
    this.getLogin()
  },
  methods: {
    reload() {
      this.isRouterAlive = false
      this.$nextTick(function() {
        this.isRouterAlive = true
      })
    },
    getLogin() {
      //console.log("获取登陆信息");
      const token=  localStorage.getItem('token');
      if(token==null)
      {
        localStorage.removeItem('invite')
        localStorage.removeItem('userId')
        localStorage.removeItem('userName')
        this.$store.commit('SET_ISLOGIN', false)
        this.$store.commit('SET_MEMBER', null)
      }
      else{
        checkLogin().then(res => {
        if (res.code == 200) {
          this.$store.commit('SET_ISLOGIN', true);
        }
        else if(res.code == 401){
          localStorage.removeItem('token')
          localStorage.removeItem('invite')
          localStorage.removeItem('userId')
          localStorage.removeItem('userName')
          this.$store.commit('SET_ISLOGIN', false);
          this.$store.commit('SET_MEMBER', null);
          this.$message.error(res.msg);
          this.$router.push('/login');
        }
      })

      }
    },
  },
}
</script>
<style>
/* PC 端样式 */
#app {
  max-width: 100%;
  margin: 0 auto;
}
/* 手机端样式 */
@media (max-width: 767px) {
  #app {
    max-width: 100%;
    padding: 0 10px;
  }
}
</style>

