<template>
<div class="page-web page-home">
		<Head />
<div style="width:95%; margin: 5px auto; height: 750px; " >
    <el-tabs v-model="activeName"  tab-position="top" style="height: 650px;">
    <el-tab-pane :label="$t('Subscriptionrecords')" name="first" >
        <div class="my_table">
<el-table 
    :empty-text="$t('order12')"
    :data="virtualSubscribes"
    style="width: 100% "
    height="700">
    <el-table-column :label="$t('transaction0')" width="60" align="left">
<template slot-scope="scope">
{{ (scope.$index+1) }}
</template></el-table-column>
   <!-- <el-table-column
      prop="id"
      :label="$t('order3')"
      width="300">
    </el-table-column> -->
    <el-table-column
      prop="number"
      :label="$t('order4')"
      width="120">
    </el-table-column>
    <el-table-column
      prop="time"
      :label="$t('order5')">
    </el-table-column>
  </el-table>
</div>
    </el-tab-pane>
    <el-tab-pane :label="$t('Rechargerecords')" name="second">
<div class="my_table">
<el-table 
    :empty-text="$t('recordsavailable')"
    :data="virtualOrders"
    style="width: 100% "
    height="700">
    <el-table-column :label="$t('transaction0')" width="50" align="left">
<template slot-scope="scope">
{{ (scope.$index+1) }}
</template></el-table-column>
    <!-- <el-table-column
      prop="tradeId"
      :label="$t('Rechargenumber')"
      width="300">
    </el-table-column> 
    <el-table-column
      prop="token"
      :label="$t('Rechargeaddress')">
    </el-table-column>-->
    <el-table-column
      prop="amount"
       :label="$t('Rechargeamount')"
      width="120">
    </el-table-column>
    
    <el-table-column
      prop="receiptTime"
      :label="$t('Rechargetime')">
    </el-table-column>
  </el-table>
</div>
    </el-tab-pane>
    <el-tab-pane :label="$t('Withdrawalrecords')" name="third">
        <div class="my_table">
<el-table 
    :empty-text="$t('withdrawalrecord')"
    :data="virtualEmbodies"
    style="width: 100% "
    height="700">
    <el-table-column :label="$t('transaction0')" width="50" align="left">
<template slot-scope="scope">
{{ (scope.$index+1) }}
</template></el-table-column>
    <!-- <el-table-column
      prop="id"
      :label="$t('Withdrawalnumber')"
      width="300">
    </el-table-column> -->
    <el-table-column
      prop="usdtNumber"
      :label="$t('Withdrawalquantity')"
      width="80">
    </el-table-column>
    <el-table-column
      prop="applicationTime"
     :label="$t('Applicationtime')">
    </el-table-column>
    <el-table-column
      prop="accountingTime"
      :label="$t('Timeofreceipt')">
    </el-table-column>
    <el-table-column
      prop="state"
      :label="$t('Withdrawalstatus')">
      <template slot-scope="scope">
          <p v-if="scope.row.state===0">{{$t('witstatus_1')}}</p>
          <p v-if="scope.row.state===1">{{$t('witstatus_4')}}</p>
          <p v-if="scope.row.state===2">{{$t('witstatus_3')}}</p>
        </template>
    </el-table-column>
    <!-- <el-table-column :label="$t('operation')" align="center" class-name="small-padding fixed-width">
        <template slot-scope="scope">
          <el-button
            v-if="scope.state===1"
            size="mini"
            type="text"
            icon="el-icon-more"
            @click="handledetailss(scope.row)"
          >{{$t('cancel')}}</el-button>
        </template>
      </el-table-column> -->
  </el-table>
</div>
    </el-tab-pane>
  </el-tabs>
</div>
<Foot />
</div>
</template>
<script>
import Head from '@/components/Head.vue'
import Foot from '@/components/Foot.vue'
import { wholeUser } from '@/api/virtual/virtual.js'
  export default {
    components: {
			Head,
			Foot
		},
    data() {
      return {
        activeName:'first',
        virtualSubscribes : [],
        virtualOrders : [],
        virtualEmbodies : [],
        }
      }
    ,
    created() {
            this.getList();
            },
	mounted() {
		
    },	
    methods: {
      getList() {
        const id=  localStorage.getItem('userId');
        wholeUser(id).then(response => {
        this.virtualSubscribes = response.data.virtualSubscribes;
        this.virtualOrders = response.data.virtualOrders;
        this.virtualEmbodies = response.data.virtualEmbodies;
        console.log(this.virtualSubscribes);
        console.log(this.virtualOrder);
        console.log(this.virtualEmbodies);
      });
      },
      handledetailss(row){
        console.log(row);
      }
    } 
  }
</script>
<style>
  /* .el-dropdown-link {
    cursor: pointer;
    color: #409EFF; 
  } 
  .el-icon-arrow-down {
    font-size: 12px;
  }
  .demonstration {
    display: block;
    color: #8492a6;
    font-size: 14px;
    margin-bottom: 20px;
  } */
.my_table >>> .el-table__row>td{
  /* 去除表格线 */
  border: none;
}
.my_table >>> .el-table th.is-leaf {
  /* 去除上边框 */
    border: none;
}

.my_table >>> .el-table::before{
  /* 去除下边框 */
  height: 0;
}

.my_table ::v-deep .el-table__body tr:hover > td {
     background-color: rgb(0, 0, 0) !important;
 }
.el-table__body tr:hover>td{
background-color: rgb(0, 0, 0) !important;
}
.el-tabs__item{
font-size:20px !important;
} 
</style>