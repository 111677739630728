import Vue from 'vue'
import VueRouter from 'vue-router'
//import Announcement from '../views/web/Announcement';
import Mining from '../views/web/Mining';
import MiningPc from '../views/web/MiningPc';
import Inviteusers from '../views/web/Inviteusers';
import InviteusersPc from '../views/web/InviteusersPc';
import myMining from '../views/web/myMining';
import myMiningPc from '../views/web/myMiningPc';
import Wallet from '../views/web/Wallet';
import WalletPc from '../views/web/WalletPc';
import Personal from '../views/web/Personal';
import PersonalPc from '../views/web/PersonalPc';
import Whitepaper from '../views/web/Whitepaper';
import WhitepaperPc from '../views/web/WhitepaperPc';
import Exchange from '../views/web/exchange';
import ExchangePc from '../views/web/exchangePc';
import Order from '../views/web/Order';
import OrderPc from '../views/web/OrderPc';
import Raffle from '../views/web/Raffle';
import RafflePc from '../views/web/RafflePc';
Vue.use(VueRouter)
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
const routes = [
  {
    path: '/',
    name: 'index',
    component: () => import('../views/web/index'),
    //主页面
  },
  {
    path: '/exchange/:id',
    //component: () => import('../views/web/exchangePc'),
    component: window.innerWidth > 700 ? Exchange : ExchangePc,
    meta: { keepAlive: false }
    //行情页
  },
  {
    path: '/Login',
    name: 'Login',
    component: () => import('../views/Login'),
    //登陆页
  },
  {
    path: '/notFound',
    name: 'notFound',
    component: () => import('../views/notFound'),
    //错误页
  },
  {
    path: '/register',
    name: 'Register',
    component: () => import('../views/Register'),
    //注册
  },
  {
    path: '/mining',
    name: 'Mining',
    //component: () => import('../views/web/Mining'),
    component: window.innerWidth > 700 ? Mining : MiningPc,
    meta: { keepAlive: true }
    //锁仓挖矿
  },
  {
    path: '/announcement',
    name: 'Announcement',
    component: () => import('../views/web/Announcement'),
    // component: window.innerWidth > 1100 ? Announcement : AnnouncementPc,
    meta: { keepAlive: false }
    //公告
  },
  {
    path: '/inviteusers',
    name: 'Inviteusers',
    //component: () => import('../views/web/Inviteusers'),
    component: window.innerWidth > 700 ? Inviteusers : InviteusersPc,
    meta: { keepAlive: true }
    //邀请拉新
  },
  {
    path: '/wallet',
    name: 'wallet',
    //component: () => import('../views/web/Wallet'),
    component: window.innerWidth > 700 ? Wallet : WalletPc,
    meta: { keepAlive: true }
    //钱包
  },
  {
    path: '/personal',
    name: 'personal',
    //component: () => import('../views/web/Personal'),
    component: window.innerWidth > 700 ? Personal : PersonalPc,
    meta: { keepAlive: true }
    //账号管理
  },
  {
    path: '/mymining',
    name: 'mymining',
    component: window.innerWidth > 700 ? myMining : myMiningPc,
    meta: { keepAlive: true }
    //我的矿机
  },
  {
    path: '/order',
    name: 'order',
    component: window.innerWidth > 700 ? Order : OrderPc,
    meta: { keepAlive: true }
    //我的矿机
  },
  {
    path: '/whitepaper',
    name: 'whitepaper',
    //component: () => import('../views/web/Whitepaper'),
    component: window.innerWidth > 700 ? Whitepaper : WhitepaperPc,
    meta: { keepAlive: true }
    //pdf
  },
  {
    path: '/raffle',
    name: 'raffle',
    component: window.innerWidth > 700 ? Raffle : RafflePc,
    //component: () => import('../views/web/Raffle'),
    meta: { keepAlive: true }
  },
  // {
  //   path: '/aboutus',
  //   name: 'aboutus',
  //   //component: () => import('../views/web/Aboutus'),
  //   component: window.innerWidth > 700 ? Aboutus : AboutusPc,
  //   //介绍信息页
  // },
  // {
  //   path: '/pdf',
  //   name: 'pdf',
  //   component: () => import('../components/pdf'),
  //   // component: window.innerWidth > 700 ? Aboutus : AboutusPc,
  //   //介绍信息页
  // },
  { path: '*', redirect: '/notFound', hidden: true, meta: { keepAlive: false } },
]
const router = new VueRouter({
  routes,
})
export default router
