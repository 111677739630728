import request from '../requestapi'
//最新24小时行情
export function gettickers(symbol) {
    return request({
      url: '/api/v3/ticker/24hr?symbol='+symbol,
      method: 'get',
    })
  }   
  export function gettickerslist(symbol) {
    return request({
      url: '/api/v3/ticker/24hr?symbols='+symbol,
      method: 'get',
    })
  }   
  //
export function gettradesList(symbol) {
    return request({
      url: '/api/v3/trades?symbol='+symbol,
      method: 'get',
    })
  }
//   /www/wwwroot/bnbncoin_com-1712567410884/Nginx
//   bnbncoin_com.key
//   bnbncoin_com-rsa.key
//   bnbncoin_com_integrated.crt
  export function getaggtrades(symbol) {
    return request({
      url: '/api/v3/aggTrades?symbol='+symbol,
      method: 'get',
    })
  }
  export function getklines(symbol,h) {
    return request({
      url: '/api/v3/klines?symbol='+symbol+'&interval='+h,
      method: 'get',
      
    })
  }
export function getlocked(data) {
    return request({
      url: '/api/v3/trades?symbol=',
      method: 'post',
      data: data
    })
  }

