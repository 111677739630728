<template>
<div style="width: 100%; height: 100% ;font-size:20px;">
<div>
    <el-button type="text" @click="getinterval('1m')">1m</el-button> 
      <el-button type="text" @click="getinterval('5m')">5m</el-button> 
      <el-button type="text" @click="getinterval('15m')">15m</el-button>
      <el-button type="text" @click="getinterval('1h')">1h</el-button>
      <el-button type="text" @click="getinterval('2h')">2h</el-button> 
      <el-button type="text" @click="getinterval('4h')">4h</el-button>
      <el-button type="text" @click="getinterval('1d')">1d</el-button>
    </div>
  <div id="main" ref="chartContainer" style="width: 100%; height: 90% ;"/>
</div>
</template>

<script>
import * as echarts from 'echarts';

import { getklines } from '@/api/api/bianapi'
export default {
    
  name: 'StockChartComponent',
  data() {
    return {
      chartInstance: null,
      option: null,
      upColor: '#00da3c',
      downColor: '#ec0000',
      interval: '1d',
    };
  },
  props: {
    symbol: {
      type: String,
    }
  },
  mounted() { 
    this.fetchAndInitChart();
  },

  methods: {
    getinterval(interval){
        this.interval=interval;
        this.fetchAndInitChart();
         echarts.init(this.$refs.chartContainer, 'dark');
    },
    async fetchAndInitChart() {
      try {
        getklines(this.symbol,this.interval).then(response=>{
        const rawData=response;  
        const processedData = this.splitData(rawData);
        this.option = this.createOption(processedData);
        this.chartInstance = echarts.init(this.$refs.chartContainer, 'dark');
        this.chartInstance.setOption(this.option, true);    
       
        })
      } catch (error) {
        console.error('Failed to fetch or process data:', error);
      }  //获取数据
      
    },
    splitData(rawData) { //解析数据
      let categoryData = []; //时间
      let values = [];  
      let volumes = [];
      for (let i = 0; i < rawData.length; i++) {
        const date = new Date(rawData[i][0]);
        if(this.interval=='1m'||this.interval=='5m'||this.interval=='15m')
        {
            categoryData.push(date.getHours() + ':'+date.getMinutes());
        }
        else if(this.interval=='1h'||this.interval=='2h'||this.interval=='4h'){
            categoryData.push(date.getMonth()+1 + '-'+date.getDate()+':'+date.getHours());
        }
        else{
            categoryData.push(date.getMonth()+1 + '.'+date.getDate());
        }
        //今开、当前价格、最低价格、最高价
         values.push([rawData[i][1],rawData[i][4],rawData[i][3],rawData[i][2],rawData[i][5]]);
         volumes.push([i, rawData[i][5], rawData[i][4] > rawData[i][1] ? 1 : -1]);
      }
      return {
        categoryData,
        values,
        volumes,
      };
    },
    calculateMA(dayCount, data) {
      var result = [];
      for (var i = 0, len = data.values.length; i < len; i++) {
        if (i < dayCount) {
          result.push('-');
          continue;
        }
        var sum = 0;
        for (var j = 0; j < dayCount; j++) {
          sum +=  Number(data.values[i - j][1]);
        }
        result.push(+(sum / dayCount).toFixed(3));
      }
      return result;
    },
    createOption(data) {
      const ma5 = this.calculateMA(5, data);
      const ma10 = this.calculateMA(10, data);
      const ma20 = this.calculateMA(20, data);
      const ma30 = this.calculateMA(30, data);
      return {
          backgroundColor: '#161a1e',
        // 原始配置恢复（注释已取消）
        animation: false,
        // legend: {
        //   bottom: 10,
        //   left: 'center',
        //   data: ['Dow-Jones index', 'MA5', 'MA10', 'MA20', 'MA30'],
        // },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
          },
          borderWidth: 1,
          borderColor: '#ccc',
          padding: 20,
          textStyle: {
            color: '#000',
            fontSize: 11,
          },
          position: function(pos, params, el, elRect, size) {
            const obj = { top: 10 };
            obj[['left', 'right'][+(pos[0] < size.viewSize[0] / 2)]] = 30;
            return obj;
          },
          // extraCssText: 'width: 170px',
        },
        axisPointer: {
        link: [
          {
            xAxisIndex: 'all'
          }
        ],
        label: {
          backgroundColor: '#777'
        }
      },
      toolbox: {
        feature: {
          dataZoom: {
            yAxisIndex: false
          },
          brush: {
            type: ['lineX', 'clear']
          }
        }
      },
      brush: {
        xAxisIndex: 'all',
        brushLink: 'all',
        outOfBrush: {
          colorAlpha: 0.1
        }
      },
      visualMap: {
        show: false,
        seriesIndex: 5,
        dimension: 2,
        pieces: [
          {
            value: 1,
            color: this.upColor
          },
          {
            value: -1,
            color: this.downColor
          }
        ]
      },
      grid: [
        {
          left: '7%',
          right: '3%',
          height: '60%',
          backgroundColor: '#000',
        },
        {
          left: '7%',
          right: '3%',
          top: '72%',
          height: '20%',
          backgroundColor: '#000',
        }
      ],
        xAxis: [
           {
            axisLabel: {
            interval: 5 ,// 设置每隔5个标签显示一个
            fontSize: 14,
            },
            
          type: 'category',
          data: data.categoryData,
          boundaryGap: false,
          axisLine: { onZero: false },
          splitLine: { show: false },
          min: 'dataMin',
          max: 'dataMax',
          axisPointer: {
            z: 100
          }
        },
        {
          type: 'category',
          gridIndex: 1,
          data: data.categoryData,
          boundaryGap: false,
          axisLine: { onZero: false },
          axisTick: { show: false },
          splitLine: { show: false },
          axisLabel: { show: false },
          min: 'dataMin',
          max: 'dataMax'
        }
        ],
        yAxis: [
           {
            axisLabel: {
                fontSize: 14 // 设置字体大小为14像素
            },
          scale: true,
          splitArea: {
            show: true
          }
        },
        {
            fontSize: 14,
          scale: true,
          gridIndex: 1,
          splitNumber: 2,
          axisLabel: { show: false },
          axisLine: { show: false },
          axisTick: { show: false },
          splitLine: { show: false }
        }
        ],
         dataZoom: [
        {
          type: 'inside',
          xAxisIndex: [0, 1],
          start: 92,
          end: 100
        },
        {
          show: true,
          xAxisIndex: [0, 1],
          type: 'slider',
          top: '94%',
          start: 92,
          end: 100
        }
      ],
        series: [
          {
            name: 'index',
            type: 'candlestick',
            data: data.values,
            itemStyle: {
              color: this.upColor,
              color0: this.downColor,
              borderColor: '',
              borderColor0: '',
            },
          },
          {
            name: 'MA5',
            type: 'line',
            data: ma5,
            smooth: true,
             symbol: 'none',
            lineStyle: {
              opacity: 0.5,
            },
          },
          {
          name: 'MA10',
          type: 'line',
          data: ma10,
          smooth: true,
          symbol: 'none',
          lineStyle: {
            opacity: 0.5
          }
        },
        {
          name: 'MA20',
          type: 'line',
          data: ma20,
          smooth: true,
          symbol: 'none',
          lineStyle: {
            opacity: 0.5
          }
        },
        {
          name: 'MA30',
          type: 'line',
          data: ma30,
          smooth: true,
          symbol: 'none',
          lineStyle: {
            opacity: 0.5
          }
        },
        {
          name: 'Volume',
          type: 'bar',
          xAxisIndex: 1,
          yAxisIndex: 1,
          data: data.volumes
        }
        ],
      };
      
    },
  },

};

</script>